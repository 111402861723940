import { useMarkAgreementSignedMutation } from "app/apiSlice";
import { showToast } from "components/Toasts/helpers/showToast";
import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";
import { useEsigEvent } from "hooks/useEsigEvent";

/**
 * Renders the component for signing an agreement.
 * @returns {JSX.Element} The iframe for boldsign embedded signing.
 */
const AgreementSign = () => {
  const { agreement } = useAgreementData();
  const { setAgreementUIStatus } = useAgreementContext();
  const [markSigned] = useMarkAgreementSignedMutation();

  useEsigEvent("documentSigned", async() => {
    try {
      const { error } = await markSigned(agreement?._id).unwrap();
      if (error) {
        throw new Error(error);
      }
      setAgreementUIStatus("edit");
    } catch (error) {
      console.error("Error updating agreement after documentSigned - ", error);
      showToast({
        type: "error",
        message: "Error updating agreement. The agreement was signed, but the status may not have been updated. Refresh the page to see the latest status.",
      });
    }
  });

  return (
    <iframe
      title={`Sign Agreement ${agreement?.title}`}
      src={agreement?.adminEmbeddedSigningUrl}
      width="100%"
      // TODO: dynamic height or make constant
      height="850px"
    />
  );
};

export default AgreementSign;
