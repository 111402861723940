import { connect } from "react-redux";
import { useEffect } from "react";
import {
  changeDisplayedAssignment,
  getCampaignCreatorAssignments,
} from "../../actions";
import DataGrid from "../../../../components/DataGrid";
import { campaignCreatorAssignmentColDefs } from "../../constants/columnDefs";
import { useParams } from "react-router-dom";

const CampaignCreatorAssignments = ({
  creatorAssignments,
  displayedAssignment,
  loading,

  getCampaignCreatorAssignments,
  changeDisplayedAssignment,
  ...props
}) => {
  const { campaign_id } = useParams();

  useEffect(() => {
    if (campaign_id) {
      try {
        getCampaignCreatorAssignments(campaign_id);
      } catch (error) {
        console.error(error);
      }
    }
  }, [campaign_id, getCampaignCreatorAssignments]);

  useEffect(() => {
    changeDisplayedAssignment(null);
  }, [changeDisplayedAssignment, campaign_id]);

  return (
    <>
      <div className="creator-campaign-assignments px-2 pb-2">
        <DataGrid
          gridId="campaign-creators"
          columnDefs={campaignCreatorAssignmentColDefs}
          idField="_id"
          loading={loading}
          rowData={creatorAssignments}
          displayedItem={displayedAssignment}
          changeDisplayedItem={changeDisplayedAssignment}
          quickFilter={true}
          borders={true}
          appliedFilters={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creatorAssignments: state.campaigns.creatorAssignments,
    displayedAssignment: state.campaigns.displayedAssignment,
    loading: state.loading.GET_CAMPAIGN_CREATOR_ASSIGNMENTS?.pending,
  };
};
export default connect(mapStateToProps, {
  getCampaignCreatorAssignments,
  changeDisplayedAssignment,
})(CampaignCreatorAssignments);
