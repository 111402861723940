// import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routers from "./routers";
import Toasts from "components/Toasts";
// import settings from "settings";

function App() {
  // useEffect(() => {
  //   const favicon = document.getElementById("favicon");
  //   if (favicon) {
  //     favicon.href = settings?.favicon;
  //   }

  //   const onDeviceReady = () => {
  //     window.universalLinks.subscribe(null, (eventData) => {
  //       history.push(eventData.path);
  //     });
  //   };

  //   document.addEventListener("deviceready", onDeviceReady, false);
  //   return () => document.removeEventListener("deviceready", onDeviceReady);
  // }, []);

  return (
    <>
      <Helmet>
        <title>Creator Portal Admin</title>
      </Helmet>
      <div className="App">
        <Routers />
        <Toasts />
      </div>
    </>
  );
}

export default App;
