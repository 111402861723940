import { useParams } from "react-router-dom";

import AgreementsGrid from "features/agreements/components/AgreementsGrid";

const CreatorAgreements = () => {
  const { creator_id } = useParams();

  return <AgreementsGrid creator_id={creator_id} />;
};

export default CreatorAgreements;
