import { AgreementModalContext } from "./AgreementModalContext";

/**
 * Provides the context for the Agreement Modal components.
 *
 * @component
 * @param {Object} props - The component props.
 * @property {boolean} isAgreementModalOpen - The open state of the modal.
 * @property {Function} setIsAgreementModalOpen - The function to set the modal open state.
 * @property {Function} toggleAgreementModal - The function to toggle the modal open state.
 * @property {React.ReactNode} children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
export const AgreementModalProvider = ({
  isAgreementModalOpen,
  setIsAgreementModalOpen,
  toggleAgreementModal,
  children,
}) => {
  return (
    <AgreementModalContext.Provider
      value={{
        isAgreementModalOpen,
        setIsAgreementModalOpen,
        toggleAgreementModal,
      }}
    >
      {children}
    </AgreementModalContext.Provider>
  );
};
