import { useSelector } from "react-redux";
import Milestones from "features/milestones";

const CreatorAssignmentMilestones = () => {
  const displayedCreator = useSelector(
    // @ts-ignore
    (state) => state.creators.displayedCreatorAssignment?._id,
  );

  return <Milestones assignment_id={displayedCreator} />;
};

export default CreatorAssignmentMilestones;
