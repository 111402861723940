import { useEffect } from "react";
import { useFormikContext } from "formik";
import { useConfirmNavigate } from "hooks/useConfirmNavigate";

/**
 * @deprecated This component is no longer in use and should not be used in new code. Use custom hook useUnsavedChangesPrompt instead.
 * @param {Object} props - The component props.
 * @param {boolean} [props.shouldPromptOverride] - Whether to override the default prompt behavior.
 * @param {function} [props.setIsDirty] - A function to set the dirty state.
 * @returns {null} This component does not render any UI.
 */
const UnsavedChangesPrompt = ({
  shouldPromptOverride = undefined,
  setIsDirty = undefined,
}) => {
  const { dirty } = useFormikContext();

  // Sync dirty state to redux
  useEffect(() => {
    if (setIsDirty) setIsDirty(dirty);
  }, [dirty, setIsDirty]);

  const shouldPrompt = shouldPromptOverride ?? dirty;

  useConfirmNavigate(
    shouldPrompt,
    "You have unsaved changes! Are you sure you want to leave?",
  );

  return null;
};

export default UnsavedChangesPrompt;
