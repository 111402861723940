import { connect } from "react-redux";
import { useCallback, useEffect } from "react";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import PanelBody from "../../../../components/Panel/PanelBody";
import Panel from "../../../../components/Panel";
import LabelledData from "../../../../components/Forms/LabelledData";
import {
  getCreator,
  getCreatorCampaignAssignments,
  updateCreator,
} from "../../actions";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { Formik } from "formik";
import PanelFooter from "components/Panel/PanelFooter";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { creatorDetailsDataDefs } from "modules/Creators/constants/dataDefs";
import Loader from "components/Loader";
import { getChangedValues } from "helpers/getChangedValues";

const CreatorDetails = ({
  creator_id,
  isEditing,
  setIsEditing,
  disableEditing,

  displayedCreator,
  creatorAssignments,
  loading,

  getCreatorCampaignAssignments,
  getCreator,
  updateCreator,
  ...props
}) => {
  useEffect(() => {
    if (creator_id) {
      getCreator(creator_id);
    }
  }, [creator_id, getCreator]);

  const creatorDefs = creatorDetailsDataDefs(displayedCreator);

  const initialValues = getInitialValues(creatorDefs?.body, displayedCreator);

  const onSubmit = useCallback(
    async(values, actions) => {
      const changedValues = getChangedValues(values, initialValues);
      if (Object.keys(changedValues).length) {
        try {
          await updateCreator({ _id: displayedCreator?._id, ...changedValues });
          setIsEditing(false);
        } catch (error) {
          console.error(error);
        }
      }
      actions.setSubmitting(false);
    },
    [displayedCreator?._id, initialValues, setIsEditing, updateCreator],
  );

  return (
    <div className="creator-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // validate={(values) => {
        //   // TODO: validate
        // }}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true}>
                {loading && <Loader />}

                {creatorDefs?.body?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <>
                            <h2 className="fs-5 section-label text-muted">
                              {def?.label}
                            </h2>
                          </>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={displayedCreator}
                              {...subDef}
                            />
                          );
                        })}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading:
      state.loading.GET_CREATOR?.pending
      || state.loading.UPDATE_CREATOR?.pending,
    displayedCreator: state.creators.displayedCreator,
    creatorAssignments: state.creators.creatorAssignments,
  };
};
export default connect(mapStateToProps, {
  getCreator,
  getCreatorCampaignAssignments,
  updateCreator,
})(CreatorDetails);
