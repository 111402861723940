import { useCallback, useMemo } from "react";

import { showToast } from "components/Toasts/helpers/showToast";
import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import useAgreementCreate from "./hooks/useAgreementCreate";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { agreementFieldDefs } from "features/agreements/constants/fieldDefs";
import AgreementForm from "features/agreements/components/Agreement/AgreementForm";
import { getChangedValues } from "helpers/getChangedValues";

// Form for creating and editing agreements
const CreateAgreementForm = ({ children }) => {
  const { assignmentId, creatorId, setAgreementId } = useAgreementContext();
  const { createAgreement } = useAgreementCreate();

  const initialValues = useMemo(
    () => getInitialValues(agreementFieldDefs, {}),
    [],
  );

  const onSubmit = useCallback(
    async(values, actions) => {
      try {
        // create
        const submitValues = getChangedValues(values, initialValues);
        if (creatorId) {
          submitValues.user_id = creatorId;
        }
        if (assignmentId) {
          submitValues.assignment_id = assignmentId;
        }
        // if (values?.requiresAdminSignature && !submitValues.adminSigner_id) {
        //   submitValues.adminSigner_id = loggedInUser?._id;
        // }
        const { result, error } = await createAgreement(submitValues).unwrap();
        if (error) throw new Error(error);
        // setAgreementUIStatus("closed");
        actions.setSubmitting(false);
        showToast({
          type: "success",
          message: "Draft Agreement created successfully",
        });
        setAgreementId(result?._id);
      } catch (error) {
        console.error("Error saving agreement - ", error);
        showToast({
          type: "error",
          message: "Error saving agreement",
        });
      }
    },
    [assignmentId, createAgreement, creatorId, initialValues, setAgreementId],
  );
  return (
    <AgreementForm initialValues={initialValues} onSubmit={onSubmit}>
      {children}
    </AgreementForm>
  );
};

export default CreateAgreementForm;
