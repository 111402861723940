import { Col, Row } from "reactstrap";
import StripeBalanceCard from "../StripeBalanceCard";
import CreatorBalanceCard from "../CreatorBalanceCard";
import TopupsCard from "../TopupsCard";
import { connect } from "react-redux";
import { getStripeBalances } from "modules/Dashboard/actions";
import { useEffect } from "react";

const Balances = ({ getStripeBalances, ...props }) => {
  useEffect(() => {
    getStripeBalances();
  }, [getStripeBalances]);

  return (
    <>
      <Col md={6}>
        <Row className="g-4">
          <Col xs={12}>
            <StripeBalanceCard />
          </Col>
          <Col>
            <CreatorBalanceCard />
          </Col>
        </Row>
      </Col>
      <Col md={6}>
        <TopupsCard />
      </Col>
    </>
  );
};

const mapStateToProps = (_state) => {
  return {};
};
export default connect(mapStateToProps, {
  getStripeBalances,
})(Balances);
