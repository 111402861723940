import { useEffect, useState } from "react";
import TabbedNav from "components/TabbedNav";
import TabPanes from "components/TabbedNav/TabPanes";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { milestonesCardTabOptions } from "features/milestones/constants";
import "./index.scss";
import { useGetAssignmentQuery } from "app/apiSlice";
import ClipboardWriteButton from "components/ClipboardWriteButton";

const MilestoneModal = ({
  milestone_id,
  assignment_id,
  isOpen,
  setIsOpen,
  defaultTabId = "view",
  onModalClosed = () => {}, // this is mostly needed for compatibility purposes, used for hacky fix to update stale data in old store after a possible rtk-query mutation
  ...props
}) => {
  const [activeTabId, setActiveTabId] = useState(
    defaultTabId || milestonesCardTabOptions[0]?.id,
  );
  const { milestone } = useGetAssignmentQuery(assignment_id, {
    selectFromResult: ({ data, error, isLoading }) => ({
      assignment: data,
      milestone:
        data?.milestones?.find((milestone) => milestone._id === milestone_id) ||
        {},
      error,
      isLoading,
    }),
    // skip: !assignment_id,
  });

  useEffect(() => {
    if (!milestone_id) {
      setActiveTabId(milestonesCardTabOptions[0]?.id);
    }
  }, [milestone_id]);

  const toggleModal = () => {
    return setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  if (milestone_id && !assignment_id) {
    console.error("No assignment_id provided to MilestoneModal component.");
    return null;
  }

  return (
    <div>
      <Modal
        size={activeTabId === "deliverables" ? "xl" : "md"}
        isOpen={isOpen}
        toggle={toggleModal}
        className="milestone-modal"
        backdrop={"static"}
        onClosed={onModalClosed}
      >
        <ModalHeader toggle={toggleModal}>
          <div>
            <span className="text-muted small">
              {milestone_id ? "Manage Milestone" : "Create Milestone"}
            </span>
            {milestone?._id && <span className="ms-2">{milestone?.name}</span>}
          </div>
          {milestone?._id && (
            <div className="d-flex align-items-center mt-2">
              <span className="text-muted small me-2">ID:</span>
              <ClipboardWriteButton
                classNames="text-muted"
                textToCopy={milestone?._id}
              />
            </div>
          )}
        </ModalHeader>
        {milestone_id && (
          <ModalBody>
            <TabbedNav
              activeTabId={activeTabId}
              setActiveTabId={setActiveTabId}
              tabs={milestonesCardTabOptions}
            ></TabbedNav>
          </ModalBody>
        )}
        <TabPanes
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
          tabs={milestonesCardTabOptions}
          paneComponentProps={{
            milestone_id,
            assignment_id,
            toggleMilestoneModal: toggleModal,
          }}
        />
      </Modal>
    </div>
  );
};

export default MilestoneModal;
