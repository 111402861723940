import { ButtonGroup } from "reactstrap";
import FilterChip from "./FilterChip";
import { titleCaseFormatter } from "helpers/formatters";

/**
 * Renders a group of chips for a given field with the field name and applied filter conditions.
 * @param {Object} props - The component props.
 * @param {string} props.field - The filtered field.
 * @param {Object} props.filter - The filter object containing the applied conditions and operators.
 * @param {String} [props.filter.colName] - Operator (if multiple conditions are applied).
 * @param {String} [props.filter.operator] - Operator (if multiple conditions are applied).
 * @param {String} [props.filter.field] - Field key.
 * @param {String} [props.filter.valueFormatter] - Value formatter.
 * @param {Array} [props.filter.conditions] - Array of filter conditions (if multiple conditions are applied).
 * @param {Function} props.handleRemoveFilter - The function to call when a filter is removed.
 * @returns {JSX.Element} - The AppliedFilterChip component.
 */
const FieldAppliedFilters = ({ field, filter, handleRemoveFilter }) => {
  // Add conditions and operators to the fieldChips array
  const conditions = filter.conditions || [filter];
  conditions.map((condition) => {
    condition.field = filter.field;
    condition.valueFormatter = filter.valueFormatter;
    return condition;
  });
  const colName = filter.colName || titleCaseFormatter(field);

  return (
    <ButtonGroup className="applied-filter-chips" key={`${field}-filter-chips`}>
      <FilterChip colName={colName} />
      {renderGroupItems(conditions, filter.operator, field, handleRemoveFilter)}
    </ButtonGroup>
  );
};

function renderGroupItems(conditions, operator, field, handleRemoveFilter) {
  const chips = [];
  conditions.forEach((condition, index) => {
    // For conditions after the first, add an operator button before the condition button
    if (index > 0) {
      chips.push(
        <FilterChip key={`${field}-operator-${index}`} operator={operator} />,
      );
    }
    chips.push(
      <FilterChip
        key={`${field}-condition-${index}`}
        field={field}
        condition={condition}
        conditionIndex={index}
        handleRemoveFilter={handleRemoveFilter}
      />,
    );
  });
  return chips;
}

export default FieldAppliedFilters;
