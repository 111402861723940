import { getWebInstrumentations, initializeFaro } from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";

const initializeTelemetry = () => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    console.warn(
      "REACT_APP_GRAFANA_FARO_URL is not defined, telemetry will not be sent",
    );
    return;
  }

  initializeFaro({
    url: process.env.REACT_APP_GRAFANA_FARO_URL,
    app: {
      name: "Creator Portal Admin",
      version: process.env.REACT_APP_VERSION || "0.0.0",
      environment: process.env.REACT_APP_STAGING
        ? "staging"
        : process.env.NODE_ENV || "development",
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
    ],
  });
};

export default initializeTelemetry;
