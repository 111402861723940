import { useSelector } from "react-redux";

/**
 * Custom hook to check if a user has the required permissions.
 *
 * @param {Array<string>} requiredPermissions - The required permissions.
 * @returns {boolean} - True if the user has the required permissions, false otherwise.
 */
const useAuthorizationCheck = (requiredPermissions) => {
  // @ts-ignore
  const userPermissions = useSelector((state) => state.login.userPermissions);

  if (!requiredPermissions || !requiredPermissions.length) return true;

  const hasPermissions = requiredPermissions?.every((permission) =>
    userPermissions?.includes(permission),
  );

  return hasPermissions;
};

export default useAuthorizationCheck;
