import { loginActionTypes } from "../constants";

const initialState = {
  loading: false,
  isAuthenticated: false,
  error: null,
  userPermissions: [],
  user: null,
};
const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case loginActionTypes.login.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.login.SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userPermissions: payload?.permissions,
        user: payload,
      };
    case loginActionTypes.login.FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userPermissions: [],
        user: null,
      };

    case loginActionTypes.logout.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case loginActionTypes.logout.SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userPermissions: [],
        user: null,
      };
    case loginActionTypes.logout.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case loginActionTypes.getLoggedInUser.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case loginActionTypes.getLoggedInUser.SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        userPermissions: payload?.permissions,
        user: payload,
      };
    case loginActionTypes.getLoggedInUser.FAILURE:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        userPermissions: [],
        user: null,
      };

    default:
      return state;
  }
};

export default loginReducer;
