import { memo } from "react";
import TopupStatusRenderer from "components/DataGrid/customRenderers/TopupStatusRenderer";

export const topupsColDefs = [
  { headerName: "id", field: "id", hide: true },
  {
    headerName: "Amount",
    field: "amount",
    type: "currency",
  },
  {
    headerName: "Status",
    field: "status",
    cellRenderer: memo(TopupStatusRenderer),
  },
  {
    headerName: "Available",
    field: "dateAvailable",
    type: "date",
  },
  {
    headerName: "Created",
    field: "dateCreated",
    type: "date",
    sort: "desc",
  },
];
