import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Loader from "components/Loader";
import useAuthorizationCheck from "hooks/useAuthorizationCheck";

/**
 * Component that protects routes based on required permissions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array<string>} props.requiredPermissions - The required permissions for accessing the route.
 * @returns {JSX.Element} The protected route component.
 */
const ProtectedRouteLayout = ({ requiredPermissions, ...props }) => {
  const isAuthorized = useAuthorizationCheck(requiredPermissions);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      console.debug("Not authorized. Redirecting...");
      // TODO: dynamic fallback logic
      navigate("/campaigns");
    }
  }, [isAuthorized, navigate]);

  return (
    <Suspense fallback={<Loader solid="true" />}>
      {isAuthorized ? <Outlet /> : null}
    </Suspense>
  );
};

export default ProtectedRouteLayout;
