// @ts-nocheck
import { apiSlice } from "app/apiSlice";
import {
  getCampaign,
  getCampaignCreatorAssignments,
} from "modules/Campaigns/actions";
import {
  getCreator,
  getCreatorCampaignAssignments,
} from "modules/Creators/actions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/**
 * Custom hook for resynchronizing data to legacy redux store after a create/update/delete operation.
 * This hook facilitates the compatibility between new RTK-query based updates and the existing redux store modules.
 * It can also be used in components after awaiting a C/U/D action to force a resync.
 * It ensures that after a mutating operation, the relevant campaign, creator, and assignments are re-fetched.
 *
 * The hook leverages `useParams` to access `campaign_id` and `creator_id` parameters from the URL.
 * Data will only be re-fetched if the user is on a campaign or creator page.
 *
 * @returns {Function[]} A single-element array containing the `resyncAfterMutation` function that, when called,
 * triggers the data refresh actions for the relevant campaign, creator, and assignments based on the presence of their IDs in the URL.
 *
 * @example
 * // ...Create Milestone onSubmit function
 * // rtk-query or legacy action
 * const { error } = await createMilestone({
 *   assignment_id,
 *   milestone: values,
 * });
 * const [resyncAfterMutation] = useResyncLegacyStore();
 * // Call `resyncAfterMutation` after a mutation effect to refresh the related data
 */
const useResyncLegacyStore = () => {
  const { campaign_id, creator_id } = useParams();
  const dispatch = useDispatch();

  const resyncAfterMutation = useCallback(() => {
    if (campaign_id) {
      dispatch(getCampaign(campaign_id));
      dispatch(getCampaignCreatorAssignments(campaign_id));
    }
    if (creator_id) {
      dispatch(getCreator(creator_id));
      dispatch(getCreatorCampaignAssignments(creator_id));
    }
    dispatch(
      apiSlice?.util?.invalidateTags([
        { type: "Assignments", id: "LIST" },
        { type: "Milestones" },
      ]),
    );
  }, [campaign_id, creator_id, dispatch]);

  return [resyncAfterMutation];
};

export default useResyncLegacyStore;
