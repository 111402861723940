import AssignToCampaignModalButton from "modules/Creators/components/AssignToCampaignModalButton";
import CreatorAgreements from "modules/Creators/components/Creator/CreatorAgreements";
import CreatorAssignmentAgreements from "modules/Creators/components/Creator/CreatorAssignmentAgreements";
import CreatorAssignmentMilestones from "modules/Creators/components/Creator/CreatorAssignmentMilestones";
import CreatorCampaignAssignments from "modules/Creators/components/Creator/CreatorCampaignAssignments";

// * This is in its own file because importing the CreatorCampaignAssignments component causes lexical error when in the main constants file due to circular dependencies
export const creatorAssignmentsTabOptions = [
  {
    label: "Campaigns",
    id: "campaigns",
    PaneComponent: CreatorCampaignAssignments,
    TabRowActionComponent: AssignToCampaignModalButton,
  },
  {
    label: "Agreements",
    id: "agreements",
    PaneComponent: CreatorAgreements,
    // TabRowActionComponent: CreateAgreementModalButton,
  },
];

export const creatorAssignmentTabOptions = [
  {
    label: "Assignment Milestones",
    id: "milestones",
    PaneComponent: CreatorAssignmentMilestones,
    // TabRowActionComponent:
  },
  {
    label: "Assignment Agreements",
    id: "agreements",
    // TODO: pass assignment_id to agreements grid
    PaneComponent: CreatorAssignmentAgreements,
    // TabRowActionComponent: CreateAgreementModalButton,
  },
];
