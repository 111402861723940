import { useMemo } from "react";
import { milestonesUpcomingColDefs } from "../../constants";
import { useGetAssignmentsQuery } from "app/apiSlice";
import { createSelector } from "@reduxjs/toolkit";
import { processUpcomingMilestones } from "modules/Dashboard/helpers/processDueAndUpcoming";
import DataGrid from "components/DataGrid";

const MilestonesUpcoming = ({ activeTabId, tabId, ...props }) => {
  const selectMilestonesUpcoming = useMemo(() => {
    const emptyArray = [];
    // Return a unique selector instance for this page so that
    // the filtered results are correctly memoized
    return createSelector(
      (res) => res.data,
      (data) => {
        // @ts-ignore
        return data ? processUpcomingMilestones(data?.results) : emptyArray;
      },
    );
  }, []);
  const { milestonesUpcoming, isLoading, error } = useGetAssignmentsQuery(
    {
      calculate:
        "assignmentBalances contentCounts deliverableMetrics agreementMetrics",
      populate: "user_id campaign_id",
      onlyUpcomingMilestonesAndAssignments: true,
    },
    {
      selectFromResult: (result) => ({
        ...result,
        milestonesUpcoming: selectMilestonesUpcoming(result),
      }),
    },
  );

  if (error) {
    console.error("Error fetching upcoming milestones", error);
    return;
  }

  return (
    <div className="milestones-upcoming">
      <DataGrid
        rowData={milestonesUpcoming}
        visible={activeTabId === tabId}
        gridId="milestones-upcoming"
        borders
        autoSizeEnabled={true}
        autoSizeIgnoreHeaders={false}
        autoHeight={true}
        idField="_id"
        columnDefs={milestonesUpcomingColDefs}
        loading={isLoading}
        quickFilter={true}
        appliedFilters={true}
      />
    </div>
  );
};
export default MilestonesUpcoming;
