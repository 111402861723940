const { useState } = require("react");

/**
 * A custom React hook that returns the number of rows in a DataGrid component.
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @returns {Object} An object containing the number of rows in the DataGrid component and event handlers to update the row count when the grid is rendered, when row data is updated, and when the filter is changed.
 */

export const useRowCount = (gridApi) => {
  const [filteredCount, setFilteredCount] = useState(null);
  const [totalCount, setTotalCount] = useState(null);

  const updateRowCount = () => {
    if (!gridApi) return;
    setFilteredCount(gridApi.getDisplayedRowCount());
    const model = gridApi.getModel();
    setTotalCount(model?.getRowCount());
  };

  return {
    filteredCount,
    totalCount,
    onFirstDataRendered: updateRowCount,
    onGridReady: updateRowCount,
    onViewPortChanged: updateRowCount,
    onRowDataUpdated: updateRowCount,
    onFilterChanged: updateRowCount,
  };
};
