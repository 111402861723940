import { Button } from "reactstrap";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

/**
 * Component for opening an agreement document (file) in a new tab.
 * @param {Object} props - The component props.
 * @param {string} props.agreement_id - The ID of the agreement.
 * @param {React.ReactNode} [props.children] - The content of the button.
 * @returns {JSX.Element} The AgreementDownload component.
 */
const ViewAgreementFileButton = ({ agreement_id, children, ...props }) => {
  const showAgreement = async() => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/agreement/${agreement_id}/download`;
      const response = await fetch(url, {
        method: "GET",
        redirect: "error",
        credentials: "include",
        headers: {
          // accept pdf file
          Accept: "application/pdf",
        },
      });
      if (!response.ok) throw new Error("Error downloading agreement");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      window.open(downloadUrl, "_blank", "noopener, noreferrer");
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.error("Download error:", err);
    }
  };

  if (!agreement_id) return null;

  return (
    <Button
      color="primary"
      outline
      className="d-flex align-items-center"
      onClick={showAgreement}
      {...props}
    >
      <ArrowTopRightOnSquareIcon
        className="mr-2"
        height={16}
        width={16}
        strokeWidth={2}
      />
      {children || "Open File"}
    </Button>
  );
};

export default ViewAgreementFileButton;
