import AgreementsGrid from "features/agreements/components/AgreementsGrid";

const Agreements = () => {
  return (
    <>
      <section className="agreements main-page">
        <div className={`agreements__content main-page__content `}>
          <div className="main-page__header">
            <h1>Agreements</h1>
            <small className="muted">
              * New agreements can only be created from a Creator page
            </small>
          </div>
          <div className="main-page__body">
            <AgreementsGrid create={false} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Agreements;
