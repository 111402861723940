import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  useDeleteDeliverableMutation,
  useGetDeliverableQuery,
} from "app/apiSlice";
import { showToast } from "components/Toasts/helpers/showToast";
import useResyncLegacyStore from "hooks/useResyncLegacyStore";
import { removeCreatorCampaignAssignment } from "modules/Creators/actions";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";

const RemoveDeliverableButton = ({ deliverable_id, toggleModal, ...props }) => {
  // let canDelete = true;
  // const cantDeleteReasons = [];
  // if (deliverable_id?.paidBalance !== 0) {
  //   canDelete = false;
  //   cantDeleteReasons.push("Paid balance is not 0.");
  // }
  // if (deliverable_id?.availableBalance !== 0) {
  //   canDelete = false;
  //   cantDeleteReasons.push("Available balance is not 0.");
  // }
  // // check if assignment has any milestones that have been completed
  // if (deliverable_id?.milestones?.length > 0) {
  //   const completedMilestones = deliverable_id.milestones.filter(
  //     (milestone) => milestone.isComplete,
  //   );
  //   if (completedMilestones.length > 0) {
  //     canDelete = false;
  //     cantDeleteReasons.push("Has completed milestones.");
  //   }
  // }
  // TODO: validate and display reasons why can't delete
  const {
    data: deliverable,
    error: getDeliverableError,
    isLoading: isGetDeliverableLoading,
  } = useGetDeliverableQuery(deliverable_id, { skip: !deliverable_id });
  const [resyncAfterMutation] = useResyncLegacyStore();
  const [deleteDeliverable] = useDeleteDeliverableMutation();
  const handleDelete = useCallback(async() => {
    try {
      // @ts-ignore
      const { error } = await deleteDeliverable(deliverable);
      if (error) {
        showToast({
          type: "error",
          message: "Error deleting deliverable.",
        });
        throw new Error(error);
      }
      resyncAfterMutation();
      showToast({
        type: "success",
        message: "Deliverable deleted successfully.",
      });
      toggleModal();
    } catch (error) {
      console.error({ error });
      // TODO
    }
  }, [deleteDeliverable, deliverable, resyncAfterMutation, toggleModal]);
  if (!deliverable_id) return null;
  return (
    <Button
      color="danger"
      outline
      className="d-flex m-1 align-items-center float-left remove-assignment-button__button"
      disabled={deliverable?.isComplete}
      // TODO: also block delete if milestone is complete or paid or released etc
      onClick={handleDelete}
    >
      {/* //TODO: delete user onclick */}
      <XMarkIcon height={20} width={20} className="me-2" />
      Delete Deliverable
    </Button>
  );
};

const mapStateToProps = (_state) => {
  return {};
};
export default connect(mapStateToProps, {
  removeCreatorCampaignAssignment,
})(RemoveDeliverableButton);
