import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import { useAgreementModalContext } from "features/agreements/components/Agreement/context/AgreementModalContext";
import { Button, ModalFooter } from "reactstrap";

const AgreementModalFooter = () => {
  const { agreementUIStatus, formRef } = useAgreementContext();
  const { toggleAgreementModal } = useAgreementModalContext();

  const onSubmitClick = () => {
    formRef?.current?.submitForm();
  };

  if (!agreementUIStatus) return null;
  if (agreementUIStatus !== "create" && agreementUIStatus !== "edit") { return null; }
  return (
    <ModalFooter>
      {agreementUIStatus === "create" && (
        <>
          <Button color="secondary" onClick={toggleAgreementModal}>
            Cancel
          </Button>
          <Button type="submit" onClick={onSubmitClick} color="primary">
            Next
          </Button>
        </>
      )}
      {agreementUIStatus === "edit" && (
        <>
          <Button color="secondary" onClick={toggleAgreementModal}>
            Cancel
          </Button>
          <Button type="submit" onClick={onSubmitClick} color="primary">
            Save
          </Button>
        </>
      )}
    </ModalFooter>
  );
};

export default AgreementModalFooter;
