import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import {
  persistStore,
  persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { apiSlice } from "app/apiSlice";
import { errorLoggerMiddleware } from "app/errorLoggerMiddleware";

const persistConfig = {
  key: "creator-portal",
  storage,
  stateReconciler: autoMergeLevel1,
  whitelist: ["login"],
  blacklist: ["loading"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  devTools:
    process.env.NODE_ENV !== "production"
    || process.env.REACT_APP_STAGING === "true",
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // serializableCheck: {
      //   // Ignore action types from redux-persist as per https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    })
      .concat(apiSlice.middleware)
      .concat(errorLoggerMiddleware)
      .concat(thunk),
});
export const persistor = persistStore(store);
