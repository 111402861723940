import { Field, ErrorMessage, useFormikContext } from "formik";
import { Label, Input, FormFeedback } from "reactstrap";
import CreatorVisibleIndicator from "./components/CreatorVisibleIndicator";
import Uploader from "./components/Uploader";

/**
 * @typedef {Object} CustomFieldProps
 * @property {string} fieldKey - The key corresponding to the value in the form state.
 * @property {string} type - The type of the input ('text', 'password', 'switch', etc.).
 * @property {string} [label] - Optional label for the form field.
 * @property {array} [hideConditionCompareValues] - Optional initial form values.
 * @property {Function} [hideCondition] - Function that determines whether to hide the field.
 * @property {any} [props] - Any additional props.
 */

/**
 * Custom Formik field component.
 *
 * @param {CustomFieldProps} params
 */
const CustomField = ({
  fieldKey,
  type,
  label = undefined,
  hideCondition = undefined,
  disableCondition = undefined,
  // TODO: refactor below, this is messy
  disableConditionCompareValues = {},
  hideConditionCompareValues = {},
  creatorVisible = undefined,
  fieldInputProps = {},
  ...props
}) => {
  const { values } = useFormikContext();
  let disabled = false;

  if (hideCondition && hideCondition(values, hideConditionCompareValues)) {
    return null;
  }
  if (
    disableCondition
    && disableCondition(values, disableConditionCompareValues)
  ) {
    disabled = true;
  }

  return (
    <>
      {label && (
        <Label className="small text-muted" htmlFor={fieldKey}>
          {/* // TODO: This is used by milestone forms and any form where label is above field */}
          <CreatorVisibleIndicator
            isVisible={!!creatorVisible}
            showIcon={true}
            iconPosition="static"
          >
            {label}
          </CreatorVisibleIndicator>
        </Label>
      )}
      <Field name={fieldKey}>
        {({ field, form, meta }) => {
          const isInvalid = !!(meta.touched && meta.error);
          switch (type) {
            case "switch":
              return (
                <Input
                  type="switch"
                  id={fieldKey}
                  name={fieldKey}
                  checked={field?.value}
                  onChange={() => {
                    form.setFieldValue(field?.name, !field?.value);
                  }}
                  invalid={isInvalid}
                  disabled={disabled}
                />
                // TODO: currency input
              );
            case "uploader":
              return (
                <Uploader
                  name={fieldKey}
                  accept={fieldInputProps?.accept}
                  multiple={fieldInputProps?.multiple}
                  disabled={disabled}
                  invalid={isInvalid}
                />
              );
            default:
              return (
                <Input
                  type={type}
                  id={fieldKey}
                  name={fieldKey}
                  invalid={isInvalid}
                  disabled={disabled}
                  {...field}
                />
              );
          }
        }}
      </Field>
      <ErrorMessage
        name={fieldKey}
        render={(msg) => {
          return <FormFeedback invalid={"true"}>{msg}</FormFeedback>;
        }}
      ></ErrorMessage>
    </>
  );
};

export default CustomField;
