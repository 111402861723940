import { useState } from "react";

import { useParams } from "react-router-dom";

// import "./index.scss";
import { Button, Label } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";

import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import BrandDetails from "modules/Brands/components/BrandDetails";
import { useGetBrandQuery } from "app/apiSlice";
import { brandsDataDefs } from "../constants";
import BrandAssignments from "./BrandAssignments";

const Brand = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { brand_id } = useParams();
  const { data, isLoading } = useGetBrandQuery(brand_id);
  const displayedBrand = data?.result || [];

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
    // TODO: reset form values
  };

  return (
    <article className="brand" key={brand_id}>
      <Panel key={brand_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/brands"}>
          <h1 className="fs-2 d-flex align-items-center">
            {isEditing ? (
              <>Editing Brand</>
            ) : (
              <>
                <Label className="text-muted small">Brand:</Label>
                {displayedBrand?.name}
                {displayedBrand?._id && (
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    className="d-flex align-items-center ms-3"
                    onClick={() => setIsEditing(true)}
                  >
                    <PencilIcon height={20} width={20} />
                  </Button>
                )}
              </>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split="vertical">
          {isEditing && (
            <BrandDetails
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              disableEditing={disableEditing}
            />
          )}
          <BrandAssignments />
        </PanelBody>
      </Panel>
    </article>
  );
};

export default Brand;
