// @ts-nocheck
import { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const PreviewImageModal = ({
  isOpen,
  setIsOpen,
  toggle,
  image,
  ...props
}) => {
  // TODO: gallery of screenshots at bottom to allow switching previews within modal  */

  const src = useMemo(() => {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }
    if (typeof image === "string") {
      return image;
    }
  }, [image]);

  return (
    <Modal
      isOpen={isOpen}
      className="expanded-image-modal"
      // tabIndex={-1}
      // aria-hidden="true"
      // onClosed={handleOnModalClosed}
      toggle={toggle}
      // returnFocusAfterClose={false}
    >
      <ModalHeader
        toggle={toggle}
        // close={
        //   <Button outline className="btn btn-outline" onClick={() => toggle()}>
        //     <MagnifyingGlassMinusIcon width={20} height={20} strokeWidth={2} />
        //     Close Preview
        //   </Button>
        // }
        className="d-flex justify-content-space-between"
      >
        Preview Full Image
      </ModalHeader>
      <ModalBody>
        <img
          src={src}
          alt="Expanded media preview"
          className="expanded-image img-fluid"
        />
      </ModalBody>
    </Modal>
  );
};

export default PreviewImageModal;
