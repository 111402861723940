import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./index.scss";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import ButtonCellRenderer from "./customRenderers/ViewButtonCellRenderer";
import { columnTypes, defaultDefaultColDef } from "./constants";
import Loader from "components/Loader";
import { usePersistGridState } from "./hooks/usePersistGridState";
import { useAutoSizeCols } from "./hooks/useAutoSizeCols";
import { useLoadingOverlay } from "./hooks/useLoadingOverlay";
import { mergeEventHandlers } from "./helpers/mergeEventHandlers";
import { useRowCount } from "./hooks/useRowCount";
import { useGridApis } from "./hooks/useGridApis";
import { Badge, Button, Col, Row } from "reactstrap";
import AppliedFilters from "./components/AppliedFilters";
import QuickFilter from "./components/QuickFilter";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { showToast } from "components/Toasts/helpers/showToast";

/**
 * Local props for DataGrid component.
 *
 * @typedef {Object} DataGridProps
 * @property {string} gridId - ID/name for the grid. Grid state is stored in local storage under this name.
 * @property {Object[]} rowData - Data for the grid rows.
 * @property {Object[]} columnDefs - Definitions for the grid columns.
 * @property {Object} [defaultColDef=defaultDefaultColDef] - Default column definition.
 * @property {string} [idField="_id"] - Unique identifier field for each row.
 * @property {boolean} [loading=false] - Loading state of the grid.
 * @property {boolean} [autoSizeEnabled=true] - Enable/disable auto sizing columns.
 * @property {boolean} [autoSizeIgnoreHeaders=true] - Ignore headers when auto-sizing.
 * @property {boolean} [autoHeight=false] - Auto-adjust grid height.
 * @property {string} [height="100%"] - Grid container height.
 * @property {string} [width="100%"] - Grid container width.
 * @property {boolean} [borders=false] - Show grid borders.
 * @property {boolean} [footer=true] - Show grid footer.
 * @property {boolean} [appliedFilters=false] - Show applied filters above grid.
 * @property {boolean} [quickFilter=false] - Show quick filter input above grid.
 * @property {Object} [displayedItem] - Currently displayed item in the side panel.
 * @property {Function} [changeDisplayedItem] - Function to update the displayed item.
 * @property {Function} [actionClick] - Function for action button click in side panel.
 * @property {string} [actionText] - Text for action button in side panel.
 * @property {any} [props] - Extra properties that will be passed to AgGridReact.
 * @returns {JSX.Element} JSX Element
 */

/**
 * @param {DataGridProps & Record<string, any>} props
 */
const DataGrid = ({
  gridId,
  rowData,
  columnDefs,
  defaultColDef = defaultDefaultColDef,
  idField = "_id",
  loading = false,
  autoSizeEnabled = true,
  autoSizeIgnoreHeaders = true,
  autoHeight = false,
  height = autoHeight ? "" : "100%",
  width = "100%",
  borders = false,
  footer = true,
  appliedFilters = false,
  quickFilter = false,
  displayedItem = null,
  changeDisplayedItem = null,
  actionClick = null,
  actionText = null,

  ...props
}) => {
  const [quickFilterQuery, setQuickFilterQuery] = useState("");
  const gridEvents = [];

  const { gridApi, colApi, ...apiEvents } = useGridApis();
  if (apiEvents) gridEvents.push(apiEvents);

  // show/hide loading overlay
  // TODO: handle hide overlay logic to allow for additional tasks like restoring column state
  // TODO: maybe pass in loadingData, only hide overlay if loadingData is false AND initializing (like persisting state) has completed
  useLoadingOverlay(gridApi, loading);

  // set row data when it changes
  useEffect(() => {
    if (!gridApi) return;
    gridApi.setRowData(rowData);
  }, [gridApi, rowData]);
  // also set row data when ready I guess. i took the below event handler out and it broke grids that were using existing data from redux store on subsequent renders
  const onGridReadyData = (params) => {
    if (!params?.api) return;
    params.api.setRowData(rowData);
  };
  gridEvents.push({ onGridReady: onGridReadyData });

  // set up getRowId function
  const getRowId = useCallback(
    function(params) {
      return params?.data[idField ?? "_id"];
    },
    [idField],
  );

  // set up column definitions for grids with action buttons
  const computedColumnDefs = useMemo(() => {
    let definitions = columnDefs;
    if (changeDisplayedItem || actionClick || actionText) {
      const buttonCellDef = {
        headerName: "",
        field: "viewButton",
        cellRenderer: memo(ButtonCellRenderer),
        cellRendererParams: {
          setDisplayed: changeDisplayedItem,
          // TODO: settle discrepancy between displayed_id and displayedItem
          displayed_id: displayedItem?._id,
          actionClick,
          actionText,
        },
        cellClass: "d-flex justify-content-center cell-button",
        minWidth: 90,
        maxWidth: 100,
        pinned: "right",
        suppressSizeToFit: true,
        suppressAutoSize: true,
        suppressMovable: true,
        lockPinned: true,
        resizable: false,
        sortable: false,
        filter: false,
      };
      definitions = [...definitions, buttonCellDef];
    }
    // add tooltips for headers
    definitions = definitions.map((def) => {
      if (def.headerName && !def.headerTooltip) {
        def.headerTooltip = def.headerName;
        if (def.children?.length) {
          def.children = def.children.map((child) => {
            if (child.headerName && !child.headerTooltip) {
              child.headerTooltip = `${def.headerName} ${child.headerName}`;
            }
            return child;
          });
        }
      }
      return def;
    });
    return definitions;
  }, [
    columnDefs,
    changeDisplayedItem,
    displayedItem?._id,
    actionClick,
    actionText,
  ]);

  // set up grid style prop
  const styleOptions = useMemo(
    () => ({
      height,
      width,
    }),
    [height, width],
  );

  // set up grid event handlers for auto sizing columns on first data loaded and when window resizes
  // TODO: handle show/hide grid to trigger auto sizing
  const autoSizeEvents = useAutoSizeCols(
    gridApi,
    colApi,
    autoSizeEnabled,
    autoSizeIgnoreHeaders,
  );
  if (autoSizeEvents) gridEvents.push(autoSizeEvents);

  // set up grid event handlers for persisting state
  const { resetGridState, resetGridFilters, ...persistGridEventHandlers }
    = usePersistGridState(
      gridApi,
      colApi,
      gridId,
      autoSizeEnabled,
      autoSizeIgnoreHeaders,
    );
  const resetAllFilters = useCallback(() => {
    resetGridFilters();
    setQuickFilterQuery("");
  }, [resetGridFilters]);
  if (persistGridEventHandlers) gridEvents.push(persistGridEventHandlers);

  const { filteredCount, totalCount, ...rowCountEvents } = useRowCount(gridApi);
  if (rowCountEvents) gridEvents.push(rowCountEvents);

  // combine any overlapping grid event handlers
  const mergedEventHandlers = mergeEventHandlers(gridEvents);

  /**
   * Handles the download of the CSV file for the data grid.
   * @returns {void}
   */
  const handleDownloadCSV = () => {
    if (!gridApi) return;

    const currentDate = new Date()
      .toISOString()
      .split("T")[0]
      .replace(/-/g, "");
    const currentTime = new Date()
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      })
      .replace(/:/g, "");
    const fileName = `${currentDate}_${currentTime}_${gridId}_cpadmin_export.csv`; // Example filename: 20220101_123456_campaigns_cpadmin_export.csv

    showToast({
      type: "info",
      message: "Exporting grid data with applied filters to CSV.",
    });
    gridApi.exportDataAsCsv({ fileName });
  };

  return (
    <div className="data-grid-container">
      {(appliedFilters || quickFilter) && (
        <div className="data-grid-filters">
          <Row className="g-0">
            {quickFilter && (
              <Col xs="12" md="6" lg="4" xl="3">
                {/* //TODO: quick filter */}
                <QuickFilter
                  onChange={setQuickFilterQuery}
                  value={quickFilterQuery}
                />
              </Col>
            )}
            {appliedFilters && (
              <Col>
                <AppliedFilters gridApi={gridApi} />
              </Col>
            )}
          </Row>
        </div>
      )}
      <div
        className={`data-grid ${borders ? "border" : ""}`}
        style={styleOptions}
      >
        <AgGridReact
          className="data-grid__body ag-theme-alpine"
          domLayout={autoHeight ? "autoHeight" : "normal"}
          getRowId={getRowId}
          columnTypes={columnTypes}
          columnDefs={computedColumnDefs}
          defaultColDef={defaultColDef}
          loadingOverlayComponent={memo(Loader)}
          rowSelection="multiple"
          rowBuffer={20}
          valueCache={true}
          suppressColumnVirtualisation={true}
          overlayNoRowsTemplate="No data to display"
          tooltipShowDelay={800}
          quickFilterText={quickFilterQuery}
          cacheQuickFilter={true}
          // enableCellTextSelection={true}
          ensureDomOrder={true}
          {...mergedEventHandlers}
          {...props}
        />
        {footer && (
          <div className="data-grid__footer ag-theme-alpine">
            <div className="data-grid__footer__data">
              <div className="data-grid__footer__data__row-count">
                <Badge color="secondary" pill>
                  <span className="data-grid__footer__data__row-count__count">
                    {loading ? "Loading..." : <>{filteredCount} Items</>}
                  </span>
                </Badge>
              </div>
              <div className="data-grid__footer__data__actions">
                <Button
                  size="sm"
                  color="secondary"
                  className="btn-soft"
                  onClick={handleDownloadCSV}
                >
                  <ArrowDownTrayIcon
                    height={16}
                    width={16}
                    strokeWidth={2.4}
                    className="me-1"
                  />
                  Download CSV
                </Button>
              </div>
            </div>
            <div className="data-grid__footer__actions">
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={resetGridState}
              >
                Reset Columns
              </Button>
              <Button
                size="sm"
                color="secondary"
                className="btn-soft"
                onClick={resetAllFilters}
              >
                Reset Filters
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DataGrid;
