import { isRejectedWithValue } from "@reduxjs/toolkit";
import { showToast } from "components/Toasts/helpers/showToast";

export const errorLoggerMiddleware = (_api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // console.error("We got a rejected action!");
    // if (action?.type === "api/executeMutation/rejected") {
    //   console.error("Error from update:", action.error);
    //   showToast({
    //     type: "error",
    //     message: action.error?.data?.message ?? action.error?.message,
    //   });
    // }
  }

  return next(action);
};
