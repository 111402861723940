import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { showToast } from "components/Toasts/helpers/showToast";
import { useCallback } from "react";
import { Button } from "reactstrap";
import "./index.scss";

const ClipboardWriteButton = ({
  textToCopy,
  displayText = textToCopy,
  classNames = "",
  ...props
}) => {
  const fallbackCopyHandler = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    const success = document.execCommand("copy");
    document.body.removeChild(textArea);
    return success;
  };

  const copyHandler = useCallback(async() => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      showToast({ type: "success", message: "Copied to clipboard" });
    } catch (err) {
      if (fallbackCopyHandler(textToCopy)) {
        showToast({ type: "success", message: "Copied to clipboard" });
        return;
      }
      showToast({ type: "error", message: "Failed to copy to clipboard" });
    }
  }, [textToCopy]);

  return (
    <span className={`clipboard-write-btn d-flex align-items-center ${classNames}`}>
      {displayText}
      <Button
        className="btn-sm ms-2"
        outline={true}
        color="secondary"
        onClick={copyHandler}
      >
        <DocumentDuplicateIcon height={16} width={16} strokeWidth={2} />
      </Button>
    </span>
  );
};

export default ClipboardWriteButton;
