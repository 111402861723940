import { useCreateAgreementMutation } from "app/apiSlice";

/**
 * Custom hook for creating an agreement with fixedCacheKey to allow universal load tracking.
 *
 * @returns {Object}
 * @property {Function} updateAgreement - The function to update an agreement.
 * @property {boolean} isLoading - The loading state.
 */
const useAgreementCreate = () => {
  const [createAgreement, { isLoading }] = useCreateAgreementMutation({
    fixedCacheKey: "createAgreement",
  });
  return {
    createAgreement,
    isLoading,
  };
};

export default useAgreementCreate;
