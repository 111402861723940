import { contentQueueActionTypes } from "../constants";

const initialState = {
  loading: false,
  contents: [],
  displayedPost: null,
  displayedPostIsDirty: false,
};
const contentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case contentQueueActionTypes.getContents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case contentQueueActionTypes.getContents.SUCCESS:
      return {
        ...state,
        loading: false,
        contents: payload?.results,
      };
    case contentQueueActionTypes.getContents.FAILURE:
      return {
        ...state,
        loading: false,
        contents: [],
      };
    case contentQueueActionTypes.updateContents.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case contentQueueActionTypes.updateContents.SUCCESS:
      return {
        ...state,
        loading: false,
        contents: payload?.results,
      };
    case contentQueueActionTypes.updateContents.FAILURE:
      return {
        ...state,
        loading: false,
        contents: [],
      };
    case contentQueueActionTypes.changeDisplayedPost.REQUEST:
      return {
        ...state,
      };
    case contentQueueActionTypes.changeDisplayedPost.SUCCESS:
      return {
        ...state,
        displayedPost: payload,
      };
    case contentQueueActionTypes.changeDisplayedPost.FAILURE:
      return {
        ...state,
      };
    case contentQueueActionTypes.changeDisplayedPostIsDirty.SUCCESS:
      return {
        ...state,
        displayedPostIsDirty: payload,
      };
    default:
      return state;
  }
};

export default contentsReducer;
