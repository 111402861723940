/**
 * Mapping of generic event names to BoldSign-specific events.
 * This can be extended or modified for other events or libraries in the future.
 *
 * @type {Object.<string, string>}
 */
const esigEventMappings = {
  documentSent: "onCreateSuccess",
  documentSaved: "onDraftSuccess",
  documentSigned: "onDocumentSigned",
};

export default esigEventMappings;
