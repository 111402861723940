const getCustomSelectStyles = (size) => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused
        ? "var(--custom-select-border-color-focus)"
        : "var(--custom-select-border-color)",
      borderWidth: "var(--bs-border-width)",
      borderRadius:
        size === "sm"
          ? "var(--bs-input-border-radius-sm)"
          : "var(--bs-input-border-radius)",
      minHeight:
        size === "sm" ? "var(--bs-input-height-sm)" : "var(--bs-input-height)",
      padding: "0",
      boxShadow: state.isFocused
        ? "var(--custom-select-focus-ring-shadow)"
        : "none",
      transition: "var(--bs-input-transition)",
      ":hover": {
        borderColor: state.isFocused
          ? "var(--custom-select-border-color-focus)"
          : "var(--custom-select-border-color)",
      },
    }),

    indicatorsContainer: (baseStyles) => ({
      ...baseStyles,
      height:
        size === "sm"
          ? "var(--bs-input-height-inner-sm)"
          : "var(--bs-input-height-inner)",
      padding: "0",
    }),
  };
};

export default getCustomSelectStyles;
