/**
 * A panel component that can have an optional overlay.
 * @param {Object} props - The props object.
 * @param {string} [props.overlay] - The type of overlay to use. Can be "fullscreen" or "expanded".
 * @param {React.ReactNode} props.children - The children to render inside the panel.
 * @returns {JSX.Element} - The Panel component.
 */
import { useLayoutEffect, useRef } from "react";
import "./index.scss";

const Panel = ({
  overlay = undefined, // can be fullscreen or expanded
  children,
  ...props
}) => {
  const overlayRef = useRef(null);

  useLayoutEffect(() => {
    overlayRef.current.focus();
  }, []);

  return (
    <div
      ref={overlayRef}
      tabIndex={-1}
      className={`panel ${overlay ? `${overlay}-overlay` : ""}`}
    >
      {children}
    </div>
  );
};

export default Panel;
