import { EyeIcon } from "@heroicons/react/24/outline";
import "./index.scss";

/**
 * Displays an indicator for creator-visible data.
 * @returns {JSX.Element} The CreatorVisibleIndicator component.
 * @description Used to indicate whether a field or data point will be visible to non-admin (creator) users.
 */
const CreatorVisibleIndicator = ({
  isVisible = undefined,
  showIcon = true,
  iconPosition = "absolute",
  children,
  ...props
}) => {
  if (!isVisible) {
    return <>{children}</>;
  }
  return (
    <div
      className="creator-visible-indicator"
      title={"This information is visible to creators."}
    >
      {showIcon && (
        <EyeIcon
          height={18}
          width={18}
          strokeWidth={2}
          className={`creator-visible-indicator__icon creator-visible-indicator__icon--${iconPosition}`}
        />
      )}
      <span className="creator-visible-indicator__label">{children}</span>
    </div>
  );
};

export default CreatorVisibleIndicator;
