import AgreementConfigAndSend from "features/agreements/components/Agreement/AgreementConfigAndSend";
import AgreementFields from "features/agreements/components/Agreement/AgreementFields";
import AgreementSign from "features/agreements/components/Agreement/AgreementSign";
import CreateAgreementForm from "features/agreements/components/Agreement/CreateAgreementForm";
import EditAgreementForm from "features/agreements/components/Agreement/EditAgreementForm";
import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";
import { useEffect } from "react";

const AgreementByUIState = () => {
  const { agreementUIStatus, setAgreementUIStatus } = useAgreementContext();
  const { agreement } = useAgreementData();

  useEffect(() => {
    console.debug("AgreementByUIState useEffect triggered by agreement change");
    if (!agreement?._id) {
      setAgreementUIStatus("create");
    } else if (!agreement.adminHasSent) {
      setAgreementUIStatus("config");
    } else {
      setAgreementUIStatus("edit");
    }
  }, [agreement, setAgreementUIStatus]);

  if (agreementUIStatus === "config") {
    return <AgreementConfigAndSend />;
  }
  if (agreementUIStatus === "sign") {
    return <AgreementSign />;
  }
  if (agreementUIStatus === "create") {
    return (
      <CreateAgreementForm>
        <AgreementFields />
      </CreateAgreementForm>
    );
  }
  if (agreementUIStatus === "edit") {
    return (
      <EditAgreementForm>
        <AgreementFields />
      </EditAgreementForm>
    );
  }
  return null;
};

export default AgreementByUIState;
