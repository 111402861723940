import { creatorsActionTypes } from "../constants";

const initialState = {
  loading: false,
  creators: [],
  displayedCreator: null,
  displayedCreatorAssignment: null,
  activeCreatorMilestone: null,
  creatorCampaignAssignments: [],
  error: null,
};
const creatorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case creatorsActionTypes.inviteCreator.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.inviteCreator.SUCCESS:
      return {
        ...state,
        loading: false,
        creators: [payload?.result, ...state.creators],
        displayedCreator: payload?.result,
      };
    case creatorsActionTypes.inviteCreator.FAILURE:
      return {
        ...state,
        loading: false,
        displayedCreator: null,
      };
    case creatorsActionTypes.getCreator.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.getCreator.SUCCESS:
      return {
        ...state,
        loading: false,
        displayedCreator: payload?.result,
      };
    case creatorsActionTypes.getCreator.FAILURE:
      return {
        ...state,
        loading: false,
        displayedCreator: null,
      };
    case creatorsActionTypes.getCreators.REQUEST:
      return {
        ...state,
        loading: true,
        displayedCreator: null,
        displayedCreatorAssignment: null,
        activeCreatorMilestone: null,
      };
    case creatorsActionTypes.getCreators.SUCCESS:
      return {
        ...state,
        loading: false,
        creators: payload?.results,
      };
    case creatorsActionTypes.getCreators.FAILURE:
      return {
        ...state,
        loading: false,
        creators: [],
      };
    case creatorsActionTypes.getCreatorCampaignAssignments.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.getCreatorCampaignAssignments.SUCCESS: {
      const updatedState = {
        ...state,
        loading: false,
        creatorCampaignAssignments: payload?.results,
      };
      // update current displayed assignment by ID
      if (state.displayedCreatorAssignment) {
        updatedState.displayedCreatorAssignment = payload?.results.find(
          (assignment) =>
            assignment._id === state.displayedCreatorAssignment._id,
        );
      }
      return updatedState;
    }
    case creatorsActionTypes.getCreatorCampaignAssignments.FAILURE:
      return {
        ...state,
        loading: false,
        creatorCampaignAssignments: [],
      };
    case creatorsActionTypes.getCreatorCampaignAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.getCreatorCampaignAssignment.SUCCESS: {
      const updatedAssignments = state.creatorCampaignAssignments.map(
        (assignment) => {
          return assignment._id === payload?._id ? payload : assignment;
        },
      );
      const updatedState = {
        ...state,
        loading: false,
        creatorCampaignAssignments: updatedAssignments,
        displayedCreatorAssignment: updatedAssignments.find((assignment) => {
          return assignment._id === state.displayedCreatorAssignment?._id;
        }),
      };
      return updatedState;
    }
    case creatorsActionTypes.getCreatorCampaignAssignment.FAILURE:
      return {
        ...state,
        loading: false,
        creatorCampaignAssignments: [],
      };
    case creatorsActionTypes.createCreatorCampaignAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.createCreatorCampaignAssignment.SUCCESS:
      return {
        ...state,
        loading: false,
        creatorCampaignAssignments: [
          payload?.result,
          ...state.creatorCampaignAssignments,
        ],
      };
    case creatorsActionTypes.createCreatorCampaignAssignment.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case creatorsActionTypes.removeCreatorCampaignAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.removeCreatorCampaignAssignment.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case creatorsActionTypes.removeCreatorCampaignAssignment.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case creatorsActionTypes.updateCreator.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.updateCreator.SUCCESS: {
      const updatedCreators = state.creators.map((creator) => {
        return creator._id === payload?.result?._id ? payload?.result : creator;
      });
      return {
        ...state,
        loading: false,
        creators: updatedCreators,
        displayedCreator: payload?.result,
      };
    }
    case creatorsActionTypes.updateCreator.FAILURE:
      return {
        ...state,
        loading: false,
        creators: [],
      };
    case creatorsActionTypes.updateCreatorAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case creatorsActionTypes.updateCreatorAssignment.SUCCESS: {
      const updatedAssignments = state.creatorCampaignAssignments.map(
        (assignment) => {
          return assignment._id === payload?.result?._id
            ? payload?.result
            : assignment;
        },
      );
      return {
        ...state,
        loading: false,
        creatorCampaignAssignments: updatedAssignments,
        displayedCreatorAssignment: payload?.result,
      };
    }
    case creatorsActionTypes.updateCreatorAssignment.FAILURE:
      return {
        ...state,
        loading: false,
        // creators: [],
      };
    case creatorsActionTypes.changeDisplayedCreator.SUCCESS:
      return {
        ...state,
        displayedCreator: payload,
        displayedCreatorAssignment: null,
      };
    case creatorsActionTypes.changeDisplayedCreatorAssignment.SUCCESS:
      return {
        ...state,
        displayedCreatorAssignment: payload,
        activeCreatorMilestone: null,
      };
    default:
      return state;
  }
};

export default creatorsReducer;
