import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { Input, InputGroup } from "reactstrap";
import "./index.scss";

const QuickFilter = ({ value, onChange, ...props }) => {
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <InputGroup className={`quick-filter ${value && "quick-filter--applied"}`}>
      <div className="input-group-text">
        <MagnifyingGlassIcon height={18} width={18} strokeWidth={2.5} />
      </div>
      <XMarkIcon
        height={18}
        width={18}
        strokeWidth={2}
        className="quick-filter__clear text-secondary"
        onClick={() => onChange("")}
      />
      <Input
        className="quick-filter__input"
        name="filter"
        type="text"
        value={value}
        onChange={handleChange}
      />
    </InputGroup>
  );
};

export default QuickFilter;
