import { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import DataGrid from "components/DataGrid";
import {
  changeDisplayedCreatorAssignment,
  getCreatorCampaignAssignments,
} from "modules/Creators/actions";
import { creatorCampaignAssignmentColDefs } from "modules/Creators/constants/columnDefs";

const CreatorCampaignAssignments = ({
  creatorCampaignAssignments,
  displayedCreatorAssignment,
  loading,

  getCreatorCampaignAssignments,
  changeDisplayedCreatorAssignment,
  ...props
}) => {
  const navigate = useNavigate();
  const { creator_id, assignmentTab, assignment_id } = useParams();

  useEffect(() => {
    try {
      getCreatorCampaignAssignments(creator_id);
    } catch (error) {
      console.error(error);
    }
  }, [creator_id, getCreatorCampaignAssignments]);

  useEffect(() => {
    changeDisplayedCreatorAssignment(null);
  }, [creator_id, changeDisplayedCreatorAssignment]);

  useEffect(() => {
    // navigate(`/creators/${creator_id}/${tab}/${assignment?._id}`);
    // when displayedCreatorAssignment changes, navigate to the new URL
    if (
      displayedCreatorAssignment?._id
      && assignment_id !== displayedCreatorAssignment?._id
    ) {
      navigate(
        `/creators/${creator_id}/${assignmentTab}/${displayedCreatorAssignment?._id}`,
      );
    }
  }, [
    creator_id,
    displayedCreatorAssignment?._id,
    navigate,
    assignmentTab,
    assignment_id,
  ]);

  // Callback function to change the URL
  const onAssignmentClick = (assignment) => {
    changeDisplayedCreatorAssignment(assignment);
  };

  return (
    <>
      <div className="creator-campaign-assignments px-2 pb-2">
        {creatorCampaignAssignments?.length > 0 ? (
          <>
            <DataGrid
              gridId="creator-campaigns"
              columnDefs={creatorCampaignAssignmentColDefs}
              idField="_id"
              rowData={creatorCampaignAssignments}
              loading={loading}
              borders={true}
              // displayedItem={displayedCreatorAssignment}
              changeDisplayedItem={onAssignmentClick}
              quickFilter={true}
              appliedFilters={true}
            />
          </>
        ) : (
          <Container>
            <Row>
              <Col className="d-flex justify-content-center align-items-center flex-column">
                <p className="no-creator-campaign-assignments mt-5">
                  Not assigned to any campaigns
                </p>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creatorCampaignAssignments: state.creators.creatorCampaignAssignments,
    displayedCreatorAssignment: state.creators.displayedCreatorAssignment,
    loading: state.loading.GET_CREATOR_CAMPAIGN_ASSIGNMENTS?.pending,
  };
};
export default connect(mapStateToProps, {
  getCreatorCampaignAssignments,
  changeDisplayedCreatorAssignment,
})(CreatorCampaignAssignments);
