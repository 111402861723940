import {
  useCreateDeliverableMutation,
  useGetDeliverableQuery,
  useGetMilestoneQuery,
  useUpdateDeliverableMutation,
} from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { showToast } from "components/Toasts/helpers/showToast";
import RemoveDeliverableButton from "features/deliverables/RemoveDeliverableButton";
import { deliverableFields } from "features/deliverables/constants";
import { Formik } from "formik";
import { getChangedValues } from "helpers/getChangedValues";
import useResyncLegacyStore from "hooks/useResyncLegacyStore";
import { useCallback, useMemo } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
} from "reactstrap";

const DeliverableModal = ({
  deliverable_id,
  milestone_id,
  isOpen,
  setIsOpen,
  ...props
}) => {
  const {
    data: deliverable,
    error: getDeliverableError,
    isLoading: isGetDeliverableLoading,
  } = useGetDeliverableQuery(deliverable_id, { skip: !deliverable_id });
  const {
    data: milestone,
    error: getMilestoneError,
    isLoading: isGetMilestoneLoading,
  } = useGetMilestoneQuery(milestone_id, { skip: !milestone_id });
  const [createDeliverable, { isLoading: isLoadingCreate }]
    = useCreateDeliverableMutation();
  const [updateDeliverable, { isLoading: isLoadingUpdate }]
    = useUpdateDeliverableMutation();

  const [resyncAfterMutation] = useResyncLegacyStore();

  const toggleModal = useCallback(() => {
    return setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [setIsOpen]);

  const initialValues = useMemo(() => {
    return getInitialValues(
      deliverableFields,
      deliverable_id ? deliverable : {},
    );
  }, [deliverable, deliverable_id]);

  const validate = useCallback(
    (values) => {
      const errors = {};
      const changedValues = getChangedValues(values, initialValues);
      deliverableFields?.forEach((field) => {
        // @ts-ignore
        if (field?.editing?.required && !values[field?.fieldKey]) {
          errors[field?.fieldKey] = `This field is required`;
        }
        if (
          field?.type === "number"
          && (!values[field?.fieldKey] || values[field?.fieldKey] <= 0)
        ) {
          errors[field?.fieldKey] = `Must be an amount greater than 0`;
        }
        if (field.fieldKey === "postDate") {
          if (values?.postDate && values?.postDate > new Date()) {
            errors[field?.fieldKey] = `Date can not be in the future`;
          }
        }
        // if marking complete...
        if (
          field.fieldKey === "isComplete"
          && Object.keys(changedValues).includes("isComplete")
          && values?.isComplete
          && !initialValues?.isComplete
        ) {
          // can only mark complete if either postURL or screenshot and handle and postDate is added
          if (
            !values?.postUrl
            && !(values?.imageUrls?.length && values?.handle && values?.postDate)
          ) {
            errors[field?.fieldKey]
              = "Deliverables can only be completed if either a post URL or screenshot, handle, and date posted is provided.";
          }
        }
        // if marking incomplete...
        if (
          field.fieldKey === "isComplete"
          && Object.keys(changedValues).includes("isComplete")
          && !values?.isComplete
          && initialValues?.isComplete
        ) {
          // can only mark un-complete if milestone is not complete or released for payment
          if (milestone?.isComplete || milestone?.releasePayment) {
            errors[field?.fieldKey]
              = "Deliverables can only be un-completed if their associated milestone is neither complete nor released for payment.";
          }
        }
      });
      return errors;
    },
    [initialValues, milestone?.isComplete, milestone?.releasePayment],
  );

  const submit = useCallback(
    async(values, actions) => {
      try {
        if (deliverable_id) {
          // update
          if (deliverable?._id !== deliverable_id) {
            const message = `ID mismatch: deliverable?._id ${deliverable?._id} !== deliverable_id ${deliverable_id}`;
            throw new Error(message);
          }
          const changedValues = getChangedValues(values, initialValues);
          const { uploadedImages: images, ...changedValuesRest }
            = changedValues;
          if (Object.keys(changedValues).length) {
            // @ts-ignore
            const { error } = await updateDeliverable({
              ...changedValuesRest,
              images,
              _id: deliverable_id,
            });
            if (error?.status) throw new Error(error);
          }
        } else {
          // create
          // @ts-ignore
          const { error } = await createDeliverable({
            ...values,
            milestone_id,
          });
          if (error?.status) throw new Error(error);
        }
        resyncAfterMutation();
        showToast({
          type: "success",
          message: "Deliverable saved successfully",
        });
        toggleModal();
      } catch (error) {
        console.error("Error saving deliverable - ", error);
        showToast({
          type: "error",
          message: "Error saving deliverable",
        });
      }
      actions.setSubmitting(false);
    },
    [
      deliverable_id,
      resyncAfterMutation,
      toggleModal,
      deliverable?._id,
      initialValues,
      updateDeliverable,
      createDeliverable,
      milestone_id,
    ],
  );

  if (!isGetDeliverableLoading && getDeliverableError && deliverable_id) {
    const message = "Error opening deliverable";
    console.error({
      message,
      error: getDeliverableError,
    });
  }
  if (!isGetMilestoneLoading && getMilestoneError && milestone_id) {
    const message = "Error opening deliverable, can not fetch milestone";
    console.error({
      message,
      error: getMilestoneError,
    });
  }

  return (
    <Modal
      size={"md"}
      isOpen={isOpen}
      toggle={toggleModal}
      className="deliverable-modal"
      backdrop="static"
      unmountOnClose={true}
    >
      <ModalHeader toggle={toggleModal}>
        {deliverable_id ? "Manage Deliverable" : "Create Deliverable"}
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <UnsavedChangesPrompt />
              <ModalBody>
                {(isGetDeliverableLoading
                  || isGetMilestoneLoading
                  || isLoadingCreate
                  || isLoadingUpdate) && <Loader />}
                {deliverableFields?.map((def, i) => {
                  return (
                    <LabelledData
                      key={i}
                      definition={def}
                      isEditing={true}
                      data={deliverable_id ? deliverable : {}}
                    />
                  );
                })}
                <RemoveDeliverableButton
                  deliverable_id={deliverable_id}
                  toggleModal={toggleModal}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button color="dark" type="submit">
                  {deliverable_id ? "Save" : "Create"}
                </Button>
              </ModalFooter>
            </Form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default DeliverableModal;
