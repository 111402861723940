import { getActionName } from "helpers/getActionName";

const dirtyFormReducer = (state = {}, { type, _payload }) => {
  const formName = getActionName(type);

  if (!formName) return state;

  if (type.endsWith("_DIRTY")) {
    return { ...state, [formName]: true };
  }

  if (type.endsWith("_CLEAN")) {
    return { ...state, [formName]: false };
  }

  return state;
};

export default dirtyFormReducer;
