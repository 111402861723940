import { useSelector } from "react-redux";
import Milestones from "features/milestones";

const CampaignAssignmentMilestones = () => {
  const displayedCampaign = useSelector(
    // @ts-ignore
    (state) => state.campaigns.displayedAssignment?._id,
  );

  return <Milestones assignment_id={displayedCampaign} />;
};

export default CampaignAssignmentMilestones;
