import { toast } from "react-toastify";

/**
 * Shows a toast notification with the given message and type.
 * @param {Object} options - The options object.
 * @param {string} options.message - The message to display in the toast.
 * @param {string} [options.type="error"] - The type of toast to display (error, success, warning, info).
 * @param {string} [options.id=""] - The type of toast to display (error, success, warning, info).
 */
export const showToast = ({ message, type = "error", id = "" }) => {
  const defaultId = type + message;
  toast(message, {
    type,
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    toastId: id || defaultId,
  });
};
