import {
  useCreateTransferMutation,
  useGetAssignmentQuery,
  useRecordOffPlatformPaymentMutation,
} from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { showToast } from "components/Toasts/helpers/showToast";
import { mileStonePaymentFields } from "features/milestones/constants";
import useResyncLegacyStore from "hooks/useResyncLegacyStore";
import { Formik } from "formik";
import { currencyFormatter } from "helpers/formatters";
import { getChangedValues } from "helpers/getChangedValues";
import useAuthorizationCheck from "hooks/useAuthorizationCheck";
import { useCallback } from "react";
import { Alert, Button, Form, ModalBody, ModalFooter } from "reactstrap";

const MilestonePay = ({
  assignment_id,
  milestone_id,
  toggleMilestoneModal,
  ...props
}) => {
  const {
    assignment,
    milestone,
    error,
    isLoading: isLoadingData,
  } = useGetAssignmentQuery(assignment_id, {
    selectFromResult: ({ data, error, isLoading }) => ({
      assignment: data,
      milestone:
        data?.milestones?.find((milestone) => milestone._id === milestone_id) ||
        {},
      error,
      isLoading,
    }),
    refetchOnMountOrArgChange: true,
    skip: !assignment_id,
  });
  const [createTransfer, { isLoading: isLoadingTransfer }] =
    useCreateTransferMutation();
  const [recordOffPlatformPayment, { isLoading: isLoadingOffplat }] =
    useRecordOffPlatformPaymentMutation();

  const [resyncAfterMutation] = useResyncLegacyStore();

  const canPay = useAuthorizationCheck(["make_payment"]);
  const initialValues = getInitialValues(mileStonePaymentFields, milestone);

  const validate = useCallback(
    (values) => {
      const errors = {};
      mileStonePaymentFields?.forEach((field) => {
        // @ts-ignore
        if (field?.editing?.required && !values[field?.fieldKey]) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (values?.offPlatformPayment) {
          if (
            (field?.fieldKey === "offPlatformAmount" &&
              !values?.offPlatformAmount) ||
            (field?.fieldKey === "offPlatformReferenceId" &&
              !values?.offPlatformReferenceId) ||
            (field?.fieldKey === "offPlatformPlatform" &&
              !values?.offPlatformPlatform) ||
            (field?.fieldKey === "offPlatformCreated" &&
              !values?.offPlatformCreated)
          ) {
            errors[field?.fieldKey] = `This field is required`;
          }
        }

        if (
          field?.fieldKey === "paymentType" &&
          !values?.paymentType &&
          !values?.offPlatformPayment
        ) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (values?.paymentType === "partial" && !values?.offPlatformPayment) {
          if (field?.fieldKey === "amountPartial") {
            if (!values.amountPartial) {
              errors[field.fieldKey] = `Please enter a payment amount`;
            }
            if (values.amountPartial > milestone?.holdBalance) {
              errors[field?.fieldKey] =
                `Payment amount cannot be greater than the amount owed`;
            }
          }
        }
      });

      return errors;
    },
    [milestone],
  );

  const submit = useCallback(
    async(values, actions) => {
      try {
        // setSubmissionError(null);
        if (values?.paymentType === "partial") {
          values.amount = values.amountPartial;
        }
        if (values?.paymentType === "full") {
          values.amount = milestone?.holdBalance;
        }
        if (values?.offPlatformPayment) {
          delete values.status;
          delete values.amount;
          // TODO
          // values.offPlatformCreatedByAdminEmail = user?.email;
        }
        const changedValues = getChangedValues(values, initialValues);
        if (
          window.confirm(
            `Confirm submitting ${
              values?.offPlatformPayment
                ? `off-platform (${values?.offPlatformPlatform})`
                : values?.paymentType
            } payment: ${currencyFormatter(
              values?.amount ?? values?.offPlatformAmount,
            )}`,
          )
        ) {
          if (!Object.keys(changedValues).length) {
            showToast({
              type: "warning",
              message: "Nothing to submit.",
            });
            return;
          }
          let paymentError;
          if (values?.offPlatformPayment) {
            // @ts-ignore
            const { error } = await recordOffPlatformPayment({
              assignment_id,
              offPlatformMilestone_id: milestone?._id,
              payment: changedValues,
            });
            paymentError = error;
          } else {
            // @ts-ignore
            const { error } = await createTransfer({
              assignment_id,
              milestone_id: milestone?._id,
              payment: changedValues,
            });
            paymentError = error;
          }
          if (paymentError?.status) {
            showToast({
              type: "error",
              message: "Error submitting payment.",
            });
            throw new Error(paymentError);
          } else {
            showToast({
              type: "success",
              message: "Payment submitted successfully.",
            });
            resyncAfterMutation();
            toggleMilestoneModal();
          }
        }
      } catch (error) {
        console.error("Error submitting payment", error);
      }
      actions.setSubmitting(false);
    },
    [
      initialValues,
      milestone?.holdBalance,
      milestone?._id,
      recordOffPlatformPayment,
      assignment_id,
      createTransfer,
      resyncAfterMutation,
      toggleMilestoneModal,
    ],
  );

  if ((!isLoadingData && !assignment_id) || assignment_id !== assignment?._id) {
    const message = "Error opening pay milestone - assignment ID mismatch";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      assignment_id,
      "assignment?._id": assignment?._id,
    });
    return null;
  }
  if (!isLoadingData && milestone_id && milestone_id !== milestone?._id) {
    const message = "Error opening pay milestone - milestone ID mismatch";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      milestone_id,
      "milestone?._id": milestone?._id,
    });
    return null;
  }
  const isLoading = isLoadingData || isLoadingTransfer || isLoadingOffplat;
  // TODO: do i need to re-throw error?
  if (error) {
    const message = "Error opening pay milestone";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      error,
    });
    return null;
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      enableReinitialize={true}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <UnsavedChangesPrompt />
          <ModalBody>
            {isLoading && <Loader />}
            {canPay && milestone?.isComplete && milestone?.releasePayment ? (
              <>
                {mileStonePaymentFields?.map((def, i) => {
                  return (
                    <LabelledData
                      key={i}
                      definition={def}
                      isEditing={true}
                      data={milestone || {}}
                      hideConditionCompareValues={assignment}
                      disableConditionCompareValues={initialValues}
                    />
                  );
                })}
                {!milestone?.__deliverableMetrics?.total && (
                  <Alert color="warning">
                    You are about to pay a milestone that has no deliverables
                    added to it. Make sure you know what you are doing!
                  </Alert>
                )}
              </>
            ) : (
              <>
                {!canPay && (
                  <Alert color="danger">
                    Your account is not permitted to make payments. Please
                    contact development if this is in error.
                  </Alert>
                )}
                {!milestone?.isComplete && (
                  <Alert color="danger">
                    This milestone is not complete so can not be paid.
                  </Alert>
                )}
                {!milestone?.releasePayment && (
                  <Alert color="danger">
                    This milestone has not been released for payment so it can
                    not be paid.
                  </Alert>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMilestoneModal}>
              Cancel
            </Button>
            <Button
              color="dark"
              type="submit"
              disabled={props.isSubmitting || isLoading}
            >
              {milestone ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default MilestonePay;
