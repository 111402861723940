import { connect } from "react-redux";
import { getCampaign } from "../../actions";
import "./index.scss";
import Panel from "../../../../components/Panel";
import PanelHeader from "../../../../components/Panel/PanelHeader";
import PanelBody from "../../../../components/Panel/PanelBody";
import CampaignCreatorAssignments from "./CampaignCreatorAssignments";
import CampaignCreatorAssignment from "./CampaignCreatorAssignment";
import AssignCreators from "./AssignCreators";

const CampaignAssignments = ({ campaign_id, ...props }) => {
  return (
    <div className="campaign-assignments">
      <Panel>
        <PanelHeader unpadded className="ms-3 mx-2">
          <h2 className="fs-4">
            <span className="small">Creator Assignments</span>
          </h2>
          <AssignCreators bold campaign_id={campaign_id} />
          {/* // TODO: assign creators button */}
          {/* <Button
            color="dark"
            outline
            className="d-flex m-1 align-items-center"
            onClick={toggleMilestoneModal}
          >
            <PlusIcon height={20} width={20} className="me-2" />
            New Milestone
          </Button> */}
          {/* // TODO: agreements tab */}
        </PanelHeader>
        <PanelBody>
          <CampaignCreatorAssignments campaign_id={campaign_id} />
        </PanelBody>
      </Panel>
      <CampaignCreatorAssignment />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    campaignCreatorAssignment: state.campaigns.campaignCreatorAssignment,
  };
};
export default connect(mapStateToProps, {
  getCampaign,
})(CampaignAssignments);
