import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { providesListTags } from "app/providesListTags";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/v1`,
    /*
     * If headers are set here, it overrides headers set in individual endpoints.
     * Ex: Couldn't send multipart form data until I commented out prepareHeaders
     * https://github.com/reduxjs/redux-toolkit/issues/2107
     */
    // prepareHeaders(headers) {
    //   const pHeaders = privateHeaders();
    //   Object.entries(pHeaders).forEach(([key, value]) => {
    //     const header = headers.get(key);
    //     headers.set(key, value);
    //   });
    //   return headers;
    // },
    credentials: "include",
  }),
  tagTypes: [
    "Admins",
    "Brands",
    "BrandUsers",
    // "Campaigns",
    "Creators",
    "Assignments", // ? should this be separate for creator vs campaign assignments?
    "Milestones", // * technically this is a sub-resource of assignments
    "Deliverables",
    "Agreements",
  ],
  endpoints: (build) => ({
    // * Admins
    getAdmins: build.query({
      query: () => ({
        url: "admins",
        params: {
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) => providesListTags(result.results, "Admins"),
    }),
    // * Creators
    getCreators: build.query({
      query: (filter) => ({
        url: "users",
        params: {
          skip: 0,
          limit: 0,
          calculate: "earnings availableBalance",
          ...filter,
        },
      }),
      providesTags: (result) => providesListTags(result.results, "Creators"),
    }),
    getCreator: build.query({
      query: (id) => ({
        url: "user",
        params: {
          _id: id,
          calculate: "earnings availableBalance contentCounts canDeleteUser",
        },
      }),
      providesTags: (_result, _error, id) => [{ type: "Creators", id }],
    }),
    updateCreator: build.mutation({
      query: (creator) => ({
        url: "user",
        method: "PUT",
        body: creator,
      }),
      invalidatesTags: (_result, _error, creator) => {
        return [{ type: "Creators", id: creator?._id }];
      },
    }),
    inviteCreator: build.mutation({
      query: (creator) => ({
        url: "user/invitation",
        method: "POST",
        body: creator,
      }),
      invalidatesTags: (_result, _error, creator) => {
        return [{ type: "Creators", id: creator?._id }];
      },
    }),
    //* Brands
    getBrands: build.query({
      query: () => ({
        url: "brands",
        params: {
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) => providesListTags(result.results, "Brands"),
    }),
    getBrand: build.query({
      query: (id) => ({
        url: "brand",
        params: {
          _id: id,
        },
      }),
      providesTags: (_result, _error, id) => [
        { type: "Brands", id },
        { type: "Brands", id: "LIST" },
      ],
    }),
    createBrand: build.mutation({
      query: (brand) => ({
        url: "brand",
        method: "POST",
        body: brand,
      }),
      invalidatesTags: (_result, _error) => [{ type: "Brands", id: "LIST" }],
    }),
    updateBrand: build.mutation({
      query: (brand) => ({
        url: "brand",
        method: "PUT",
        body: brand,
      }),
      invalidatesTags: (_result, _error, brand) => [
        { type: "Brands", id: brand?._id },
      ],
    }),
    //* Brand Users
    getBrandUsers: build.query({
      query: (brand_id) => ({
        url: "brandUsers",
        params: {
          brand_id,
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) => providesListTags(result?.results, "BrandUsers"),
    }),
    getBrandUser: build.query({
      query: (id) => ({
        url: `brandUser/${id}`,
      }),
      providesTags: (_result, _error, id) => [
        { type: "BrandUsers", id },
        { type: "BrandUsers", id: "LIST" },
      ],
    }),
    inviteBrandUser: build.mutation({
      query: ({ email, name, brand_id, enableNotifications }) => ({
        url: "brandUser/invitation",
        method: "POST",
        body: {
          email,
          name,
          brand_id,
          enableNotifications,
        },
      }),
      invalidatesTags: (_result, _error) => [
        { type: "BrandUsers", id: "LIST" },
      ],
    }),
    updateBrandUser: build.mutation({
      query: ({ _id, enableNotifications, disabled }) => ({
        url: "brandUser",
        method: "PUT",
        body: { _id, enableNotifications, disabled },
      }),
      invalidatesTags: (_result, _error, { _id }) => [
        { type: "BrandUsers", id: _id },
        { type: "BrandUsers", id: "LIST" },
      ],
    }),
    //* Assignments and Milestones
    getAssignments: build.query({
      query: ({
        calculate = "contentCounts deliverableMetrics agreementMetrics",
        populate,
        hasMilestonePaymentsDue,
        onlyUpcomingMilestonesAndAssignments,
      }) => {
        const addPropertyIfBoolean = (obj, key, value) => {
          if (typeof value === "boolean") {
            obj[key] = value;
          }
        };
        const params = {
          skip: 0,
          limit: 0,
          calculate,
          populate,
        };
        addPropertyIfBoolean(
          params,
          "hasMilestonePaymentsDue",
          hasMilestonePaymentsDue,
        );
        addPropertyIfBoolean(
          params,
          "onlyUpcomingMilestonesAndAssignments",
          onlyUpcomingMilestonesAndAssignments,
        );

        return {
          url: "assignments",
          params,
        };
      },
      providesTags: (result) => [
        ...providesListTags(result.results, "Assignments"),
        { type: "Milestones", id: "LIST" },
      ],
    }),
    getAssignment: build.query({
      query: (id) => ({
        url: "assignment",
        params: {
          _id: id,
          populate: "user_id",
          calculate: "contentCounts deliverableMetrics agreementMetrics",
        },
      }),
      providesTags: (_result, _error, id) => [
        { type: "Assignments", id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: "LIST" },
      ],
    }),
    // TODO: getMilestones
    getMilestone: build.query({
      query: (milestone_id) => ({
        url: `assignment/readOneMilestone/${milestone_id}`,
        params: {
          calculate: "contentCounts deliverableMetrics",
        },
        method: "GET",
      }),
      providesTags: (_result, _error, milestone_id) => [
        { type: "Milestones", id: milestone_id },
      ],
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
    }),
    createAssignmentMilestone: build.mutation({
      query: ({ assignment_id, milestone }) => ({
        url: "assignment/addMilestone",
        method: "PUT",
        body: { _id: assignment_id, ...milestone },
      }),
      invalidatesTags: (_result, _error, { assignment_id }) => {
        return [
          { type: "Assignments", id: assignment_id },
          { type: "Assignments", id: "LIST" },
          { type: "Milestones" },
          // TODO: milestone tag, get milestone_id from update result for now, just invalidate all milestones
        ];
      },
    }),
    updateAssignmentMilestone: build.mutation({
      query: ({ assignment_id, milestone_id, changedValues }) => ({
        url: "assignment/editMilestone",
        method: "PUT",
        body: { _id: assignment_id, milestone_id, ...changedValues },
      }),
      invalidatesTags: (_result, _error, { assignment_id, milestone_id }) => {
        return [
          { type: "Assignments", id: assignment_id },
          { type: "Assignments", id: "LIST" },
          { type: "Milestones", id: milestone_id },
          { type: "Milestones", id: "LIST" },
        ];
      },
    }),
    recordOffPlatformPayment: build.mutation({
      query: ({ assignment_id, offPlatformMilestone_id, payment }) => ({
        url: "assignment",
        method: "PUT",
        // TODO: correct body
        body: { _id: assignment_id, offPlatformMilestone_id, ...payment },
      }),
      invalidatesTags: (
        _result,
        _error,
        { assignment_id, offPlatformMilestone_id },
      ) => [
        { type: "Assignments", id: assignment_id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: offPlatformMilestone_id },
        // TODO: milestone tag
      ],
    }),
    //* off platform payments should hit the update assignment endpoint for some reason, NOT the create transfer API
    createTransfer: build.mutation({
      query: ({ assignment_id, milestone_id, payment }) => ({
        url: "assignment/createTransfer",
        method: "POST",
        body: { _id: assignment_id, milestone_id, ...payment },
      }),
      invalidatesTags: (_result, _error, { assignment_id, milestone_id }) => [
        { type: "Assignments", id: assignment_id },
        { type: "Assignments", id: "LIST" },
        { type: "Milestones", id: milestone_id },
      ],
    }),
    // TODO: delete milestone

    // Deliverables
    getDeliverables: build.query({
      query: ({
        milestone_id,
        assignment_id,
        campaign_id,
        user_id,
        populate,
      }) => ({
        url: "deliverables",
        params: {
          milestone_id,
          assignment_id,
          campaign_id,
          user_id,
          populate,
          skip: 0,
          limit: 0,
        },
      }),
      providesTags: (result) =>
        providesListTags(result.results, "Deliverables"),
    }),
    getDeliverable: build.query({
      query: (id) => ({
        url: "deliverable",
        params: {
          _id: id,
        },
      }),
      providesTags: (_result, _error, id) => {
        return [{ type: "Deliverables", id }];
      },
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
    }),
    createDeliverable: build.mutation({
      query: (deliverable) => ({
        url: "deliverable",
        method: "POST",
        body: deliverable,
      }),
      invalidatesTags: (result, _error, _deliverable) => [
        { type: "Deliverables", id: "LIST" },
        { type: "Milestones", id: result?.milestone_id },
        { type: "Assignments", id: "LIST" },
        { type: "Assignments", id: result?.assignment_id },
      ],
    }),
    updateDeliverable: build.mutation({
      query: (deliverable) => {
        const formData = new FormData();
        Object.keys(deliverable).forEach((key) => {
          if (Array.isArray(deliverable[key]) && deliverable[key]?.length) {
            deliverable[key].forEach((item, _index) => {
              formData.append(key === "images" ? "images" : `${key}[]`, item);
            });
          } else {
            formData.append(key, deliverable[key]);
          }
        });
        return {
          url: "deliverable",
          method: "PUT",
          body: formData,
          formData: true, // sets multipart/form-data content-type header
        };
      },
      invalidatesTags: (result, _error, { _id }) => {
        return [
          { type: "Deliverables", id: _id },
          { type: "Deliverables", id: "LIST" },
          { type: "Milestones", id: result?.milestone_id },
          { type: "Assignments", id: "LIST" },
          { type: "Assignments", id: result?.assignment_id },
        ];
      },
    }),
    deleteDeliverable: build.mutation({
      query: (deliverable) => ({
        url: `deliverable/${deliverable?._id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, deliverable) => [
        { type: "Deliverables", id: deliverable?._id },
        { type: "Deliverables", id: "LIST" },
        { type: "Milestones", id: deliverable?.milestone_id },
        { type: "Assignments", id: "LIST" },
        { type: "Assignments", id: deliverable?.assignment_id },
      ],
    }),

    // agreements
    getAgreements: build.query({
      query: (filter) => ({
        url: "agreements",
        params: {
          skip: 0,
          limit: 0,
          populate: "user_id campaign_id assignment_id",
          ...filter,
        },
      }),
      transformResponse(response) {
        // @ts-ignore
        return response?.results;
      },
      providesTags: (result) => providesListTags(result.results, "Agreements"),
    }),
    getAgreement: build.query({
      query: (id) => ({
        url: "agreement",
        params: {
          _id: id,
          populate: "user_id campaign_id assignment_id",
        },
      }),
      transformResponse(response) {
        // @ts-ignore
        return response?.result;
      },
      providesTags: (_result, _error, id) => [{ type: "Agreements", id }],
    }),
    createAgreement: build.mutation({
      query: ({ files, ...agreementData }) => {
        const formData = new FormData();

        // Append agreement data to form data
        Object.keys(agreementData).forEach((key) => {
          formData.append(key, agreementData[key]);
        });

        // Append files to form data
        [...files]?.forEach((file, _index) => {
          formData.append("files", file);
        });

        return {
          url: "/agreement",
          method: "POST",
          body: formData,
          formData: true, // sets multipart/form-data content-type header
        };
      },
      invalidatesTags: (_result, _error) => [
        { type: "Agreements", id: "LIST" },
        { type: "Assignments", id: "LIST" },
      ],
    }),
    updateAgreement: build.mutation({
      query: ({ agreement_id, ...changedValues }) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, ...changedValues },
      }),
      invalidatesTags: (_result, _error, { agreement_id }) => {
        return [
          { type: "Agreements", id: agreement_id },
          { type: "Agreements", id: "LIST" },
          { type: "Assignments", id: "LIST" },
        ];
      },
    }),
    markAgreementSent: build.mutation({
      query: (agreement_id) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, adminHasSent: true },
      }),
      invalidatesTags: (_result, _error, { agreement_id }) => {
        return [
          { type: "Agreements", id: agreement_id },
          { type: "Agreements", id: "LIST" },
          { type: "Assignments", id: "LIST" },
        ];
      },
    }),
    markAgreementSigned: build.mutation({
      query: (agreement_id) => ({
        url: "agreement",
        method: "PUT",
        body: { _id: agreement_id, adminHasSigned: true },
      }),
      invalidatesTags: (_result, _error, { agreement_id }) => {
        return [
          { type: "Agreements", id: agreement_id },
          { type: "Agreements", id: "LIST" },
          { type: "Assignments", id: "LIST" },
        ];
      },
    }),
  }),
});

// @ts-ignore
export const {
  useGetAdminsQuery,

  useGetCreatorQuery,
  useGetCreatorsQuery,
  useUpdateCreatorMutation,
  useInviteCreatorMutation,

  useGetBrandQuery,
  useGetBrandsQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,

  useGetBrandUserQuery,
  useGetBrandUsersQuery,
  useInviteBrandUserMutation,
  useUpdateBrandUserMutation,

  useGetAssignmentsQuery,
  useGetAssignmentQuery,

  useGetMilestoneQuery,
  useCreateAssignmentMilestoneMutation,
  useUpdateAssignmentMilestoneMutation,
  useRecordOffPlatformPaymentMutation,
  useCreateTransferMutation,

  useGetDeliverableQuery,
  useGetDeliverablesQuery,
  useCreateDeliverableMutation,
  useUpdateDeliverableMutation,
  useDeleteDeliverableMutation,

  useGetAgreementsQuery,
  useGetAgreementQuery,
  useCreateAgreementMutation,
  useUpdateAgreementMutation,
  useMarkAgreementSentMutation,
  useMarkAgreementSignedMutation,
} = apiSlice;
