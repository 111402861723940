/**
 * @typedef {Object} PanelBodyProps
 * @property {'vertical'|'horizontal'} [split] - Panel split direction.
 * @property {boolean} [padded=false] - Padding around the panel body.
 * @property {boolean} [scrollable=false] - Enable scrollable content.
 * @property {boolean} [borders=false] - Display borders.
 * @property {*} children - Children components.
 */

/**
 * Panel Body Component
 * @param {PanelBodyProps} props
 * @returns {JSX.Element}
 */
const PanelBody = ({
  split, // can be vertical or horizontal
  padded = false,
  scrollable = false,
  borders = false,
  children,
  ...props
}) => {
  const optionsClassNames = [];
  if (split) optionsClassNames.push(`panel__body--split-${split}`);
  if (padded) optionsClassNames.push("panel__body--padded");
  if (borders) optionsClassNames.push("panel__body--borders");
  if (scrollable) optionsClassNames.push("panel__body--scroll");
  return (
    <div className={`panel__body bg-body ${optionsClassNames.join(" ")}`}>
      {children}
    </div>
  );
};

export default PanelBody;
