import { Badge } from "reactstrap";

const BadgeRenderer = ({ value, color, ...props }) => {
  return (
    <Badge pill color={color}>
      {value}
    </Badge>
  );
};

export default BadgeRenderer;
