// Renders a button that triggers a modal to assign an existing creator to a campaign

import { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";
import AssignExistingCreatorsModal from "./AssignExistingCreatorsModal";

const AssignExistingCreatorsModalButton = ({
  campaign_id,
  color = "dark",
  outline = false,
  btnText = "Assign to Campaign",
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="assign-to-campaign-modal-button">
      <Button
        color={color}
        outline={outline}
        className="d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        {btnText}
      </Button>
      <AssignExistingCreatorsModal
        modalOpen={modalOpen}
        campaign_id={campaign_id}
        toggleModal={toggleModal}
      ></AssignExistingCreatorsModal>
    </div>
  );
};

export default AssignExistingCreatorsModalButton;
