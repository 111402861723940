import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";
import { useCallback } from "react";
import { Button } from "reactstrap";

/**
 * Renders a button to trigger agreement signing.
 *
 * Must be a descendant of an AgreementIdContext.Provider.
 *
 * @returns {React.ReactNode} - The rendered component or null if no adminEmbeddedSigningUrl exists on the agreement.
 */
const AgreementConfigModalButton = () => {
  const { agreement } = useAgreementData();
  const { setAgreementUIStatus } = useAgreementContext();

  const onClick = useCallback(() => {
    setAgreementUIStatus("config");
  }, [setAgreementUIStatus]);

  if (!agreement?.embeddedRequestUrl || agreement?.status !== "Draft" || agreement?.adminHasSent || !agreement?.__isAdminActionRequired) {
    return null;
  }

  return (
    <>
      <Button onClick={onClick} color="primary">
        Configure Fields and Send
      </Button>
    </>
  );
};

export default AgreementConfigModalButton;
