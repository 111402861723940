import { connect } from "react-redux";
import { changeDisplayedAssignment, getCampaign } from "../../actions";
import "./index.scss";
import { useEffect, useState } from "react";
import CampaignDetails from "./CampaignDetails";
import PanelHeader from "../../../../components/Panel/PanelHeader";
import PanelBody from "../../../../components/Panel/PanelBody";
import Panel from "../../../../components/Panel";
import CampaignAssignments from "./CampaignAssignments";
import { useParams } from "react-router-dom";
import { Button, Label } from "reactstrap";
import { campaignDetailsDataDefs } from "modules/Campaigns/constants/dataDefs";
import { PencilIcon } from "@heroicons/react/24/outline";

const Campaign = ({
  getCampaign,
  displayedCampaign,
  changeDisplayedAssignment,
  ...props
}) => {
  const { campaign_id } = useParams();
  const [isEditing, setIsEditing] = useState(false);

  // TODO: this is duplicated from campaign detials
  const campaignDefs = campaignDetailsDataDefs(displayedCampaign);

  // TODO: better solution for controlling editing state. this is a temp fix to change the header layout
  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
    // TODO: reset form values
  };

  useEffect(() => {
    getCampaign(campaign_id);
  }, [campaign_id, getCampaign]);

  useEffect(() => {
    changeDisplayedAssignment(null);
  }, [changeDisplayedAssignment, campaign_id]);

  return (
    <article className="campaign" key={campaign_id}>
      <Panel key={campaign_id} overlay="expanded">
        <PanelHeader returnTo="/campaigns">
          <h1 className="fs-2 d-flex align-items-center">
            {isEditing ? (
              <>Editing Campaign</>
            ) : (
              <>
                <Label className="text-muted small">
                  {campaignDefs?.header?.label}:
                </Label>
                {campaignDefs?.header?.value}
                {displayedCampaign?._id && (
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    className="d-flex align-items-center ms-3"
                    onClick={() => setIsEditing(true)}
                  >
                    <PencilIcon height={20} width={20} />
                  </Button>
                )}
              </>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split={"vertical"}>
          <CampaignDetails
            campaign_id={campaign_id}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <CampaignAssignments campaign_id={campaign_id} />
        </PanelBody>
      </Panel>
    </article>
  );
};

const mapStateToProps = (state) => {
  return {
    displayedCampaign: state.campaigns.displayedCampaign,
  };
};
export default connect(mapStateToProps, {
  getCampaign,
  changeDisplayedAssignment,
})(Campaign);
