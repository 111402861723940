import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import "./index.scss";
import { Button, Label } from "reactstrap";
import { PencilIcon } from "@heroicons/react/24/outline";

import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import {
  changeDisplayedCreatorAssignment,
  getCreator,
} from "modules/Creators/actions";
import CreatorAssignments from "modules/Creators/components/Creator/CreatorAssignments";
import CreatorDetails from "modules/Creators/components/Creator/CreatorDetails";
import { creatorDetailsDataDefs } from "modules/Creators/constants/dataDefs";

const Creator = ({
  changeDisplayedCreatorAssignment,
  getCreator,
  displayedCreator,
  ...props
}) => {
  const { creator_id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const creatorDefs = creatorDetailsDataDefs(displayedCreator);

  useEffect(() => {
    getCreator(creator_id);
  }, [creator_id, getCreator]);

  useEffect(() => {
    changeDisplayedCreatorAssignment(null);
  }, [changeDisplayedCreatorAssignment, creator_id]);

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
    // TODO: reset form values
  };

  return (
    <article className="creator" key={creator_id}>
      <Panel key={creator_id} overlay={"expanded"}>
        <PanelHeader returnTo={"/creators"}>
          <h1 className="fs-2 d-flex align-items-center">
            {isEditing ? (
              <>Editing Creator</>
            ) : (
              <>
                <Label className="text-muted small">
                  {creatorDefs?.header?.label}:
                </Label>
                {creatorDefs?.header?.value}
                {displayedCreator?._id && (
                  <Button
                    color="secondary"
                    outline
                    size="sm"
                    className="d-flex align-items-center ms-3"
                    onClick={() => setIsEditing(true)}
                  >
                    <PencilIcon height={20} width={20} />
                  </Button>
                )}
              </>
            )}
          </h1>
        </PanelHeader>
        <PanelBody split={"vertical"}>
          <CreatorDetails
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            disableEditing={disableEditing}
          />
          <CreatorAssignments creator_id={creator_id} />
        </PanelBody>
      </Panel>
    </article>
  );
};

const mapStateToProps = (state) => {
  return {
    displayedCreator: state.creators.displayedCreator,
  };
};
export default connect(mapStateToProps, {
  getCreator,
  changeDisplayedCreatorAssignment,
})(Creator);
