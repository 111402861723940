/**
 * @typedef {import('react').ReactNode} ReactNode
 */

import CloseNav from "components/Buttons";
import BackNav from "components/Buttons/BackNav";

/**
 * Renders the header of a panel component.
 *
 * @typedef {Object} PanelHeaderProps
 * @property {string} [returnTo] - The URL to return to when the back button is clicked.
 * @property {Function} [closeAction] - The function to call when the close button is clicked.
 * @property {ReactNode} [children] - The content to display in the header.
 * @property {boolean} [unpadded] - Whether to remove padding from the header.
 * @property {string} [className] - Additional classes to apply to the header.
 *
 * @param {PanelHeaderProps} props - The props for the PanelHeader component.
 * @returns {JSX.Element} - The PanelHeader component.
 */
const PanelHeader = ({
  returnTo = undefined,
  closeAction = undefined,
  children = undefined,
  unpadded = false, // TODO: refactor
  className = "",
  ...props
}) => {
  return (
    <div
      className={`panel__header bg-body ${
        unpadded ? "panel__header--unpadded" : ""
      } ${className}`}
    >
      <div className="panel__header__nav panel__header__nav--back">
        <NavBtn returnTo={returnTo} closeAction={closeAction} />
      </div>
      {children && (
        <div className="panel__header__content">
          <div className="panel__title">{children}</div>
        </div>
      )}
      <div className="panel__header__nav panel__header__nav--back" />
    </div>
  );
};

/**
 * Renders a navigation button for the PanelHeader component.
 *
 * @param {Object} props - The props for the NavBtn function.
 * @param {string} [props.returnTo] - The URL to return to when the back button is clicked.
 * @param {Function} [props.closeAction] - The function to call when the close button is clicked.
 * @returns {JSX.Element|null} - The navigation button component or null if no props are provided.
 */
function NavBtn({ returnTo = undefined, closeAction = undefined, ...props }) {
  if (closeAction) {
    return <CloseNav onClick={closeAction} />;
  } else if (returnTo) {
    return <BackNav to={returnTo} />;
  } else {
    return null;
  }
}

export default PanelHeader;
