import { connect } from "react-redux";
import "./index.scss";
import DataGrid from "../../components/DataGrid";
import { creatorColDefs } from "./constants/columnDefs";
import { useParams } from "react-router-dom";
import Creator from "./components/Creator";
import CreateCreatorModalButton from "./components/InviteCreatorModalButton";

const Creators = ({
  definition,
  populateInitialValues,

  loading,
  creators,

  ...props
}) => {
  const { creator_id } = useParams();

  return (
    <>
      <section className="creators main-page">
        <div
          className={`creators__content main-page__content ${
            creator_id ? "invisible position-absolute" : ""
          }`}
        >
          <div className="main-page__header">
            <h1>Creators</h1>
            <CreateCreatorModalButton />
          </div>
          <div className="main-page__body">
            <DataGrid
              gridId="creators"
              borders={true}
              loading={loading}
              idField="_id"
              rowData={creators}
              columnDefs={creatorColDefs}
              quickFilter={true}
              appliedFilters={true}
            />
          </div>
        </div>
        {creator_id && <Creator creator_id={creator_id} />}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creators: state.creators.creators,
    loading: state.loading.GET_CREATORS?.pending,
  };
};
export default connect(mapStateToProps, {})(Creators);
