import { brandsActionTypes } from "../constants";

const initialState = {
  loading: false,
  brands: [],
  error: null,
};
const brandsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case brandsActionTypes.getBrands.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case brandsActionTypes.getBrands.SUCCESS:
      return {
        ...state,
        loading: false,
        brands: payload,
      };
    case brandsActionTypes.getBrands.FAILURE:
      return {
        ...state,
        loading: false,
        brands: [],
        error: payload,
      };

    case brandsActionTypes.createBrand.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case brandsActionTypes.createBrand.SUCCESS:
      return {
        ...state,
        loading: false,
        brands: [payload, ...state.brands],
      };
    case brandsActionTypes.createBrand.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export default brandsReducer;
