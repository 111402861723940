import { useCallback, useEffect, useState } from "react";
import FieldAppliedFilters from "./FieldAppliedFilters";
import "./index.scss";

const AppliedFilters = ({ gridApi }) => {
  const [appliedFilters, setAppliedFilters] = useState({});

  const updateFilterBadges = useCallback(() => {
    if (!gridApi) return;
    const currentFilters = gridApi.getFilterModel();
    if (currentFilters) {
      // Add colName to filter object
      const columnDefs = gridApi.getColumnDefs();
      Object.entries(currentFilters).forEach(([field, _filter]) => {
        const colDef = columnDefs.reduce((acc, def) => {
          if (acc) return acc; // If we've already found a match, return it
          // Search in children
          const foundInChildren = def.children?.find(
            (child) => child.field === field,
          );
          // If found in children, prepend headerName from parent to child and return new object
          if (foundInChildren) {
            return {
              ...foundInChildren,
              headerName: `${def.headerName} - ${foundInChildren.headerName}`,
            };
          }
          // Check the current def
          return def.field === field ? def : null;
        }, null);

        currentFilters[field].field = field;
        currentFilters[field].colName = colDef?.headerName;
        currentFilters[field].valueFormatter = colDef?.valueFormatter;
      });
    }
    setAppliedFilters(currentFilters);
  }, [gridApi]);

  useEffect(() => {
    // Listen for filter changes to update badges
    const onFilterChanged = () => {
      updateFilterBadges();
    };

    if (!gridApi) return;

    gridApi.addEventListener("filterChanged", onFilterChanged);

    // Initial fetch of the filter model
    updateFilterBadges();

    // Cleanup event listener when the component is unmounted or when gridApi changes
    return () => {
      gridApi.removeEventListener("filterChanged", onFilterChanged);
    };
  }, [gridApi, updateFilterBadges]);

  const handleRemoveFilter = useCallback(
    (field, conditionIndex) => {
      if (!gridApi || !field) return;
      const filterInstance = gridApi.getFilterInstance(field);
      const filter = filterInstance?.appliedModel;
      if (!filter) return;
      const conditions = filter?.conditions || [filter];
      if (!conditions || conditions.length < conditionIndex) return;
      conditions.splice(conditionIndex, 1);
      if (conditions.length === 0) {
        filterInstance.setModel(null);
      } else {
        const newModel = {
          ...filter,
          conditions,
        };
        filterInstance.setModel(newModel);
      }
      filterInstance.applyModel();
      gridApi.onFilterChanged(); // Notify AG-Grid of the filter change
    },
    [gridApi],
  );

  return (
    <div className="applied-filters">
      {Object.entries(appliedFilters).map(([field, filter]) => {
        return (
          <FieldAppliedFilters
            key={field}
            field={field}
            filter={filter}
            handleRemoveFilter={handleRemoveFilter}
          />
        );
      })}
    </div>
  );
};

export default AppliedFilters;
