import { useCallback } from "react";
import { connect } from "react-redux";
import { createCampaign } from "../../actions";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { campaignDetailsDataDefs } from "modules/Campaigns/constants/dataDefs";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import CreateCampaignFields from "./CreateCampaignFields";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";

/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  // TODO: unique validation messages
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  if (!values.status) {
    errors.status = "Required";
  }
  if (!values.brand_id) {
    errors.brand_id = "Required";
  }
  return errors;
};

// Get the field definitions for the campaign details form.
const fieldDefs = campaignDetailsDataDefs({})?.body;

/**
 * @typedef {Object} CreateCampaignModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} [initialCampaignName] - Initial campaign name
 * @property {boolean} loading - Loading state managed by redux
 * @property {Function} createCampaign - Create new campaign action
 */
/**
 * Create Campaign Modal
 * @param {CreateCampaignModalProps} props
 * @returns {JSX.Element}
 */
function CreateCampaignModal({
  modalOpen,
  toggleModal,
  initialCampaignName = undefined,

  loading,

  createCampaign,
  ...props
}) {
  const navigate = useNavigate();

  /**
   * Handle form submission
   * @typedef {Object} OnSubmitValues
   * @property {string} name - Campaign name
   *
   * @param {OnSubmitValues} values
   * @param {object} actions
   * @param {Function} actions.setSubmitting
   */
  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      try {
        const newCampaign = await createCampaign(values);
        toggleModal();
        navigate(`/campaigns/${newCampaign?._id}`);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [createCampaign, navigate, toggleModal],
  );

  const initialValues = getInitialValues(fieldDefs, {
    name: initialCampaignName,
  });

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {loading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Create Campaign</ModalHeader>
          <ModalBody>
            <CreateCampaignFields
              fieldDefs={fieldDefs}
              initialValues={initialValues}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.CREATE_CAMPAIGN?.pending,
  };
};
export default connect(mapStateToProps, {
  createCampaign,
})(CreateCampaignModal);
