import { createRequestActionTypes } from "actions";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/api/v1/admin/login`;
export const LOGOUT_URL = `${process.env.REACT_APP_API_URL}/api/v1/admin/logout`;
export const USER_URL = `${process.env.REACT_APP_API_URL}/api/v1/admin/`;

export const LOGIN_ERROR = "Error logging in";
export const LOGOUT_ERROR = "Error logging out";
export const CHECK_AUTH_ERROR
  = "Error verifying authentication. Please login again.";

/**
 * The action types for milestones.
 * @type {Object}
 * @property {string} login.request - The request action type for attempting login.
 * @property {string} login.success - The success action type for attempting login.
 * @property {string} login.failure - The failure action type for attempting login.
 * @property {string} logout.request - The request action type for attempting logout.
 * @property {string} logout.success - The success action type for attempting logout.
 * @property {string} logout.failure - The failure action type for attempting logout.
 * @property {string} getLoggedInUser.request - The request action type for fetching the logged in user.
 * @property {string} getLoggedInUser.success - The success action type for fetching the logged in user.
 * @property {string} getLoggedInUser.failure - The failure action type for fetching the logged in user.
 */
export const loginActionTypes = {
  login: createRequestActionTypes("LOGIN"),
  logout: createRequestActionTypes("LOGOUT"),
  getLoggedInUser: createRequestActionTypes("GET_LOGGED_IN_USER"),
};
