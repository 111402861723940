import { useRef, useState } from "react";

import { AgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";

/**
 * Provides the context for the Agreement components.
 *
 * @component
 * @param {Object} props - The component props.
 * @property {string} agreementId - The ID of the agreement.
 * @property {Function} setAgreementId - The function to set the agreement ID.
 * @property {string} creatorId - The ID of the agreement creator.
 * @property {string} assignmentId - The ID of the agreement assignment.
 * @property {React.ReactNode} children - The child components.
 * @returns {React.ReactNode} The rendered component.
 */
export const AgreementProvider = ({
  agreementId,
  setAgreementId,
  creatorId,
  assignmentId,
  children,
}) => {
  const [agreementUIStatus, setAgreementUIStatus] = useState("");
  const formRef = useRef(null);
  return (
    <AgreementContext.Provider
      value={{
        agreementId,
        setAgreementId,
        creatorId,
        assignmentId,
        agreementUIStatus,
        setAgreementUIStatus,
        formRef,
      }}
    >
      {children}
    </AgreementContext.Provider>
  );
};
