function checkIfGracePeriod(dateString) {
  const compareDate = new Date(dateString);
  const today = new Date();
  // grace period is within the same calendar month, unless it's currently within the first 5 days of the month, in which case the grace period also includes the previous calendar month
  const isGracePeriod
    = compareDate.getMonth() === today.getMonth()
    || (today.getDate() <= 5 && compareDate.getMonth() === today.getMonth() - 1);
  return isGracePeriod;
}

export default checkIfGracePeriod;
