/**
 * Generates a list of tags based on the provided results with IDs and tag type.
 * For more info on this pattern of cache invalidation with tag IDs and LIST, see https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#advanced-invalidation-with-abstract-tag-ids
 *
 * @param {Array} resultsWithIds - Array of results list items with IDs.
 * @param {string} tagType - The tag type string.
 * @param {string} tagId="LIST" - The tag ID string. Defaults to "LIST"
 * @returns {Array} - The list of tags.
 */
export const providesListTags = (resultsWithIds, tagType, tagId = "LIST") => {
  return resultsWithIds
    ? [
      { type: tagType, id: tagId },
      ...resultsWithIds.map(({ _id }) => ({ type: tagType, id: _id })),
    ]
    : [{ type: tagType, id: tagId }];
};
