import { useCallback } from "react";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import LabelledData from "components/Forms/LabelledData";
import { brandsDataDefs } from "../constants";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { useCreateBrandMutation } from "app/apiSlice";

/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  // TODO: unique validation messages
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

/**
 * @typedef {Object} CreateBrandModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {Function} [onBrandCreated] - Create brand callback. Receives the result of the createBrand action
 * @property {string} [initialBrandName] - Initial brand name
 */
/**
 * Create Brand Modal
 * @param {CreateBrandModalProps} props
 * @returns {JSX.Element}
 */
function CreateBrandModal({
  modalOpen,
  toggleModal,
  onBrandCreated = undefined,
  initialBrandName,

  ...props
}) {
  const [createBrand, { isLoading }] = useCreateBrandMutation();
  /**
   * Handle form submission
   * @typedef {Object} OnSubmitValues
   * @property {string} name - Brand name
   *
   * @param {OnSubmitValues} values
   * @param {object} actions
   * @param {Function} actions.setSubmitting
   */
  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      try {
        const newBrand = await createBrand(values).unwrap();
        if (onBrandCreated) onBrandCreated(newBrand);
        toggleModal();
      } catch (err) {
        console.error(err);
      }
      setSubmitting(false);
    },
    [createBrand, onBrandCreated, toggleModal],
  );

  const initialValues = getInitialValues(brandsDataDefs, {
    name: initialBrandName || "",
  });

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-brand-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {isLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>Create Brand</ModalHeader>
          <ModalBody>
            {brandsDataDefs?.map((fieldDef) => {
              return (
                <LabelledData
                  key={fieldDef?.fieldKey}
                  data={initialValues}
                  definition={fieldDef}
                  isEditing={true}
                  editingOnly={true}
                />
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Create
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

export default CreateBrandModal;
