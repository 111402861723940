import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useField } from "formik";
import { Button, ListGroup, ListGroupItem } from "reactstrap";

const Uploader = ({ name, accept, multiple, disabled, invalid, ...props }) => {
  const [field, , helpers] = useField(name);

  const handleChange = (event) => {
    const files = event.currentTarget.files;
    helpers.setValue(multiple ? files : files[0]);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    helpers.setValue(multiple ? files : files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDelete = (index) => {
    const files = Array.from(field.value);
    files.splice(index, 1);
    helpers.setValue(files);
  };

  const truncateFilename = (filename, maxLength) => {
    if (filename.length <= maxLength) {
      return filename;
    }
    return filename.slice(0, maxLength) + "...";
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "150px",
          borderStyle: "dashed",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className={`bg-body-secondary text-secondary p-2 border rounded ${invalid ? "border-danger" : ""} ${disabled ? "d-none" : "d-flex"}`}
      >
        <input
          type="file"
          accept={accept}
          onChange={handleChange}
          onBlur={field.onBlur}
          name={field.name}
          id={field.name}
          multiple={multiple}
          style={{ display: "none" }}
        />
        <label
          htmlFor={field.name}
          style={{ cursor: "pointer", height: "100%" }}
          className="d-flex flex-column justify-content-evenly align-items-center"
        >
          <ArrowUpTrayIcon height={50} width={50} />
          Drag and drop files here or click to upload
        </label>
      </div>
      {field.value && (
        <ListGroup className="mt-1">
          {Array.from(field.value).map((file, index) => (
            <ListGroupItem
              key={index}
              className="d-flex justify-content-between align-items-center"
            >
              <span title={file.name}>{truncateFilename(file.name, 50)}</span>
              <Button
                color="danger"
                outline
                className="p-1"
                title="Remove file"
                onClick={() => handleDelete(index)}
              >
                <XMarkIcon height={20} width={20} />
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </>
  );
};

export default Uploader;
