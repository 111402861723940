import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Brands from "modules/Brands";
import Campaigns from "modules/Campaigns";
import Creators from "modules/Creators";
import Dashboard from "modules/Dashboard";
import Login from "modules/Login";
import Logout from "modules/Login/Logout";
import PrivatePageLayout from "routers/PrivatePageLayout";
import ProtectedRouteLayout from "routers/ProtectedRouteLayout";
import PublicPageLayout from "routers/PublicPageLayout";
import { Agreements } from "features/agreements";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Private Routes */}
        <Route element={<PrivatePageLayout />}>
          <Route
            element={
              <ProtectedRouteLayout requiredPermissions={["dashboard"]} />
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
          <Route path="/campaigns/:campaign_id?" element={<Campaigns />} />
          <Route
            path="/creators/:creator_id?/:assignmentTab?/:assignment_id?"
            element={<Creators />}
          />

          {/* start new creators routes */}
          {/* <Route path="/creators" element={<CreatorsPage />}>
            <Route path=":creator_id" element={<CreatorPage />}>
              <Route path="campaigns" element={<CreatorCampaigns />}>
                <Route path=":campaign_id" element={<CreatorCampaign />}>
                  <Route
                    path="milestones"
                    element={<CreatorCampaignMilestones />}
                  />
                  <Route
                    path="agreements"
                    element={<CreatorCampaignAgreements />}
                  />
                  <Route
                    path="deliverables"
                    element={<CreatorCampaignDeliverables />}
                  />
                  <Route
                    index // default to milestones
                    element={<Navigate replace to="milestones" />}
                  />
                </Route>
              </Route>
              <Route path="agreements" element={<CreatorAgreements />}>
                <Route path=":agreement_id" element={<CreatorAgreement />} />
              </Route>
              <Route path="deliverables" element={<CreatorDeliverables />}>
                <Route
                  path=":deliverable_id"
                  element={<CreatorDeliverable />}
                />
              </Route>
              <Route path="milestones" element={<CreatorMilestones />}>
                <Route path=":milestone_id" element={<CreatorMilestone />} />
              </Route>
              <Route index element={<Navigate replace to="campaigns" />} />
            </Route>
          </Route> */}
          {/* end new creators routes */}

          <Route
            path="/brands/:brand_id?/:assignmentTab?/:assignment_id?"
            element={<Brands />}
          />
          <Route path="/agreements" element={<Agreements />} />
        </Route>
        {/* Public Routes */}
        <Route element={<PublicPageLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Route>
        {/* // TODO: 404 page */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
