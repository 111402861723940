/**
 * Returns an array of column definitions for a data grid with a checkbox selection column, and the view button removed.
 * @param {Array} colDefs - An array of column definitions for the data grid.
 * @returns {Array} An array of column definitions with a checkbox selection column.
 */
export const getSelectGridColDefs = (colDefs) => {
  return [
    {
      headerName: "",
      field: "",
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: false,
    },
    ...colDefs.filter((colDef) => colDef.field !== "viewButton"),
  ];
};
