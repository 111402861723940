/**
 * Render filter condition/expression into a human readable format
 *
 * @param {Object} condition - The filter condition object.
 * @param {string} condition.type - The type of filter condition.
 * @param {string} [condition.filter] - The filter value.
 * @param {string} [condition.filterTo] - The filter value.
 * @param {string} [condition.filterType] - The filter type.
 * @param {string} [condition.dateFrom] - Date filter value from.
 * @param {string} [condition.dateTo] - Date filter value to.
 */
export const renderFilterCondition = (condition) => {
  let conditionType = condition.type;
  if (condition.filterType === "date") {
    if (condition.type === "lessThan" || condition.type === "greaterThan") {
      conditionType = `${condition.type}Date`;
    }
  }

  return (
    <span>
      {conditionTextDict[conditionType]} {renderValues(condition)}
    </span>
  );
};

function renderValues(condition) {
  if (!condition) return null;
  let filter = condition.filter;
  let filterTo = condition.filterTo;
  if (condition.filterType === "date") {
    filter = condition.dateFrom;
    filterTo = condition.dateTo;
  }
  return (
    <>
      <span className="condition-value">
        {format(filter, condition.valueFormatter)}
      </span>
      {filterTo != null && (
        <>
          {" "}
          and{" "}
          <span className="condition-value">
            {format(filterTo, condition.valueFormatter)}
          </span>
        </>
      )}
    </>
  );
}

function format(value, formatter) {
  if (typeof formatter !== "function") {
    return value;
  }
  return formatter({ value });
}

const conditionTextDict = {
  equals: "equals",
  notEqual: "does not equal",
  notBlank: "is not blank",
  blank: "is blank",
  startsWith: "starts with",
  endsWith: "ends with",
  contains: "contains",
  notContains: "does not contain",
  true: "is true",
  false: "is false",
  lessThan: "is less than",
  lessThanOrEqual: "is less than or equal to",
  greaterThan: "is greater than",
  greaterThanOrEqual: "is greater than or equal to",
  lessThanDate: "is before",
  greaterThanDate: "is after",
  inRange: "is between",
  future: "is in the future",
  past: "is in the past",
};
