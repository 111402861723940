import { useEffect } from "react";
import { connect } from "react-redux";
import "./index.scss";
import { getCampaigns } from "./actions";
import DataGrid from "../../components/DataGrid";
import CreateCampaignModalButton from "./components/CreateCampaignModalButton";
import { campaignColDefs } from "./constants/columnDefs";
import { useParams } from "react-router-dom";
import Campaign from "./components/Campaign";

const Campaigns = ({
  loading,
  campaigns,

  getCampaigns,
}) => {
  const { campaign_id } = useParams();

  useEffect(() => {
    if (!campaign_id && !campaigns?.length) {
      getCampaigns();
    }
  }, [campaign_id, campaigns?.length, getCampaigns]);

  return (
    <>
      <section className="campaigns main-page">
        <div
          className={`campaigns__content main-page__content ${
            campaign_id ? "invisible position-absolute" : ""
          }`}
        >
          <div className="main-page__header">
            <h1>Campaigns</h1>
            <CreateCampaignModalButton />
          </div>
          <div className="main-page__body">
            <DataGrid
              gridId="campaigns"
              borders={true}
              idField="_id"
              rowData={campaigns}
              loading={loading}
              columnDefs={campaignColDefs}
              quickFilter={true}
              appliedFilters={true}
            />
          </div>
        </div>
        {campaign_id && <Campaign campaign_id={campaign_id} />}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaigns: state.campaigns.campaigns,
    loading: state.loading.GET_CAMPAIGNS?.pending,
  };
};
export default connect(mapStateToProps, {
  getCampaigns,
})(Campaigns);
