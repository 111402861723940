import { connect } from "react-redux";
import "./index.scss";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { getCampaign, updateCampaign } from "../../actions";
import Panel from "../../../../components/Panel";
import PanelBody from "../../../../components/Panel/PanelBody";
import LabelledData from "../../../../components/Forms/LabelledData";
import { Formik } from "formik";
import UnsavedChangesPrompt from "../../../../components/Forms/UnsavedChangesPrompt";
import PanelFooter from "../../../../components/Panel/PanelFooter";
import { campaignDetailsDataDefs } from "../../constants/dataDefs";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { getChangedValues } from "helpers/getChangedValues";

const CampaignDetails = ({
  campaign_id,
  isEditing,
  setIsEditing,
  disableEditing,

  displayedCampaign,

  getCampaign,
  updateCampaign,
  ...props
}) => {
  useEffect(() => {
    if (campaign_id) {
      getCampaign(campaign_id);
    }
  }, [campaign_id, getCampaign]);

  const campaignDefs = campaignDetailsDataDefs(displayedCampaign);

  const initialValues = getInitialValues(campaignDefs?.body, displayedCampaign);

  return (
    <div className="campaign-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={async(values, actions) => {
          const changedValues = getChangedValues(values, initialValues);
          if (Object.keys(changedValues).length) {
            await updateCampaign({
              _id: displayedCampaign?._id,
              ...changedValues,
            });
          }
          actions.setSubmitting(false);
          setIsEditing(false);
        }}
        validate={(_values) => {
          // TODO: validate
        }}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true}>
                {campaignDefs?.body?.map((def, i) => {
                  if (def?.role === "section") {
                    return (
                      <div key={i} className="labeled-data-container">
                        {def?.label && (
                          <h2 className="fs-5 section-label text-muted">
                            {def?.label}
                          </h2>
                        )}
                        {def?.fields?.map((subDef, j) => {
                          return (
                            <LabelledData
                              key={j}
                              definition={subDef}
                              isEditing={isEditing}
                              data={displayedCampaign}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    displayedCampaign: state.campaigns.displayedCampaign,
  };
};
export default connect(mapStateToProps, {
  getCampaign,
  updateCampaign,
})(CampaignDetails);
