// Track loading state by dispatched action name

import { getActionName } from "helpers/getActionName";

const loadingReducer = (state = {}, { type, _payload }) => {
  const actionName = getActionName(type);

  if (!actionName) return state;

  if (type.endsWith("_REQUEST")) {
    return { ...state, [actionName]: { pending: true } };
  }

  if (type.endsWith("_SUCCESS") || type.endsWith("_FAILURE")) {
    return { ...state, [actionName]: { pending: false } };
  }

  return state;
};

export default loadingReducer;
