import DataGrid from "../../components/DataGrid";
import { brandsColDefs } from "./constants";

import "./index.scss";
import CreateBrandModalButton from "./components/CreateBrandModalButton";
import Brand from "./components/Brand";
import { useNavigate, useParams } from "react-router-dom";
import { useGetBrandsQuery } from "app/apiSlice";
import { useCallback } from "react";

const Brands = () => {
  const { brand_id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetBrandsQuery();
  const brands = data?.results || [];

  const onBrandClick = useCallback((brand) => {
    if (!brand?._id) return;
    navigate(`/brands/${brand?._id}`);
  }, [navigate]);

  return (
    <>
      <section className="brands main-page">
        <div
          className={`brand__content main-page__content ${brand_id ? "invisible position-absolute" : ""}`}
        >
          <div className="main-page__header">
            <h1>Brands</h1>
            <CreateBrandModalButton />
          </div>
          <div className="main-page__body">
            <DataGrid
              gridId="brands"
              idField="_id"
              loading={isLoading}
              rowData={brands}
              columnDefs={brandsColDefs}
              appliedFilters={true}
              quickFilter={true}
              actionText="View"
              actionClick={onBrandClick}
              borders
            />
          </div>

          {/* // TODO: add section to view all campaigns/creators for a given brand  */}
        </div>
        {brand_id && <Brand />}
      </section>
    </>
  );
};

export default Brands;
