import { titleCaseFormatter } from "helpers/formatters";
import { Badge } from "reactstrap";

const TopupStatusRenderer = ({ value = "pending", ...props }) => {
  let color = "warning";
  if (value === "pending") {
    color = "warning";
  } else if (value === "failed") {
    // TODO: is this the actual value for failed topups?
    color = "danger";
  } else if (value === "succeeded") {
    color = "secondary";
  }
  return (
    <Badge pill color={color}>
      {titleCaseFormatter(value)}
    </Badge>
  );
};

export default TopupStatusRenderer;
