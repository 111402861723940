import { dashboardActionTypes } from "../constants";

const initialState = {
  loading: false,
  milestonesUpcoming: [],
  milestonesDue: [],
  assignmentsUpcoming: [],
  assignmentsDue: [],
  stripeBalancePending: null,
  stripeBalanceAvailable: null,
  stripeBalanceTotal: null,
  pendingTopups: [],
  pastTopups: [],
  creatorBalanceAvailable: null,
  creatorBalancePaid: null,
  creatorBalanceHold: null,
  creatorBalanceEarned: null,
  creatorBalancePotentiallyOwed: null,
  creatorBalanceMilestoneHold: null,
  creatorBalanceMilestonePotentiallyOwed: null,
  creatorBalanceMilestoneOwed: null,
  forecastData: null,
  error: null,
};
const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case dashboardActionTypes.getCreatorBalances.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getCreatorBalances.SUCCESS:
      return {
        ...state,
        loading: false,
        creatorBalanceAvailable: payload.availableBalance,
        creatorBalancePaid: payload.paidBalance,
        creatorBalanceHold: payload.holdBalance,
        creatorBalanceEarned: payload.paidBalance + payload.availableBalance,
        creatorBalancePotentiallyOwed:
          payload.holdBalance - payload.milestoneOwed,
        creatorBalanceMilestoneHold: payload.milestoneHoldBalance,
        creatorBalanceMilestonePotentiallyOwed:
          payload.milestonePotentiallyOwed,
        creatorBalanceMilestoneOwed: payload.milestoneOwed,
      };
    case dashboardActionTypes.getCreatorBalances.FAILURE:
      return {
        ...state,
        loading: false,
        creatorBalanceAvailable: null,
        creatorBalancePaid: null,
        creatorBalanceHold: null,
        creatorBalanceEarned: null,
        creatorBalancePotentiallyOwed: null,
        creatorBalanceMilestoneHold: null,
        creatorBalanceMilestonePotentiallyOwed: null,
        creatorBalanceMilestoneOwed: null,
      };
    case dashboardActionTypes.getStripeBalances.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getStripeBalances.SUCCESS:
      return {
        ...state,
        loading: false,
        stripeBalancePending: payload.pendingBalance,
        stripeBalanceAvailable: payload.availableBalance,
        stripeBalanceTotal: payload.totalBalance,
        pendingTopups: payload.pendingTopups,
        pastTopups: payload.pastTopups,
      };
    case dashboardActionTypes.getStripeBalances.FAILURE:
      return {
        ...state,
        loading: false,
        stripeBalancePending: null,
        stripeBalanceAvailable: null,
        stripeBalanceTotal: null,
        pendingTopups: [],
        pastTopups: [],
      };
    case dashboardActionTypes.getMilestonesUpcoming.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getMilestonesUpcoming.SUCCESS:
      return {
        ...state,
        loading: false,
        milestonesUpcoming: payload,
      };
    case dashboardActionTypes.getMilestonesUpcoming.FAILURE:
      return {
        ...state,
        loading: false,
        milestonesUpcoming: [],
        error: payload,
      };
    case dashboardActionTypes.getMilestonesDue.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getMilestonesDue.SUCCESS:
      return {
        ...state,
        loading: false,
        milestonesDue: payload,
      };
    case dashboardActionTypes.getMilestonesDue.FAILURE:
      return {
        ...state,
        loading: false,
        milestonesDue: [],
        error: payload,
      };
    case dashboardActionTypes.getAssignmentsUpcoming.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getAssignmentsUpcoming.SUCCESS:
      return {
        ...state,
        loading: false,
        assignmentsUpcoming: payload,
      };
    case dashboardActionTypes.getAssignmentsUpcoming.FAILURE:
      return {
        ...state,
        loading: false,
        assignmentsUpcoming: [],
        error: payload,
      };
    case dashboardActionTypes.getAssignmentsDue.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getAssignmentsDue.SUCCESS:
      return {
        ...state,
        loading: false,
        assignmentsDue: payload,
      };
    case dashboardActionTypes.getAssignmentsDue.FAILURE:
      return {
        ...state,
        loading: false,
        assignmentsDue: [],
        error: payload,
      };

    case dashboardActionTypes.getForecastData.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardActionTypes.getForecastData.SUCCESS:
      return {
        ...state,
        loading: false,
        forecastData: payload,
      };
    case dashboardActionTypes.getForecastData.FAILURE:
      return {
        ...state,
        loading: false,
        forecastData: null,
        error: payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
