import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAssignmentsUpcoming } from "../../actions";
import DataGrid from "../../../../components/DataGrid";
import { assignmentsUpcomingColDefs } from "../../constants";
import AssignmentMilestonesModal from "./AssignmentMilestonesModal";

const AssignmentsUpcoming = ({
  assignmentsUpcoming,
  loading,
  getAssignmentsUpcoming,
  activeTabId,
  tabId,
  ...props
}) => {
  useEffect(() => {
    getAssignmentsUpcoming();
  }, [getAssignmentsUpcoming]);
  const [assignmentMilestonesModalOpen, setAssignmentMilestonesModalOpen]
    = useState(false);
  const [activeAssignment, setActiveAssignment] = useState(null);

  const toggleAssignmentMilestonesModal = useCallback(
    (assignment) => {
      setActiveAssignment(assignment);
      setAssignmentMilestonesModalOpen((isOpen) => !isOpen);
    },
    [setAssignmentMilestonesModalOpen],
  );
  const refreshAssignments = useCallback(async() => {
    let assignments;
    try {
      assignments = await getAssignmentsUpcoming();
      if (activeAssignment) {
        setActiveAssignment(
          assignments.find(
            (assignment) => assignment._id === activeAssignment?._id,
          ),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [getAssignmentsUpcoming, setActiveAssignment, activeAssignment]);
  return (
    <div className="assignments-upcoming">
      <DataGrid
        gridId="assignments-upcoming"
        // autoHeight
        visible={activeTabId === tabId}
        borders
        // sizeToFit
        autoSizeEnabled={true}
        autoSizeIgnoreHeaders={true}
        idField="_id"
        rowData={assignmentsUpcoming}
        columnDefs={assignmentsUpcomingColDefs}
        loading={loading}
        actionClick={toggleAssignmentMilestonesModal}
        actionText={"View"}
        quickFilter={true}
        appliedFilters={true}
      />
      <AssignmentMilestonesModal
        isOpen={assignmentMilestonesModalOpen}
        toggle={toggleAssignmentMilestonesModal}
        onClosed={refreshAssignments}
        assignment={activeAssignment}
        type="upcoming"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    assignmentsUpcoming: state.dashboard.assignmentsUpcoming,
    loading: state.loading.GET_ASSIGNMENTS_UPCOMING?.pending,
  };
};
export default connect(mapStateToProps, {
  getAssignmentsUpcoming,
})(AssignmentsUpcoming);
