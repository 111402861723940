import useAuthorizationCheck from "hooks/useAuthorizationCheck";

/**
 * Renders the children components only if the user has the required permissions.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.requiredPermissions - The required permissions for the user to render the children components.
 * @param {React.ReactNode} props.children - The children components to be rendered.
 * @returns {React.ReactNode} - The rendered children components if the user has the required permissions, otherwise null.
 */
const ProtectedRender = ({ requiredPermissions, children, ...props }) => {
  const isAuthorized = useAuthorizationCheck(requiredPermissions);

  return <>{isAuthorized ? children : null}</>;
};

export default ProtectedRender;
