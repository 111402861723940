import DataGrid from "components/DataGrid";
import { topupsColDefs } from "./constants/columnDefs";
import { connect } from "react-redux";

const PastTopupsPane = ({
  activeTabId,
  tabId,

  loading,
  pastTopups,
  ...props
}) => {
  return (
    <DataGrid
      gridId="past-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={pastTopups}
      columnDefs={topupsColDefs}
      autoSizeEnabled={true}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_STRIPE_BALANCES?.pending,
    pastTopups: state.dashboard.pastTopups,
  };
};
export default connect(mapStateToProps, {})(PastTopupsPane);
