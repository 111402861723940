/**
 * Returns the initial values for a form based on the provided definitions and initial data.
 * Allows for nested definitions and setting value overrides in the definitions.
 *
 * @param {Array} definitions - An array of field definitions.
 * @param {Object} data - An object containing the initial data.
 * @returns {Object} - An object containing the initial values for the form.
 */
import { ISODateFormatter } from "../../../helpers/formatters";
const getInitialValues = (definitions, data) => {
  let initialValues = {};

  if (!definitions) {
    console.error("Definitions required");
    return initialValues;
  }

  definitions.forEach((def) => {
    if (!def) {
      console.error("Definition object required");
      return;
    }

    if (def.role === "section") {
      initialValues = {
        ...initialValues,
        ...getInitialValues(def.fields, data),
      };
      return;
    }

    const key = def.editing?.fieldKey ?? def.fieldKey;
    const value = def.value ?? data?.[key] ?? def.editing?.defaultValue;

    initialValues[key] = def.type === "date" ? ISODateFormatter(value) : value;
  });

  if (data?._id) {
    initialValues._id = data._id;
  }

  return initialValues;
};

export { getInitialValues };
