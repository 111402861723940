import {
  useCreateAssignmentMilestoneMutation,
  useGetAssignmentQuery,
  useUpdateAssignmentMilestoneMutation,
} from "app/apiSlice";
import LabelledData from "components/Forms/LabelledData";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import Loader from "components/Loader";
import { showToast } from "components/Toasts/helpers/showToast";
import { milestoneFields } from "features/milestones/constants";
import { Formik } from "formik";
import { getChangedValues } from "helpers/getChangedValues";
import { useCallback, useMemo } from "react";
import { Button, Form, ModalBody, ModalFooter } from "reactstrap";
import "./index.scss";
import useResyncLegacyStore from "hooks/useResyncLegacyStore";
import {
  validateMilestoneCreate,
  validateMilestoneUpdate,
} from "features/milestones/validation";

const Milestone = ({
  assignment_id,
  milestone_id,
  toggleMilestoneModal,
  ...props
}) => {
  const { milestone, assignment, error, isLoadingMilestone } =
    useGetAssignmentQuery(assignment_id, {
      selectFromResult: ({ data, error, isLoading }) => ({
        assignment: data,
        milestone:
          data?.milestones?.find(
            (milestone) => milestone?._id === milestone_id,
          ) || {},
        error,
        isLoading,
      }),
      refetchOnMountOrArgChange: true, // This fixed the bug where milestone validation was using stale assignment balances after the assignment balance was updated
    });

  const [updateMilestone, { isLoading: isLoadingUpdate }] =
    useUpdateAssignmentMilestoneMutation();
  const [createMilestone, { isLoading: isLoadingCreate }] =
    useCreateAssignmentMilestoneMutation();

  const [resyncAfterMutation] = useResyncLegacyStore();

  const initialValues = useMemo(
    () => getInitialValues(milestoneFields, milestone),
    [milestone],
  );
  const validate = useCallback(
    (values) => {
      //* separate validation logic for create/update
      if (values._id) {
        return validateMilestoneUpdate({
          fields: milestoneFields,
          values,
          initialValues,
          milestone,
          assignment,
        });
      } else {
        return validateMilestoneCreate({
          fields: milestoneFields,
          values,
          assignment,
        });
      }
    },
    [assignment, initialValues, milestone],
  );

  const submit = useCallback(
    async(values, actions) => {
      try {
        if (milestone?._id) {
          // update
          if (
            assignment_id !== assignment?._id ||
            milestone_id !== milestone?._id
          ) {
            const message = "Error updating milestone - ID mismatch";
            showToast({
              type: "error",
              message,
            });
            throw new Error(message);
          }
          const changedValues = getChangedValues(values, initialValues);
          if (!changedValues?.isComplete && !initialValues?.isComplete) {
            // do not update dateCompleted if milestone is not complete or being completed
            delete changedValues?.dateCompleted;
          }
          if (Object.keys(changedValues).length) {
            // @ts-ignore
            const { error } = await updateMilestone({
              assignment_id,
              milestone_id,
              changedValues,
            });
            if (error?.status) {
              showToast({
                type: "error",
                message: "Error updating milestone.",
              });
              throw new Error(error);
            } else {
              showToast({
                type: "success",
                message: "Milestone updated successfully.",
              });
              resyncAfterMutation();
              toggleMilestoneModal();
            }
          }
        } else {
          // create
          // @ts-ignore
          const { error } = await createMilestone({
            assignment_id,
            milestone: values,
          });
          if (error?.status) {
            showToast({
              type: "error",
              message: "Error creating milestone.",
            });
            throw new Error(error);
          } else {
            showToast({
              type: "success",
              message: "Milestone created successfully.",
            });
          }
        }
        resyncAfterMutation();
        toggleMilestoneModal();
      } catch (error) {
        console.error({ error });
        // TODO
      }
      actions.setSubmitting(false);
    },
    [
      milestone?._id,
      resyncAfterMutation,
      toggleMilestoneModal,
      assignment_id,
      assignment?._id,
      milestone_id,
      initialValues,
      updateMilestone,
      createMilestone,
    ],
  );

  if (!assignment_id) {
    const message = "Error opening milestone form - no assignment ID";
    showToast({
      type: "error",
      message,
    });
    console.error(message);
    return null;
  }
  if (assignment && assignment_id !== assignment?._id) {
    const message = "Error opening milestone form - assignment ID mismatch";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      assignment_id,
      "assignment?._id": assignment?._id,
    });
    return null;
  }
  if (
    milestone_id &&
    milestone?._id &&
    milestone_id !== milestone?._id &&
    !isLoadingMilestone
  ) {
    const message = "Error opening milestone - milestone ID mismatch";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      milestone_id,
      "milestone?._id": milestone?._id,
    });
    return null;
  }
  // TODO: do i need to re-throw error?
  if (error) {
    const message = "Error opening milestone";
    showToast({
      type: "error",
      message,
    });
    console.error({
      message,
      error,
    });
    return null;
  }

  const isLoading = isLoadingUpdate || isLoadingCreate || isLoadingMilestone;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      enableReinitialize={true}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <UnsavedChangesPrompt />
          <ModalBody>
            {isLoading && <Loader />}
            {milestoneFields?.map((def, i) => {
              return (
                <LabelledData
                  key={i}
                  definition={def}
                  isEditing={true}
                  data={milestone || {}}
                  hideConditionCompareValues={assignment}
                  disableConditionCompareValues={initialValues}
                />
              );
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMilestoneModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              {milestone ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default Milestone;
