import { createContext, useContext } from "react";

/**
 * Context for storing the agreement ID and UI status.
 * @type {React.Context}
 * @example
 *
 * // To set up the provider:
 * <AgreementContext.Provider value={{agreementId, agreementUIStatus, setAgreementUIStatus}}>
 *  {children}
 * </AgreementContext.Provider>
 */
const AgreementContext = createContext(null);

/**
 * Custom hook for accessing the agreement context.
 * @returns {Object} The agreement context.
 * @property {string} agreementId - The agreement ID.
 * @property {Function} setAgreementId - A function to set the agreement ID.
 * @property {string} creatorId - The creator ID. Used when creating a new agreement.
 * @property {string} assignmentId - The assignment ID. Used when creating a new agreement.
 * TODO: come up with a better solution for agreementUIStatus that isn't just random strings
 * @property {string|null} agreementUIStatus - The agreement UI status. Ex: "edit", "create", "config", "sign", "closed", or null
 * @property {Function} setAgreementUIStatus - A function to set the agreement UI status.
 * @property {React.RefObject} formRef - A reference to the agreement form.
 * @throws {Error} If used outside of an AgreementContext.Provider.
 */
const useAgreementContext = () => {
  const context = useContext(AgreementContext);
  if (!context) {
    throw new Error(
      "useAgreementContext must be used within an AgreementContext.Provider",
    );
  }
  return context;
};

export { AgreementContext, useAgreementContext };
