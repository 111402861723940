import { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import NavbarHeader from "components/NavbarHeader";
import { getLoggedInUser } from "modules/Login/actions";
import Loader from "components/Loader";
import { getCreators } from "modules/Creators/actions";

const PrivatePageLayout = ({
  isAuthenticated,
  getLoggedInUser,
  getCreators,
  creators,
  ...props
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);
  useEffect(() => {
    if (!isAuthenticated) {
      console.debug("Not authenticated. Redirecting to login...");
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  // Pre-load creators
  // TODO: have a more permanent solution for the slow creators readMany
  useEffect(() => {
    if (!creators?.length) {
      setTimeout(() => {
        getCreators();
      }, 1);
    }
  }, [getCreators, creators?.length]);

  return (
    <>
      <NavbarHeader />
      <main>
        <Suspense fallback={<Loader solid="true" />}>
          <Outlet />
        </Suspense>
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
    creators: state.creators.creators,
  };
};
export default connect(mapStateToProps, { getLoggedInUser, getCreators })(
  PrivatePageLayout,
);
