import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const LinkButtonCellRenderer = ({ value, btnText = "View", ...props }) => {
  return (
    <Button
      color="dark"
      outline
      className="m-0"
      size="sm"
      tag={Link}
      to={value}
    >
      {btnText}
    </Button>
  );
};

export default LinkButtonCellRenderer;
