import { useEffect } from "react";

/**
 * A custom React hook that shows or hides a loading overlay on a DataGrid component based on the `loading` prop.
 *
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @param {boolean} loading - A boolean value that determines whether to show or hide the loading overlay.
 */
export const useLoadingOverlay = (gridApi, loading) => {
  useEffect(() => {
    if (!gridApi) return;
    if (loading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [loading, gridApi]);
};
