import { Button } from "reactstrap";

const Tab = ({ children, tabId, activeTabId, setActiveTabId, ...props }) => {
  return (
    <Button
      className={`rounded-pill m-1 ${activeTabId === tabId ? "isActive" : ""}`}
      color={activeTabId === tabId ? "primary" : "secondary"}
      outline
      onClick={() => setActiveTabId(tabId)}
    >
      {children}
    </Button>
  );
};
export default Tab;
