import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getCampaigns } from "modules/Campaigns/actions";
import DataGrid from "components/DataGrid";
import { getCreatorCampaignAssignments } from "modules/Creators/actions";
import { getSelectGridColDefs } from "components/DataGrid/helpers/getSelectGridColDefs";
import { campaignColDefs } from "modules/Campaigns/constants/columnDefs";

const selectCampaignColDefs = getSelectGridColDefs(campaignColDefs);
/**
 * A component that renders a list of campaigns with checkboxes and allows the user to select one.
 *
 * @param {Object} props - The component props.
 * @param {function} props.setSelectedId - A function to set the selected campaign ID when the user makes a selection change.
 * @param {string} props.creator_id - ID of the creator being assigned.
 * @param {Array} props.campaigns - An array of campaign objects to display in the DataGrid, from redux.
 * @param {Array} props.assignedCampaignIds - An array of existing campaign assignments for this creator, from redux.
 * @param {boolean} props.loading - A boolean indicating the current loading state, from redux.
 * @param {function} props.getCampaigns - A redux action to fetch the list of campaigns.
 * @param {function} props.getCreatorCampaignAssignments - A redux action to fetch the assigned campaign ids.
 * @returns {JSX.Element} - The SelectCampaign component.
 */
const AssignableCampaignSelect = ({
  setSelectedId,
  creator_id,

  campaigns,
  assignedCampaignIds,
  loading,
  getCampaigns,
  getCreatorCampaignAssignments,
  ...props
}) => {
  useEffect(() => {
    try {
      getCampaigns();
      getCreatorCampaignAssignments(creator_id);
    } catch (error) {
      console.error(error);
    }
  }, [creator_id, getCampaigns, getCreatorCampaignAssignments]);

  // TODO: make this a custom hook
  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedNodes();
    if (selectedRows.length > 0) {
      setSelectedId(selectedRows[0].id);
    } else {
      setSelectedId(null);
    }
  };

  const isRowSelectable = useMemo(() => {
    return (rowNode) => {
      return !assignedCampaignIds.includes(rowNode.id);
    };
  }, [assignedCampaignIds]);

  return (
    <>
      <div className="mb-3">
        <small className="text-muted">
          Note: Campaigns without checkboxes mean the creator is already
          assigned to that campaign.
        </small>
      </div>
      <DataGrid
        gridId="campaigns-select"
        columnDefs={selectCampaignColDefs}
        idField="_id"
        rowData={campaigns}
        loading={loading}
        height="500px"
        borders
        onSelectionChanged={handleSelectionChanged}
        isRowSelectable={isRowSelectable}
        quickFilter={true}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    campaigns: state.campaigns.campaigns,
    assignedCampaignIds: state.creators.creatorCampaignAssignments.map(
      (assignment) => assignment.campaign_id?._id ?? assignment.campaign_id,
    ),
    loading:
      state.loading.GET_CAMPAIGNS?.pending
      || state.loading.GET_CREATOR_CAMPAIGN_ASSIGNMENTS?.pending,
  };
};
export default connect(mapStateToProps, {
  getCampaigns,
  getCreatorCampaignAssignments,
})(AssignableCampaignSelect);
