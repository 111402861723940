import DataGrid from "components/DataGrid";
import { getForecastData } from "modules/Dashboard/actions";
import { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { forecastColDefs } from "../../constants/index";
import "./index.scss";

const isFullWidth = (data) => {
  return !!data.isFullWidthRow;
};

const PaymentForecast = ({
  loading,
  getForecastData,
  forecastData,
  ...props
}) => {
  useEffect(() => {
    getForecastData();
  }, [getForecastData]);
  const rows = useMemo(() => {
    if (!forecastData) return;
    const sortedWeeks = forecastData?.weeks?.sort((a, b) => {
      if (a.date === "before") return 1;
      if (b.date === "before") return -1;
      return 0;
    });
    return sortedWeeks?.reduce((acc, week) => {
      acc.push({
        _id: week.date,
        date: week.date,
        amount: week.amount,
        count: week.count,
        isFullWidthRow: true,
      });
      week.days.forEach((day) => {
        acc.push({
          _id: day.date,
          date: day.date,
          amount: day.amount,
          count: day.count,
        });
      });
      return acc;
    }, []);
  }, [forecastData]);

  const getRowClass = useCallback((params) => {
    if (isFullWidth(params.node.data)) {
      return "full-width-row week-row";
    }
    return "day-row";
  }, []);
  const getRowHeight = useCallback((params) => {
    if (isFullWidth(params.node.data)) {
      return 50;
    }
  }, []);

  return (
    <div className="payment-forecast__content">
      <DataGrid
        // width="600px"
        gridId="forecast"
        footer={false}
        borders
        autoHeight
        loading={loading}
        rowData={rows}
        columnDefs={forecastColDefs}
        quickFilter={false}
        appliedFilters={false}
        // isFullWidthRow={isFullWidthRow}
        // fullWidthCellRenderer={fullWidthCellRenderer}
        getRowClass={getRowClass}
        getRowHeight={getRowHeight}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_FORECAST_DATA?.pending,
    forecastData: state.dashboard.forecastData,
  };
};
export default connect(mapStateToProps, {
  getForecastData,
})(PaymentForecast);
