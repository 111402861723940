import TabbedNav from "components/TabbedNav";
import TabPanes from "components/TabbedNav/TabPanes";
import { creatorAssignmentTabOptions } from "modules/Creators/constants/tabOptions";
import { useState } from "react";

const CreatorAssignmentTabDetail = () => {
  const [activeTabId, setActiveTabId] = useState(
    creatorAssignmentTabOptions[0]?.id,
  );

  return (
    <div className="assignment-tab-container">
      <TabbedNav
        activeTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        tabs={creatorAssignmentTabOptions}
      ></TabbedNav>
      <TabPanes
        activeTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        tabs={creatorAssignmentTabOptions}
      />
    </div>
  );
};

export default CreatorAssignmentTabDetail;
