// @ts-nocheck
import DataGrid from "components/DataGrid";
import { milestoneColDefs } from "features/milestones/constants";
import { useState } from "react";
import "./index.scss";
import MilestoneModal from "features/milestones/MilestoneModal";
import clsx from "clsx";

const MilestonesList = ({ className = "", ...props }) => {
  const [activeMilestone_id, setActiveMilestone_id] = useState(null);
  const [activeAssignment_id, setActiveAssignment_id] = useState(null);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);

  const handleMilestoneClick = ({ _id, assignment_id }) => {
    setActiveAssignment_id(assignment_id);
    setActiveMilestone_id(_id);
    setMilestoneModalOpen(!!_id);
  };
  const handleMilestoneClosed = () => {
    setActiveAssignment_id(null);
    setActiveMilestone_id(null);
    setMilestoneModalOpen(false);
  };

  const clsxStr = clsx("milestones", className);

  return (
    <div className={clsxStr}>
      <DataGrid
        gridId={"milestones"}
        columnDefs={milestoneColDefs}
        autoSizeIgnoreHeaders={false}
        actionClick={handleMilestoneClick}
        actionText={"View"}
        autoHeight={false}
        {...props}
      />
      <MilestoneModal
        milestone_id={activeMilestone_id}
        assignment_id={activeAssignment_id}
        isOpen={milestoneModalOpen}
        setIsOpen={setMilestoneModalOpen}
        onModalClosed={handleMilestoneClosed}
      />
    </div>
  );
};

export default MilestonesList;
