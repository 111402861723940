import {
  currencyFormatter,
  dateFormatter,
  dateTimeFormatter,
} from "../../../helpers/formatters";

/**
 * Default column definition for the data grid.
 * @type {Object}
 */
export const defaultDefaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  editable: false,
  filterParams: {
    buttons: ["reset"],
    maxNumConditions: 5,
  },
  minWidth: 80,
  maxWidth: 350,
};

/**
 * Column types for the data grid.
 * @type {Object}
 */
export const columnTypes = {
  text: { cellDataType: "text" },
  boolean: { cellDataType: "boolean", sortingOrder: ["desc", "asc", null] },
  date: {
    cellDataType: "date",
    sortingOrder: ["desc", "asc", null],
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    valueFormatter: (params) => {
      return dateFormatter(params.value, {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      });
    },
    filterParams: {
      filterOptions: [
        "equals",
        {
          displayKey: "future",
          displayName: "Future",
          predicate: (filterValue, cellValue) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const cellDate = new Date(cellValue);
            return cellDate >= today;
          },
          numberOfInputs: 0,
        },
        {
          displayKey: "past",
          displayName: "Past",
          predicate: (filterValue, cellValue) => {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const cellDate = new Date(cellValue);
            return cellDate < today;
          },
          numberOfInputs: 0,
        },
        "lessThan",
        "greaterThan",
        "inRange",
        "notEqual",
        "blank",
        "notBlank",
      ],
    },
  },
  dateTime: {
    cellDataType: "date",
    sortingOrder: ["desc", "asc", null],
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    valueFormatter: (params) => {
      return dateTimeFormatter(params.value);
    },
  },
  dateString: {
    cellDataType: "dateString",
    sortingOrder: ["desc", "asc", null],
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    valueFormatter: (params) => {
      return dateFormatter(params.value, {
        year: "2-digit",
        month: "numeric",
        day: "numeric",
      });
    },
  },
  currency: {
    cellDataType: "number",
    sortingOrder: ["desc", "asc", null],
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
    valueFormatter: (params) => {
      // TODO: handle null values for all column types
      const value = params?.value ?? 0;
      return currencyFormatter(value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
  number: {
    cellDataType: "number",
    sortingOrder: ["desc", "asc", null],
    headerClass: "ag-right-aligned-header",
    cellClass: "ag-right-aligned-cell",
  },
};
