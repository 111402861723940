import { useCallback } from "react";
import { Button, Form } from "reactstrap";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import PanelBody from "components/Panel/PanelBody";
import Panel from "components/Panel";
import LabelledData from "components/Forms/LabelledData";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { Formik } from "formik";
import PanelFooter from "components/Panel/PanelFooter";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import Loader from "components/Loader";
import { getChangedValues } from "helpers/getChangedValues";
import { useParams } from "react-router-dom";
import { brandsDataDefs } from "../constants";
import { useGetBrandQuery, useUpdateBrandMutation } from "app/apiSlice";

const BrandDetails = ({
  isEditing,
  setIsEditing,
  disableEditing,
  ...props
}) => {
  const { brand_id } = useParams();
  const { data, isLoading: isLoadingQuery } = useGetBrandQuery(brand_id);
  const displayedBrand = data?.result || [];
  const initialValues = getInitialValues(brandsDataDefs, displayedBrand);
  const [updateBrand, { isLoading: isLoadingUpdate }]
    = useUpdateBrandMutation();

  const isLoading = isLoadingQuery || isLoadingUpdate;

  const onSubmit = useCallback(
    async(values, actions) => {
      const changedValues = getChangedValues(values, initialValues);
      if (Object.keys(changedValues).length) {
        try {
          await updateBrand({
            _id: displayedBrand?._id,
            ...changedValues,
          }).unwrap();
          setIsEditing(false);
        } catch (error) {
          console.error(error);
        }
      }
      actions.setSubmitting(false);
    },
    [displayedBrand?._id, initialValues, setIsEditing, updateBrand],
  );

  return (
    <div className="creator-details details-panel d-flex">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        // validate={(values) => {
        //   // TODO: validate
        // }}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel>
              <PanelBody padded={true} scrollable={true}>
                {isLoading && <Loader />}

                {brandsDataDefs?.map((def, i) => {
                  return (
                    <LabelledData
                      key={i}
                      definition={def}
                      isEditing={isEditing}
                      data={displayedBrand}
                      {...def}
                    />
                  );
                })}
              </PanelBody>
              {isEditing && (
                <PanelFooter>
                  <UnsavedChangesPrompt />
                  <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                    <Button
                      color="secondary"
                      outline
                      size="lg"
                      className="d-flex align-items-center"
                      onClick={() => disableEditing(dirty)}
                    >
                      <XMarkIcon height={20} width={20} className="me-2" />
                      Cancel
                    </Button>
                    <Button
                      color="dark"
                      className="d-flex align-items-center"
                      type="submit"
                      size="lg"
                    >
                      <CheckIcon height={20} width={20} className="me-2" />
                      Save
                    </Button>
                  </div>
                </PanelFooter>
              )}
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BrandDetails;
