import CustomSelect from "components/Forms/CustomSelect";
import BoolCellRenderer from "../../DataGrid/customRenderers/BoolCellRenderer";
import { memo } from "react";
import { useFormikContext } from "formik";

export const milestoneColDefs = [
  {
    headerName: "Name",
    field: "name",
    type: "text",
    // valueGetter: (params) => {
    //   return "July 2023 Complete";
    // },
  },
  {
    headerName: "Status/Notes",
    field: "status",
    type: "text",
  },
  {
    headerName: "Rate",
    field: "rate",
    type: "currency",
  },
  {
    headerName: "Potentially Owed",
    field: "holdBalance",
    type: "currency",
  },
  // {
  //   headerName: "Available",
  //   field: "availableBalance",
  //   type: "currency",
  // },
  {
    headerName: "Earned",
    field: "paidBalance",
    type: "currency",
  },
  {
    headerName: "Payment Due Date",
    field: "nextPaymentDate",
    type: "date",
  },
  {
    headerName: "Complete?",
    field: "isComplete",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Completed Date",
    field: "dateCompleted",
    type: "date",
    // valueGetter: (params) => {
    //   return new Date(2023, 7, 0);
    // },
  },
  {
    headerName: "Release Payment?",
    field: "releasePayment",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
];

export const mileStoneFields = [
  {
    fieldKey: "name",
    type: "text",
    editing: {
      required: true,
    },
    componentProps: { label: "Title" },
  },
  {
    fieldKey: "rate",
    type: "number",
    editing: {
      required: true,
    },
    componentProps: { label: "Rate" },
  },
  {
    fieldKey: "updateAssignmentRate",
    type: "switch",
    creatorVisible: true,
    editing: {
      required: false,
      hideCondition: (values, assignment) => {
        const milestonesBalance = assignment.milestones.reduce(
          (acc, milestone) => {
            if (milestone._id === values?._id) return acc;
            return acc + milestone.rate;
          },
          0,
        );
        const remainingBalance = assignment?.rate - milestonesBalance;
        const shouldHide = remainingBalance >= (values?.rate || 0);
        return shouldHide;
      },
      componentProps: { label: "Update Assignment Rate?" },
    },
  },
  {
    fieldKey: "status",
    type: "text",
    editing: {
      required: false,
    },
    componentProps: { label: "Status/Notes" },
  },
  {
    fieldKey: "nextPaymentDate",
    type: "date",
    editing: {
      required: false,
    },
    componentProps: { label: "Payment Due Date" },
  },
  {
    fieldKey: "isComplete",
    type: "switch",
    editing: {
      required: false,
      disableCondition: (values) => values?.releasePayment,
    },
    componentProps: { label: "Completed?" },
  },
  {
    fieldKey: "dateCompleted",
    type: "date",
    editing: {
      required: false,
      hideCondition: (values) => !values?.isComplete && !values?.dateCompleted,
      disableCondition: (values, initialValues) =>
        (initialValues?.isComplete && values?.isComplete)
        || values?.releasePayment,
      componentProps: { label: "Date Completed" },
    },
  },
  {
    fieldKey: "releasePayment",
    type: "switch",
    editing: {
      required: false,
      // TODO: disableCondition not working for field type switch
      disableCondition: (values, initialValues) =>
        (!initialValues?.isComplete && !values?.isComplete)
        || !values?.dateCompleted,
    },
    componentProps: { label: "Release Payment?" },
  },
];

export const mileStonePaymentFields = [
  {
    // TODO: key
    fieldKey: "offPlatformPayment",
    type: "switch",
    componentProps: { label: "Off Platform Payment?" },
    editing: true,
  },
  {
    fieldKey: "offPlatformCreated",
    type: "date",
    hideCondition: (values) => !values?.offPlatformPayment,
    editing: {
      componentProps: { label: "Payment Date" },
    },
  },
  {
    fieldKey: "offPlatformReferenceId",
    type: "text",
    hideCondition: (values) => !values?.offPlatformPayment,
    editing: {
      componentProps: { label: "Reference ID" },
    },
  },
  {
    fieldKey: "offPlatformPlatform",
    type: "text", // TODO: select
    // TODO: handle required for conditional fields
    // required: false,
    componentProps: { label: "Platform/Service" },
    hideCondition: (values) => !values?.offPlatformPayment,
    editing: true,
  },
  {
    fieldKey: "offPlatformAmount",
    type: "number", // TODO: currency input
    // TODO: handle required for conditional fields
    // required: false,
    componentProps: { label: "Amount" },
    hideCondition: (values) => !values?.offPlatformPayment,
    editing: {
      required: false,
    },
  },
  {
    fieldKey: "paymentType",
    type: "select",
    // required: false,
    hideCondition: (values) => values?.offPlatformPayment,
    componentProps: { label: "Payment Type" },
    editing: {
      // required: true,
      CustomRenderer: (props) => {
        // TODO: CustomField add type for select
        const { values } = useFormikContext();

        if (
          props.hideCondition
          && props.hideCondition(values, props.hideConditionCompareValues)
        ) {
          return null;
        }
        return (
          <CustomSelect
            {...props}
            name="paymentType"
            options={[
              { value: "full", label: "Full" },
              { value: "partial", label: "Partial" },
            ]}
          />
        );
      },
    },
  },
  {
    fieldKey: "amountPartial",
    type: "number", // TODO: currency input
    // TODO: handle required for conditional fields
    // required: false,
    creatorVisible: true,
    componentProps: { label: "Amount" },
    hideCondition: (values) =>
      values?.offPlatformPayment
      || !values?.paymentType
      || values?.paymentType === "full",
    editing: {
      required: false,
    },
  },
  {
    fieldKey: "amount",
    type: "number", // TODO: currency input
    // TODO: handle required for conditional fields
    // required: false,
    // valueGetter: (params) => console.log(params), //TODO: fix valueGetter
    creatorVisible: true,
    componentProps: { label: "Amount" },
    hideCondition: (values) =>
      values?.offPlatformPayment
      || !values?.paymentType
      || values?.paymentType === "partial",
    editing: false,
  },
  {
    fieldKey: "description",
    type: "textarea",
    hideCondition: (values) => values?.offPlatformPayment,
    creatorVisible: true,
    editing: {
      required: false,
    },
    componentProps: { label: "Description (Optional)" },
  },
  {
    fieldKey: "expectedPaymentDate",
    type: "date",
    hideCondition: (values) => values?.offPlatformPayment,
    creatorVisible: true,
    editing: {
      required: false,
    },
    componentProps: { label: "Update Expected Pay Date (Optional)" },
  },
  {
    fieldKey: "status",
    type: "text",
    hideCondition: (values) => values?.offPlatformPayment,
    creatorVisible: true,
    editing: {
      required: false,
    },
    componentProps: { label: "Update Assignment Status (Optional)" },
  },
];
