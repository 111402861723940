import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useState } from "react";
import { createCreatorCampaignAssignment } from "modules/Creators/actions";
import SelectAssignableCampaign from "../AssignableCampaignSelect";

/**
 * @typedef {Object} AssignToCampaignModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {boolean} loading - Modal open state
 * @property {string} creator_id - Modal open state
 * @property {function} createCreatorCampaignAssignment - Redux actions to assign the creator to the selected campaign
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 */
/**
 * Assign Creator to Campaign Modal
 * @param {AssignToCampaignModalProps} props
 * @returns {JSX.Element}
 */
function AssignToCampaignModal({
  creator_id,
  modalOpen,
  toggleModal,

  createCreatorCampaignAssignment,

  ...props
}) {
  const [selectedCampaign_id, setSelectedCampaign_id] = useState(null);

  const handleSaveAssignmentClick = async() => {
    try {
      await createCreatorCampaignAssignment({
        user_id: creator_id,
        campaign_id: selectedCampaign_id,
      });
      toggleModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size="xl"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      <ModalHeader toggle={toggleModal}>Assign Creator to Campaign</ModalHeader>
      <ModalBody>
        <SelectAssignableCampaign
          setSelectedId={setSelectedCampaign_id}
          creator_id={creator_id}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          color="dark"
          disabled={!selectedCampaign_id}
          onClick={handleSaveAssignmentClick}
        >
          Save Assignment
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.CREATE_CREATOR_CAMPAIGN_ASSIGNMENT?.pending,
  };
};
export default connect(mapStateToProps, {
  createCreatorCampaignAssignment,
})(AssignToCampaignModal);
