import { memo } from "react";

import BadgeRenderer from "components/DataGrid/customRenderers/BadgeRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import LinkButtonCellRenderer from "components/DataGrid/customRenderers/LinkButtonCellRenderer";
import WarningBoolCellRenderer from "components/DataGrid/customRenderers/WarningBoolCellRenderer";
import CampaignStatusBadge from "modules/Campaigns/components/CampaignStatusBadge";

export const creatorColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    // TODO: display name/name override
    headerName: "Name",
    field: "name",
    type: "text",
  },
  {
    headerName: "Email",
    field: "email",
    type: "text",
    width: 200,
  },
  {
    headerName: "Verified Email",
    field: "emailVerified",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Country",
    field: "country",
    type: "text",
  },
  {
    headerName: "Agency",
    field: "isAgency",
    valueGetter: (params) => {
      return params.data.isAgency ? "Agency" : "Creator";
    },
    // TODO: customize filter to behave like boolean - should be only two options in dropdown for 'creator' or 'agency'
  },
  {
    headerName: "Stripe Onboarding",
    field: "needsStripeOnboarding",
    type: "boolean",
    cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
    valueGetter: (params) => {
      return !params.data.needsStripeOnboarding;
    },
    cellRenderer: memo(BoolCellRenderer),
    // BoolCellRenderer needs params for truthyText and falsyText
    cellRendererParams: {
      truthyText: "Complete",
      falsyText: "Incomplete",
    },
  },
  {
    headerName: "Financial Account",
    field: "needsStripeFinancialConnection",
    type: "boolean",
    cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
    valueGetter: (params) => {
      return !params.data.needsStripeFinancialConnection;
    },
    cellRenderer: memo(BoolCellRenderer),
    cellRendererParams: {
      truthyText: "Linked",
      falsyText: "Not linked",
    },
  },
  {
    headerName: "Hold ($)",
    field: "__holdBalance",
    type: "currency",
  },
  {
    headerName: "Earnings ($)",
    field: "__earnings",
    type: "currency",
  },
  {
    // TODO: handle __availableBalanceCurrency
    headerName: "Available ($)",
    field: "__availableBalance",
    type: "currency",
  },
  {
    headerName: "Paid Out ($)",
    field: "__paidBalance",
    type: "currency",
  },
  {
    headerName: "Last Login",
    sort: "desc", // initial sort order on load
    field: "lastLogin",
    type: "date",
    valueGetter: (params) => {
      return params.data?.lastLogin ? new Date(params.data?.lastLogin) : null;
    },
  },
  {
    headerName: "First Login",
    field: "firstLogin",
    type: "date",
    valueGetter: (params) => {
      return params.data?.firstLogin ? new Date(params.data?.firstLogin) : null;
    },
  },
  {
    headerName: "Created",
    field: "created",
    type: "date",
    // valueGetter: (params) => {
    //   return params.data?.created ? new Date(params.data?.created) : null;
    // },
  },
  {
    headerName: "Account Disabled",
    field: "disabled",
    type: "boolean",
    cellRenderer: memo(WarningBoolCellRenderer),
  },
  {
    headerName: "",
    field: "viewButton",
    cellRenderer: memo(LinkButtonCellRenderer),
    valueGetter: (params) => {
      return `/creators/${params.data?._id}/campaigns`;
    },
    cellClass: "d-flex justify-content-center cell-button",
    minWidth: 100,
    maxWidth: 100,
    pinned: "right",
    suppressSizeToFit: true,
    suppressMovable: true,
    lockPinned: true,
    lockVisible: true,
    sortable: false,
    filter: false,
  },
];

export const creatorAgreementAssignmentColDefs = [
  // TODO: creator agreement coldefs
];

export const creatorCampaignAssignmentColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Campaign",
    field: "campaignName",
    type: "text",
  },
  {
    headerName: "Campaign Status",
    field: "campaign_id.status",
    type: "text",
    cellRenderer: memo(CampaignStatusBadge), // importing this makes the app crash... why?
  },
  {
    headerName: "Assignment Status",
    field: "status",
    type: "text",
    cellRenderer: memo(BadgeRenderer), // importing this makes the app crash... why?
  },
  {
    headerName: "Total Rate",
    field: "rate",
    type: "currency",
  },
  {
    headerName: "Cashed Out",
    type: "currency",
    field: "paidBalance",
  },
  {
    headerName: "Withdrawable",
    field: "availableBalance",
    type: "currency",
  },
  {
    headerName: "Hold",
    // ? formerly "Potentially Owed"
    field: "holdBalance",
    type: "currency",
  },
  {
    headerName: "Expected Pay Date",
    field: "nextPayoutDate",
    type: "date",
  },
  {
    // TODO: more detailed milestone counts for completed/incomplete
    headerName: "Milestones",
    field: "milestones",
    type: "number",
    valueGetter: (params) => {
      return params.data?.milestones?.length || 0;
    },
    sort: "desc", // initial sort order on load
  },
  {
    headerName: "Agreements",
    // TODO: show due/complete counts
    field: "__agreementMetrics.total",
    type: "number",
  },
  {
    headerName: "Deliverables",
    // TODO: show due/complete counts
    field: "__deliverableMetrics.total",
    type: "number",
  },
  {
    headerName: "Assigned Date",
    field: "created",
    type: "date",
  },
];
