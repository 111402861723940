// Renders a button that triggers a modal to create a new campaign

import { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateCreatorModal from "./InviteCreatorModal";

const CreateCreatorModalButton = ({
  color = "dark",
  outline = false,
  btnText = "Invite Creator",
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="create-campaign">
      <Button
        color={color}
        outline={outline}
        className="d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        {btnText}
      </Button>
      <CreateCreatorModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      ></CreateCreatorModal>
    </div>
  );
};

export default CreateCreatorModalButton;
