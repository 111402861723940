import { Formik } from "formik";
import { Form } from "reactstrap";

import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";
import { agreementFieldDefs } from "features/agreements/constants/fieldDefs";

/**
 * Represents the AgreementForm component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.initialValues - The initial values for the form.
 * @param {Function} props.onSubmit - The function to be called when the form is submitted.
 * @param {React.ReactNode} props.children - The child components to be rendered inside the form.
 * @returns {JSX.Element} The rendered AgreementForm component.
 */
const AgreementForm = ({ initialValues, onSubmit, children }) => {
  const { formRef } = useAgreementContext();

  // const handleSubmit = useCallback(() => {
  //   onSubmit();
  // }, [onSubmit]);
  const validate = (values) => {
    const errors = {};
    agreementFieldDefs?.forEach((field) => {
      // @ts-ignore
      if (field?.fieldKey === "files" && !values?.files?.length && !values?._id) {
        // do something
        errors.files = `A file is required`;
      }
      if (field?.editing?.required && !values[field?.fieldKey]) {
        errors[field?.fieldKey] = `This field is required`;
      }

      if (
        field?.fieldKey === "adminSigner_id"
        && values?.requiresAdminSignature
        && !values?.adminSigner_id
      ) {
        errors.adminSigner_id = `Must specify admin signer if admin signature is required`;
      }
    });

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      // @ts-ignore
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
      innerRef={formRef}
    >
      <Form
        // @ts-ignore
        onSubmit={onSubmit}
      >
        {children}
      </Form>
    </Formik>
  );
};

export default AgreementForm;
