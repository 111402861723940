import DataGrid from "components/DataGrid";
import { topupsColDefs } from "./constants/columnDefs";
import { connect } from "react-redux";

const PendingTopupsPane = ({
  activeTabId,
  tabId,

  loading,
  pendingTopups,
  ...props
}) => {
  return (
    <DataGrid
      gridId="pending-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={pendingTopups}
      columnDefs={topupsColDefs}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_STRIPE_BALANCES?.pending,
    pendingTopups: state.dashboard.pendingTopups,
  };
};
export default connect(mapStateToProps, {})(PendingTopupsPane);
