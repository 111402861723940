import { PlusIcon } from "@heroicons/react/24/outline";
import { useGetAssignmentQuery } from "app/apiSlice";
import DataGrid from "components/DataGrid";
import Loader from "components/Loader";
import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import { showToast } from "components/Toasts/helpers/showToast";
import { milestoneColDefs } from "features/milestones/constants";
import { useState } from "react";
import { Button } from "reactstrap";
import MilestoneModal from "features/milestones/MilestoneModal";
import "./index.scss";

const Milestones = ({ assignment_id, ...props }) => {
  const [activeMilestone_id, setActiveMilestone_id] = useState(null);
  const [milestoneModalOpen, setMilestoneModalOpen] = useState(false);
  const { milestones, error, isLoading } = useGetAssignmentQuery(
    assignment_id,
    {
      selectFromResult: ({ data, error, isLoading }) => ({
        milestones: data?.milestones?.map((milestone) => {
          return {
            ...milestone,
            assignment_id: data._id,
          };
        }),
        error,
        isLoading,
      }),
      skip: !assignment_id,
    },
  );

  if (!assignment_id) {
    console.error("No assignment_id provided to Milestones component.");
    return null;
  }
  if (error) {
    showToast({
      type: "error",
      message: "Error fetching milestones.",
    });
  }

  const handleMilestoneClick = ({ _id }) => {
    setActiveMilestone_id(_id);
    setMilestoneModalOpen(!!_id);
  };

  const handleClickCreateMilestone = () => {
    setActiveMilestone_id(null);
    setMilestoneModalOpen(true);
  };

  return (
    <section className="milestones">
      {isLoading && <Loader />}
      <Panel overlay={"expanded"}>
        <PanelHeader unpadded={true}>
          {/* // TODO: need create milestone stuff to be in its own component so i can put it with the nav tab component. But to do that I need to refactor and not make it rely on global state */}
          <Button
            color="dark"
            className="d-flex m-1 align-items-center float-end"
            // TODO: onclick open new milestone modal
            onClick={handleClickCreateMilestone}
          >
            <PlusIcon height={20} width={20} className="me-2" />
            New Milestone
          </Button>
        </PanelHeader>
        <PanelBody borders={true}>
          <DataGrid
            gridId="milestones"
            columnDefs={milestoneColDefs}
            autoSizeIgnoreHeaders={false}
            rowData={milestones}
            loading={isLoading}
            actionClick={handleMilestoneClick}
            actionText={"View"}
            autoHeight={true}
          />
        </PanelBody>
      </Panel>
      <MilestoneModal
        milestone_id={activeMilestone_id}
        assignment_id={assignment_id}
        isOpen={milestoneModalOpen}
        setIsOpen={setMilestoneModalOpen}
      />
    </section>
  );
};

export default Milestones;
