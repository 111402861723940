import checkIfGracePeriod from "helpers/checkIfGracePeriod";
import { getChangedValues } from "helpers/getChangedValues";

export function validateMilestoneCreate({ fields, values, assignment }) {
  const errors = {};
  fields?.forEach((field) => {
    // required fields
    if (
      field?.editing?.required
      && !values[field?.fieldKey]
      && typeof values[field?.fieldKey] !== "number"
    ) {
      errors[field?.fieldKey] = `This field is required`;
    }

    // numbers must be positive
    if (field?.type === "number" && values[field?.fieldKey] < 0) {
      errors[field?.fieldKey] = `Must be a positive number`;
    }

    // check milestone rate does not exceed assignment rate
    if (
      field?.fieldKey === "updateAssignmentRate" || field?.fieldKey === "rate"
    ) {
      const milestonesBalance = assignment?.milestones?.reduce(
        (acc, milestone) => {
          return acc + milestone.rate;
        },
        0,
      );
      const remainingBalance = assignment?.rate - milestonesBalance;
      if (
        remainingBalance < (values.rate || 0)
        && !values.updateAssignmentRate
      ) {
        errors.updateAssignmentRate
          = `Milestone would exceed remaining assignment rate. Decrease Milestone rate or increase Assignment rate.`;
      }
    }

    if (field?.fieldKey === "dateCompleted") {
      if (!values.dateCompleted && values.isComplete) {
        errors[field?.fieldKey] = `Completion date is required`;
      }

      if (values.dateCompleted) {
        const dateCompleted = new Date(values.dateCompleted);
        const today = new Date();
        // must not be in the future
        if (dateCompleted > today) {
          errors[field?.fieldKey] = "Completion date cannot be in the future.";
        } else {
          // date completed must be within grace period
          if (!checkIfGracePeriod(values.dateCompleted)) {
            errors[field?.fieldKey]
              = "Completion date must be within 1 calendar month of today.";
          }
        }
      }
    }

    // if releasing payment...
    if (
      field.fieldKey === "releasePayment"
      && values?.releasePayment
    ) {
      // can not release for payment if not complete
      if (!values?.isComplete) {
        errors[field?.fieldKey]
          = "Milestones can not be released for payment if they are incomplete.";
      }
    }
  });

  return errors;
}

export function validateMilestoneUpdate({
  fields,
  values,
  initialValues,
  milestone,
  assignment,
}) {
  const errors = {};
  const changedValues = getChangedValues(values, initialValues);
  fields?.forEach((field) => {
    if (
      field?.editing?.required
      && !values[field?.fieldKey]
      && typeof values[field?.fieldKey] !== "number"
    ) {
      errors[field?.fieldKey] = `This field is required`;
    }

    if (field?.type === "number" && values[field?.fieldKey] < 0) {
      errors[field?.fieldKey] = `Must be a positive number`;
    }

    if (
      field?.fieldKey === "updateAssignmentRate"
    ) {
      // make sure total of all milestones does not exceed assignment rate/budget
      const milestonesBalance = assignment?.milestones?.reduce(
        (acc, milestone) => {
          if (milestone._id === values?._id) return acc;
          return acc + milestone.rate;
        },
        0,
      );
      const remainingBalance = assignment?.rate - milestonesBalance;
      if (
        remainingBalance < (values.rate || 0)
        && !values.updateAssignmentRate
      ) {
        errors[field?.fieldKey]
          = `Milestone would exceed remaining assignment rate. Decrease Milestone rate or increase Assignment rate.`;
      }
    }

    if (field.fieldKey === "rate") {
      if (Object.keys(changedValues).includes("rate")) {
        if (initialValues?.isComplete && values?.isComplete) {
          // if within grace period, rate can decrease but not increase
          if (checkIfGracePeriod(initialValues?.dateCompleted)) {
            if (values?.rate > initialValues?.rate) {
              errors[field?.fieldKey]
                = "Milestone rate can not be increased after it has been completed.";
            }
          } else {
            // if outside grace period, rate can not change in either direction
            if (values?.rate !== initialValues?.rate) {
              errors[field?.fieldKey]
                = "Milestone rate can not be changed after it has been completed and the grace period has passed.";
            }
          }
        }
        if (initialValues?.releasePayment && values?.releasePayment) {
          // can not change rate if payment has been released
          errors[field?.fieldKey]
            = "Milestone rate can not be changed when it has been released for payment.";
        }
      }
    }

    // if uncompleting...
    if (
      field.fieldKey === "isComplete"
      && Object.keys(changedValues).includes("isComplete")
      && !values?.isComplete
    ) {
      // can not un-complete a milestone outside of grace period
      if (!checkIfGracePeriod(initialValues?.dateCompleted)) {
        errors[field?.fieldKey]
          = "Milestones can only be un-completed within 1 calendar month of completion date.";
      }
    }

    // if completing...
    if (
      field.fieldKey === "isComplete"
      && Object.keys(changedValues).includes("isComplete")
      && values?.isComplete
    ) {
      // can not complete a milestone if there are uncompleted deliverables
      if (milestone?.__deliverableMetrics?.incomplete) {
        errors[field?.fieldKey]
          = "Milestones can not be completed if there are incomplete deliverables.";
      }
    }

    if (field?.fieldKey === "dateCompleted") {
      if (!values.dateCompleted && values.isComplete) {
        errors[field?.fieldKey] = `Completion date is required`;
      }

      if (changedValues?.dateCompleted) {
        const dateCompleted = new Date(values.dateCompleted);
        const today = new Date();
        // must not be in the future
        if (dateCompleted > today) {
          errors[field?.fieldKey] = "Completion date cannot be in the future.";
        } else {
          // date completed must be within grace period
          if (!checkIfGracePeriod(values.dateCompleted)) {
            errors[field?.fieldKey]
              = "Completion date must be within 1 calendar month of today.";
          }
        }
      }
    }

    // if releasing payment...
    if (
      field.fieldKey === "releasePayment"
      && Object.keys(changedValues).includes("releasePayment")
      && values?.releasePayment
    ) {
      // can not release for payment if has uncompleted deliverables
      if (milestone?.__deliverableMetrics?.incomplete) {
        errors[field?.fieldKey]
          = "Milestones can not be released for payment if there are incomplete deliverables.";
      }
      // can not release for payment if not complete
      if (!values?.isComplete) {
        errors[field?.fieldKey]
          = "Milestones can not be released for payment if they are incomplete.";
      }
    }
  });

  return errors;
}
