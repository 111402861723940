import DataGrid from "components/DataGrid";
import { topupsColDefs } from "./constants/columnDefs";
import { connect } from "react-redux";
import { useMemo } from "react";

const AllTopupsPane = ({
  activeTabId,
  tabId,

  loading,
  pastTopups,
  pendingTopups,
  ...props
}) => {
  const allTopups = useMemo(
    () => [...pendingTopups, ...pastTopups],
    [pastTopups, pendingTopups],
  );
  return (
    <DataGrid
      gridId="all-topups"
      borders
      idField="id"
      visible={activeTabId === tabId}
      rowData={allTopups}
      columnDefs={topupsColDefs}
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_STRIPE_BALANCES?.pending,
    pastTopups: state.dashboard.pastTopups,
    pendingTopups: state.dashboard.pendingTopups,
  };
};
export default connect(mapStateToProps, {})(AllTopupsPane);
