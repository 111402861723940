import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getLoggedInUser } from "modules/Login/actions";

const PublicPageLayout = ({
  isAuthenticated,
  getLoggedInUser,
  ...props
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    getLoggedInUser();
  }, [getLoggedInUser]);
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <main className="d-flex vh-100 align-items-center">
        <Outlet />
      </main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.login.isAuthenticated,
  };
};
export default connect(mapStateToProps, { getLoggedInUser })(
  PublicPageLayout,
);
