import { Link, useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const BackNav = ({ to, ...props }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <div className="back-nav" {...props}>
      {to ? (
        <Button color="link" tag={Link} to={to} className="text-muted p-0">
          <ArrowLeftIcon height={20} width={20} />
          {/* Back */}
        </Button>
      ) : (
        <Button color="link" onClick={handleClick} className="p-0">
          <ArrowLeftIcon height={20} width={20} />
          {/* Back */}
        </Button>
      )}
    </div>
  );
};

export default BackNav;
