// TODO: numeric formatters

/**
 * Formats a date value into a string with the format "MMM DD, YYYY".
 * @param {Date | string} value - The date value to format.
 * @param {Object} [formatOptions] - The format options to pass to the Intl.DateTimeFormat constructor.
 * @returns {string} The formatted date string.
 */
export const dateFormatter = (value, formatOptions = {}) => {
  if (!value) return "";
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    ...formatOptions,
  };
  const formatted = value?.toLocaleDateString("en-US", options);
  return formatted || "";
};

/**
 * Formats a date value into an ISO date string with the format "YYYY-MM-DD".
 * @param {Date | string} value - The date value to format.
 * @returns {string} The formatted ISO date string.
 */
export const ISODateFormatter = (value) => {
  if (!value) return "";
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * Formats a date and time value into a string with the format "MMM DD, YYYY, HH:MM AM/PM".
 * @param {Date | string} value - The date and time value to format.
 * @returns {string} The formatted date and time string.
 */
export const dateTimeFormatter = (value) => {
  if (!value) return "";
  if (!(value instanceof Date)) {
    value = new Date(value);
  }
  const formatted = value?.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    // TODO: include timezone in tooltip maybe?
    // timeZoneName: "short",
  });
  return formatted || "";
};

/**
 * Formats a numeric value into a string with the format "$X.XX".
 * @param {number} value - The numeric value to format.
 * @param {Object} [formatOptions] - The format options to pass to the Intl.NumberFormat constructor.
 * @returns {string} The formatted currency string.
 */
export const currencyFormatter = (value, formatOptions = {}) => {
  if (value === undefined || value === null) return "--";
  const options = {
    style: "currency",
    currency: "USD",
    ...formatOptions,
  };
  const formatted = new Intl.NumberFormat("en-US", options).format(value);
  return formatted || "--";
};

/**
 * Formats a string to title case.
 * @param {string} value - The string to format.
 * @returns {string} The formatted string in title case.
 */
export const titleCaseFormatter = (value) => {
  if (!value) return "";
  return value
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
