import { connect } from "react-redux";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { currencyFormatter } from "helpers/formatters";
import Loader from "components/Loader";

const StripeBalanceCard = ({
  loading,
  stripeBalancePending,
  stripeBalanceAvailable,
  stripeBalanceTotal,
  pendingTopups,
  pastTopups,
  ...props
}) => {
  return (
    <Card>
      <CardBody>
        {loading && <Loader />}
        <CardTitle>
          <h2 className="fs-4">NeoReach Funds</h2>
        </CardTitle>
        <CardText className="dashboard__content__labelled-data">
          <span>
            <span className="small text-muted mb-2">Available</span>
            {currencyFormatter(stripeBalanceAvailable)}
          </span>
          <span>
            <span className="small text-muted mb-2">Pending</span>
            {currencyFormatter(stripeBalancePending)}
          </span>
          <span>
            <span className="small text-muted mb-2">Total</span>
            {currencyFormatter(stripeBalanceTotal)}
          </span>
        </CardText>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading.GET_STRIPE_BALANCES?.pending,
    stripeBalancePending: state.dashboard.stripeBalancePending,
    stripeBalanceAvailable: state.dashboard.stripeBalanceAvailable,
    stripeBalanceTotal: state.dashboard.stripeBalanceTotal,
    pendingTopups: state.dashboard.pendingTopups,
    pastTopups: state.dashboard.pastTopups,
  };
};
export default connect(mapStateToProps, {})(StripeBalanceCard);
