import { memo } from "react";

import SelectAdminSigner from "features/agreements/components/Agreement/SelectAdminSigner";

const AssignmentName = ({ value }) => {
  return <>{value ?? "No campaign"}</>;
};

export default AssignmentName;

export const agreementFieldDefs = [
  {
    fieldKey: "_id",
    type: "text",
    hideCondition: () => {
      return true;
    },
  },
  {
    // TODO: custom status renderer
    fieldKey: "status",
    type: "text",
    label: "Status",
    creatorVisible: true,
    editing: {
      creatorVisible: null,
      label: null,
      componentProps: { label: "Status", creatorVisible: true },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id || !values?.status;
      },
    },
  },
  {
    // TODO: link to campaign page
    fieldKey: "assignment_id.campaignName",
    type: "text",
    label: "Campaign",
    hideCondition: (values) => {
      return !values?._id || !values?.assignment_id;
    },
    CustomRenderer: memo(AssignmentName),
  },
  {
    fieldKey: "files",
    type: "uploader",
    label: "Document/File",
    creatorVisible: true,
    fieldInputProps: { multiple: true, accept: "application/pdf" },
    hideCondition: (values) => {
      return values?._id;
    },
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: { label: "Document/File", creatorVisible: true },
    },
  },
  {
    fieldKey: "title",
    type: "text",
    label: "Title",
    creatorVisible: true,
    editing: {
      required: true,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Title", creatorVisible: true },
      disableCondition: (values) => {
        return values?._id;
      },
      hideCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "isRequired",
    type: "switch",
    label: "Required for Payment",
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Require for Payment",
      },
      defaultValue: false,
    },
  },
  {
    fieldKey: "adminHasSent",
    type: "switch",
    label: "Admin Has Sent",
    editing: {
      label: null,
      componentProps: { label: "Admin Has Sent" },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id;
      },
    },
  },
  {
    fieldKey: "creatorHasSigned",
    type: "switch",
    label: "Creator Has Signed",
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Creator Has Signed",
      },
      defaultValue: false,
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?._id;
      },
    },
  },
  {
    fieldKey: "requiresAdminSignature",
    type: "switch",
    label: "Require Admin Signature",
    creatorVisible: true,
    editing: {
      defaultValue: false,
      label: null,
      creatorVisible: null,
      componentProps: {
        label: "Require Admin Signature",
        creatorVisible: true,
      },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
  {
    fieldKey: "adminSigner_id",
    label: "Admin Signer",
    creatorVisible: false,
    // displayValue: displayedCampaign?.brand_id?.name,
    type: "text",
    editing: {
      defaultValue: null,
      label: null,
      creatorVisible: null,
      fieldKey: "adminSigner_id",
      CustomRenderer: memo(SelectAdminSigner),
      hideCondition: (values) => {
        return !values?.requiresAdminSignature || values?._id;
      },
    },
  },
  {
    fieldKey: "adminEmailAddress",
    type: "text",
    label: "Admin Signer Email",
    creatorVisible: false,
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: { label: "Admin Signer Email", creatorVisible: false },
      hideCondition: (values) => {
        return !values?.requiresAdminSignature || !values?._id;
      },
      disableCondition: () => {
        return true;
      },
    },
  },
  {
    fieldKey: "adminHasSigned",
    type: "switch",
    label: "Admin Has Signed",
    creatorVisible: true,
    editing: {
      label: null,
      creatorVisible: null,
      componentProps: { label: "Admin Has Signed", creatorVisible: true },
      disableCondition: () => {
        return true;
      },
      hideCondition: (values) => {
        return !values?.requiresAdminSignature || !values?._id;
      },
    },
  },
  {
    fieldKey: "message",
    type: "textarea",
    label: "Message",
    creatorVisible: true,
    editing: {
      defaultValue: null,
      label: null,
      creatorVisible: null,
      componentProps: { label: "Message", creatorVisible: true },
      disableCondition: (values) => {
        return values?._id;
      },
    },
  },
];
