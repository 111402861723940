import { useState } from "react";

// Custom Hook
export const useGridApis = () => {
  const [gridApi, setGridApi] = useState(null);
  const [colApi, setColApi] = useState(null);

  const onGridReady = (params) => {
    if (!params?.api || !params?.columnApi) return;
    setGridApi(params.api);
    setColApi(params.columnApi);
  };

  return { gridApi, colApi, onGridReady };
};
