import { useGetAgreementQuery } from "app/apiSlice";
import { useAgreementContext } from "features/agreements/components/Agreement/context/AgreementContext";

/**
 * Custom hook to fetch agreement data from RTK-query.
 *
 * It must be used within a component that is a **descendant of an AgreementContext Provider**,
 * as it relies on the context to obtain parameters needed for data fetching.
 *
 * @returns {Object} An object containing agreement data, loading state, and error from the RTK-query result.
 * @property {Object} agreement - The agreement data.
 * @property {boolean} isLoading - A boolean indicating whether the data is currently being loaded.
 * @property {Function} refetch - A function to manually refetch the data.
 * @property {Error|null} error - An error object if there was an error while fetching the data.
 */
const useAgreementData = () => {
  const { agreementId } = useAgreementContext();
  const {
    data: agreement,
    isLoading,
    error,
    refetch,
  } = useGetAgreementQuery(agreementId, {
    skip: !agreementId,
  });

  const ret = {
    agreement,
    isLoading,
    error,
    refetch,
  };
  if (!agreementId) {
    ret.agreement = {};
  }
  return ret;
};

export default useAgreementData;
