import { useParams, useNavigate } from "react-router-dom";

import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import TabbedNav from "components/TabbedNav";
import TabPanes from "components/TabbedNav/TabPanes";
import BrandUserAssignments from "./BrandUserAssignments";
import BrandUserAssignment from "./BrandUserAssignment";
import CreateBrandUserModalButton from "./CreateBrandUserModalButton";

const BrandAssignments = ({ ...props }) => {
  const { brand_id, assignmentTab } = useParams();
  const navigate = useNavigate();

  if (!assignmentTab) {
    navigate(`/brands/${brand_id}/brandUsers`);
  }

  const handleTabChange = (assignmentTab) => {
    navigate(`/brands/${brand_id}/${assignmentTab}`);
  };

  const brandAssignmentsTabOptions = [
    {
      label: "Brand Users",
      id: "brandUsers",
      PaneComponent: BrandUserAssignments,
      TabRowActionComponent: CreateBrandUserModalButton,
    },
  ];

  return (
    <>
      <div className="brand-assignments position-relative">
        <Panel>
          <PanelHeader unpadded className="mx-2">
            <TabbedNav
              activeTabId={assignmentTab || "brandUsers"}
              setActiveTabId={handleTabChange}
              tabs={brandAssignmentsTabOptions}
            />
          </PanelHeader>
          <PanelBody>
            <TabPanes
              activeTabId={assignmentTab}
              setActiveTabId={handleTabChange}
              tabs={brandAssignmentsTabOptions}
            />
          </PanelBody>
        </Panel>
        <BrandUserAssignment />
      </div>
    </>
  );
};

export default BrandAssignments;
