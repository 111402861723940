import { campaignsActionTypes } from "../constants";

const initialState = {
  loading: false,
  campaigns: [],
  displayedCampaign: null,
  displayedAssignment: null,
  creatorAssignments: [],
  error: null,
};
const campaignsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case campaignsActionTypes.getCampaigns.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getCampaigns.SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: payload?.results,
      };
    case campaignsActionTypes.getCampaigns.FAILURE:
      return {
        ...state,
        loading: false,
        campaigns: [],
        error: payload,
      };
    case campaignsActionTypes.getCampaignCreatorAssignments.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getCampaignCreatorAssignments.SUCCESS: {
      const updatedState = {
        ...state,
        loading: false,
        creatorAssignments: payload?.results,
      };
      if (state.displayedAssignment) {
        updatedState.displayedAssignment = payload?.results.find(
          (assignment) => assignment._id === state.displayedAssignment._id,
        );
      }
      return updatedState;
    }
    case campaignsActionTypes.getCampaignCreatorAssignments.FAILURE:
      return {
        ...state,
        loading: false,
        creatorAssignments: [],
        error: payload,
      };
    case campaignsActionTypes.createCampaignCreatorAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.createCampaignCreatorAssignment.SUCCESS:
      return {
        ...state,
        loading: false,
        creatorAssignments: [payload?.result, ...state.creatorAssignments],
      };
    case campaignsActionTypes.createCampaignCreatorAssignment.FAILURE:
      return {
        ...state,
        loading: false,
      };
    case campaignsActionTypes.removeCampaignCreatorAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.removeCampaignCreatorAssignment.SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case campaignsActionTypes.removeCampaignCreatorAssignment.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.getCampaign.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.getCampaign.SUCCESS:
      return {
        ...state,
        loading: false,
        displayedCampaign: payload?.result,
      };
    case campaignsActionTypes.getCampaign.FAILURE:
      return {
        ...state,
        loading: false,
        displayedCampaign: null,
        error: payload,
      };
    case campaignsActionTypes.updateCampaign.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.updateCampaign.SUCCESS: {
      // update by _id
      const updatedCampaigns = state.campaigns.map((campaign) => {
        return campaign._id === payload?.result?._id
          ? payload?.result
          : campaign;
      });
      return {
        ...state,
        loading: false,
        campaigns: updatedCampaigns,
        displayedCampaign: payload?.result,
      };
    }
    case campaignsActionTypes.updateCampaign.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.updateCampaignCreatorAssignment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.updateCampaignCreatorAssignment.SUCCESS: {
      const updatedAssignments = state.creatorAssignments.map((assignment) => {
        return assignment._id === payload?.result?._id
          ? payload?.result
          : assignment;
      });
      const updatedState = {
        ...state,
        loading: false,
        creatorAssignments: updatedAssignments,
        displayedAssignment: updatedAssignments.find((assignment) => {
          return assignment._id === state.displayedAssignment?._id;
        }),
      };
      return updatedState;
    }
    case campaignsActionTypes.updateCampaignCreatorAssignment.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.createMilestone.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.createMilestone.SUCCESS: {
      // update by _id
      const updatedAssignments = state.creatorAssignments.map((assignment) => {
        return assignment._id === payload?._id ? payload?.result : assignment;
      });
      return {
        ...state,
        loading: false,
        creatorAssignments: updatedAssignments,
        displayedAssignment: payload?.result,
      };
    }
    case campaignsActionTypes.createMilestone.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.updateMilestone.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.updateMilestone.SUCCESS: {
      // update by _id
      const updatedAssignments = state.creatorAssignments.map((assignment) => {
        return assignment._id === payload?.result?._id
          ? payload?.result
          : assignment;
      });
      const updatedState = {
        ...state,
        loading: false,
        creatorAssignments: updatedAssignments,
        displayedAssignment: updatedAssignments.find((assignment) => {
          return assignment._id === state.displayedAssignment?._id;
        }),
      };
      return updatedState;
    }
    case campaignsActionTypes.updateMilestone.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.makePayment.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.makePayment.SUCCESS: {
      // update by _id
      const updatedAssignments = state.creatorAssignments.map((assignment) => {
        return assignment._id === payload?.result?._id
          ? payload?.result
          : assignment;
      });
      const updatedState = {
        ...state,
        loading: false,
        creatorAssignments: updatedAssignments,
        displayedAssignment: updatedAssignments.find((assignment) => {
          return assignment._id === state.displayedAssignment?._id;
        }),
      };
      return updatedState;
    }
    case campaignsActionTypes.makePayment.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.createCampaign.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case campaignsActionTypes.createCampaign.SUCCESS: {
      return {
        ...state,
        loading: false,
        campaigns: [payload?.result, ...state.campaigns],
        displayedCampaign: payload?.result,
      };
    }
    case campaignsActionTypes.createCampaign.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case campaignsActionTypes.changeDisplayedCampaign.SUCCESS:
      return {
        ...state,
        displayedCampaign: payload,
        displayedAssignment: null,
      };
    case campaignsActionTypes.changeDisplayedAssignment.SUCCESS:
      return {
        ...state,
        displayedAssignment: payload,
      };
    default:
      return state;
  }
};

export default campaignsReducer;
