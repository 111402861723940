/**
 * Returns an object containing the values that have changed between two objects.
 *
 * @param {Object} values - The current values object.
 * @param {Object} initialValues - The initial values object.
 * @returns {Object} - An object containing the changed values.
 */
export const getChangedValues = (values, initialValues) => {
  const changedValues = {};
  for (const key in values) {
    if (values[key]?._id) {
      if (values[key]._id !== initialValues[key]) {
        changedValues[key] = values[key]?._id;
      }
    } else {
      if (values[key] !== initialValues[key]) {
        changedValues[key] = values[key];
      }
    }
  }
  return changedValues;
};
