// Renders a button that triggers a modal to create a new brand

import { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";
import CreateBrandUserModal from "./CreateBrandUserModal";

const CreateBrandUserModalButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="create-brand">
      <Button
        color="dark"
        className="ms-3 d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Invite Brand User
      </Button>
      <CreateBrandUserModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
      ></CreateBrandUserModal>
    </div>
  );
};

export default CreateBrandUserModalButton;
