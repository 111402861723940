import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { showToast } from "components/Toasts/helpers/showToast";
import AgreementForm from "features/agreements/components/Agreement/AgreementForm";
import useAgreementData from "features/agreements/components/Agreement/hooks/useAgreementData";
import useAgreementUpdate from "features/agreements/components/Agreement/hooks/useAgreementUpdate";
import { agreementFieldDefs } from "features/agreements/constants/fieldDefs";
import { getChangedValues } from "helpers/getChangedValues";
import { useCallback, useMemo } from "react";

const EditAgreementForm = ({ children }) => {
  const { agreement } = useAgreementData();
  const { updateAgreement } = useAgreementUpdate();

  const initialValues = useMemo(
    () => getInitialValues(agreementFieldDefs, agreement || {}),
    [agreement],
  );

  const onSubmit = useCallback(
    async(values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        const { error } = await updateAgreement({
          _id: agreement?._id,
          ...changedValues,
        }).unwrap();
        if (error) throw new Error(error);
        // TODO? may automatically update UIStatus?
        // setAgreementUIStatus("edit");
        actions.setSubmitting(false);
        showToast({
          type: "success",
          message: "Agreement saved successfully",
        });
      } catch (error) {
        console.error("Error saving agreement - ", error);
        showToast({
          type: "error",
          message: "Error saving agreement",
        });
      }
    },
    [agreement?._id, initialValues, updateAgreement],
  );
  if (!agreement) return null;
  return (
    <AgreementForm initialValues={initialValues} onSubmit={onSubmit}>
      {children}
    </AgreementForm>
  );
};

export default EditAgreementForm;
