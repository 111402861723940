import { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getCampaignCreatorAssignments } from "modules/Campaigns/actions";
import DataGrid from "components/DataGrid";
import { creatorColDefs } from "modules/Creators/constants/columnDefs";
import { getSelectGridColDefs } from "components/DataGrid/helpers/getSelectGridColDefs";

const selectCreatorColDefs = getSelectGridColDefs(creatorColDefs);

/**
 * A component that renders a list of campaigns with checkboxes and allows the user to select one.
 *
 * @param {Object} props - The component props.
 * @param {function} props.setSelectedId - A function to set the selected campaign ID when the user makes a selection change.
 * @param {string} props.campaign_id - ID of the campaign the creator will be assigned to.
 * @param {Array} props.creators - An array of creator objects to display in the DataGrid, from redux.
 * @param {Array} props.assignedCreatorIds - An array of existing assignments creator _ids for this campaign, from redux.
 * @param {boolean} props.loading - A boolean indicating the current loading state, from redux.
 * @param {function} props.getCampaignCreatorAssignments - A redux action to fetch the assigned campaign ids.
 * @returns {JSX.Element} - The SelectCampaign component.
 */
const AssignableCreatorsSelect = ({
  setSelectedId,
  campaign_id,

  creators,
  assignedCreatorIds,
  loading,
  getCampaignCreatorAssignments,
  ...props
}) => {
  useEffect(() => {
    try {
      getCampaignCreatorAssignments(campaign_id);
    } catch (error) {
      console.error(error);
    }
  }, [campaign_id, getCampaignCreatorAssignments]);

  // TODO: make this a custom hook
  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedNodes();
    if (selectedRows.length > 0) {
      setSelectedId(selectedRows[0].id);
    } else {
      setSelectedId(null);
    }
  };

  const isRowSelectable = useMemo(() => {
    return (rowNode) => {
      return !assignedCreatorIds.includes(rowNode.id);
    };
  }, [assignedCreatorIds]);

  return (
    <>
      <div className="mb-3">
        <small className="text-muted">
          Note: Creators without checkboxes mean the creator is already assigned
          to this campaign.
        </small>
      </div>
      <DataGrid
        gridId="creators-select"
        columnDefs={selectCreatorColDefs}
        idField="_id"
        rowData={creators}
        loading={loading}
        height="500px"
        borders
        onSelectionChanged={handleSelectionChanged}
        isRowSelectable={isRowSelectable}
        quickFilter={true}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    creators: state.creators.creators,
    assignedCreatorIds: state.campaigns.creatorAssignments.map(
      (assignment) => assignment.user_id?._id ?? assignment.user_id,
    ),
    loading:
      state.loading.GET_CREATORS?.pending
      || state.loading.GET_CAMPAIGN_CREATOR_ASSIGNMENTS?.pending,
  };
};
export default connect(mapStateToProps, {
  getCampaignCreatorAssignments,
})(AssignableCreatorsSelect);
