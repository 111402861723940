import { Spinner } from "reactstrap";
import "./index.scss";

const Loader = ({ solid = undefined, ...props }) => {
  return (
    <div className={`bg-body loader ${solid && "solid"}`}>
      <Spinner />
    </div>
  );
};

export default Loader;
