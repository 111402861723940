import LinkButtonCellRenderer from "components/DataGrid/customRenderers/LinkButtonCellRenderer";
import CampaignStatusBadge from "../components/CampaignStatusBadge";
import WarningBoolCellRenderer from "components/DataGrid/customRenderers/WarningBoolCellRenderer";
import BoolCellRenderer from "components/DataGrid/customRenderers/BoolCellRenderer";
import { memo } from "react";

export const campaignColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    // TODO: display name/name override
    headerName: "Name",
    field: "name",
    type: "text",
  },
  {
    headerName: "Status",
    field: "status",
    type: "text",
    cellRenderer: memo(CampaignStatusBadge),
  },
  {
    headerName: "Client Login Allowed",
    field: "allowClientAccess",
    type: "boolean",
    cellRenderer: memo(BoolCellRenderer),
  },
  {
    headerName: "Brand",
    field: "brand_id.name",
    type: "text",
  },
  {
    headerName: "Total Rate",
    field: "totalRate",
    type: "currency",
    valueGetter: (params) => {
      return (
        (params.data?.__assignmentBalances?.paidBalance || 0)
        + (params.data?.__assignmentBalances?.availableBalance || 0)
        + (params.data?.__assignmentBalances?.holdBalance || 0)
      );
    },
  },
  {
    headerName: "Paid",
    type: "currency",
    field: "paid",
    valueGetter: (params) => {
      return (
        (params.data?.__assignmentBalances?.paidBalance || 0)
        + (params.data?.__assignmentBalances?.availableBalance || 0)
      );
    },
  },
  {
    headerName: "Hold",
    field: "__assignmentBalances.holdBalance",
    type: "currency",
  },
  {
    headerName: "Creators",
    field: "__assignmentBalances.count",
    type: "number",
  },
  // {
  //   headerName: "Content Approval Enabled",
  //   field: "allowContentApproval",
  //   type: "boolean",
  //   cellRenderer: memo(BoolCellRenderer),
  // },
  // {
  //   headerName: "Action Required",
  //   field: "actionRequired",
  //   // type: "boolean",
  //   // cellDataType: "boolean", // this should be handled by type: "boolean" above, but it's not applied when valueGetter is used
  //   valueGetter: (params) => {
  //     // TODO: replace random test data
  //     return "TODO: calculate";
  //   },
  //   // cellRenderer: memo(BoolCellRenderer),
  // },
  // {
  //   headerName: "Posts",
  //   field: "__content",
  //   type: "number",
  //   valueGetter: (params) => {
  //     // TODO: replace random test data
  //     return "TODO: calculate";
  //   },
  // },
  {
    headerName: "SOW ID",
    field: "invoiceId",
    type: "text",
  },
  {
    headerName: "SOW $",
    field: "sowAmount",
    type: "currency",
  },
  {
    headerName: "Start Date",
    field: "startDate",
    type: "date",
    valueGetter: (params) => {
      // TODO: replace random test data
      return params.data?.startDate
        ? new Date(params.data?.startDate)
        : new Date();
    },
  },
  {
    headerName: "End Date",
    field: "endDate",
    type: "date",
    valueGetter: (params) => {
      // TODO: replace random test data
      return params.data?.endDate ? new Date(params.data?.endDate) : new Date();
    },
  },
  {
    headerName: "Created Date",
    field: "created",
    type: "date",
    valueGetter: (params) => {
      // TODO: replace random test data
      return params.data?.created ? new Date(params.data?.created) : new Date();
    },
    sort: "desc",
  },
  {
    headerName: "",
    field: "viewButton",
    cellRenderer: memo(LinkButtonCellRenderer),
    valueGetter: (params) => {
      return `/campaigns/${params.data?._id}`;
    },
    cellClass: "d-flex justify-content-center cell-button",
    minWidth: 100,
    maxWidth: 100,
    pinned: "right",
    suppressSizeToFit: true,
    suppressMovable: true,
    lockPinned: true,
    lockVisible: true,
    sortable: false,
    filter: false,
  },
];

export const campaignCreatorAssignmentColDefs = [
  { headerName: "_id", field: "_id", hide: true },
  {
    headerName: "Name",
    field: "user_id.name",
    type: "text",
  },
  {
    headerName: "Custom Handle",
    field: "agencyCreatorName",
    type: "text",
  },
  {
    headerName: "Agency/Creator",
    field: "user_id.isAgency",
    type: "boolean",
    valueGetter: (params) => {
      return params.data?.user_id?.isAgency ? "Agency" : "Creator";
    },
  },
  {
    headerName: "Email",
    field: "user_id.email",
    type: "text",
    width: "150",
  },
  {
    headerName: "Rate",
    field: "rate",
    type: "currency",
  },
  {
    headerName: "Cashed Out",
    field: "paidBalance",
    type: "currency",
  },
  // {
  //   // TODO: handle __availableBalanceCurrency
  //   headerName: "Available",
  //   field: "earningsBalance",
  //   type: "currency",
  // },
  {
    headerName: "Withdrawable",
    field: "availableBalance",
    type: "currency",
  },
  {
    headerName: "Potentially Owed",
    field: "holdBalance",
    type: "currency",
  },
  {
    headerName: "Expected Pay Date",
    field: "nextPayoutDate",
    type: "date",
  },
  {
    headerName: "Status",
    field: "status",
    type: "text",
  },
  {
    headerName: "Stripe Onboarding",
    field: "user_id.needsStripeOnboarding",
    type: "boolean",
    cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
    valueGetter: (params) => {
      return !params.data.user_id?.needsStripeOnboarding;
    },
    cellRenderer: memo(BoolCellRenderer),
    cellRendererParams: {
      type: "icon",
      truthyText: "Complete",
      falsyText: "Not Completed",
    },
  },
  {
    headerName: "Financial Account",
    field: "user_id.needsStripeFinancialConnection",
    type: "boolean",
    cellDataType: "boolean", // this should be handled by type: "boolean" above, but boolean type is not applied when valueGetter is used
    valueGetter: (params) => {
      return !params.data.user_id?.needsStripeFinancialConnection;
    },
    cellRenderer: memo(BoolCellRenderer),
    cellRendererParams: {
      type: "icon",
      truthyText: "Linked",
      falsyText: "Not Linked",
    },
  },
  {
    headerName: "Last Login",
    sort: "desc", // initial sort order on load
    field: "lastLogin",
    type: "date",
    valueGetter: (params) => {
      return params.data?.lastLogin
        ? new Date(params.data?.lastLogin)
        : new Date();
    },
  },
  {
    headerName: "First Login",
    field: "firstLogin",
    type: "date",
    valueGetter: (params) => {
      return params.data?.firstLogin
        ? new Date(params.data?.firstLogin)
        : new Date();
    },
  },
  {
    headerName: "Account Disabled",
    field: "disabled",
    type: "boolean",
    cellRenderer: memo(WarningBoolCellRenderer),
  },
  // TODO: calculate content counts
];
