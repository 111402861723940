import { useState } from "react";

import { useParams } from "react-router-dom";

import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import AgreementsGrid from "features/agreements/components/AgreementsGrid";
import CreateAgreementModalButton from "features/agreements/components/CreateAgreement/CreateAgreementModalButton";

const CreatorAssignmentAgreements = () => {
  const { assignment_id, creator_id } = useParams();
  const [agreement_id, setAgreement_id] = useState(null);

  const onCreateAgreement = (agreement) => {
    setAgreement_id(agreement._id);
  };

  return (
    <section className="agreements">
      {/* {isLoading && <Loader />} */}
      <Panel overlay={"expanded"}>
        {/* <PanelHeader unpadded={true}>
          <CreateAgreementModalButton onCreate={onCreateAgreement} />
        </PanelHeader> */}
        <PanelBody borders={false} padded={false}>
          <AgreementsGrid
            assignment_id={assignment_id}
            creator_id={creator_id}
            quickFilter={false}
            activeAgreement_id={agreement_id}
          />
        </PanelBody>
      </Panel>
    </section>
  );
};

export default CreatorAssignmentAgreements;
