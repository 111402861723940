import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useState } from "react";
import AssignableCreatorsSelect from "../AssignableCreatorsSelect";
import { createCampaignCreatorAssignment } from "modules/Campaigns/actions";

/**
 * @typedef {Object} AssignExistingCreatorsModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} campaign_id - Campaign ID to which creator will be assigned
 * @property {boolean} loading - Loading state managed by redux
 * @property {function} createCampaignCreatorAssignment - Redux actions to assign the creator to the selected campaign
 */
/**
 * Assign Creator to Campaign Modal
 * @param {AssignExistingCreatorsModalProps} props
 * @returns {JSX.Element}
 */
function AssignExistingCreatorsModal({
  campaign_id,
  modalOpen,
  toggleModal,

  createCampaignCreatorAssignment,

  ...props
}) {
  const [selectedCreator_id, setSelectedCreator_id] = useState(null);

  const handleSaveAssignmentClick = async() => {
    try {
      await createCampaignCreatorAssignment({
        user_id: selectedCreator_id,
        campaign_id,
      });
      toggleModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size="xl"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      <ModalHeader toggle={toggleModal}>Assign Creator to Campaign</ModalHeader>
      <ModalBody>
        <AssignableCreatorsSelect
          setSelectedId={setSelectedCreator_id}
          campaign_id={campaign_id}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          Cancel
        </Button>
        <Button
          color="dark"
          disabled={!selectedCreator_id}
          onClick={handleSaveAssignmentClick}
        >
          Save Assignment
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.CREATE_CREATOR_CAMPAIGN_ASSIGNMENT?.pending,
  };
};
export default connect(mapStateToProps, {
  createCampaignCreatorAssignment,
})(AssignExistingCreatorsModal);
