/**
 * Resizes all resizable columns in the grid to fit their contents and adjusts the grid width if necessary.
 * @param {Object} gridApi - The grid API object from ag-Grid.
 * @param {Object} colApi - The column API object from ag-Grid.
 * @param {boolean} [skipHeader] - Whether to skip resizing the header row.
 */
const autoSizeAll = (gridApi, colApi, skipHeader = true) => {
  if (!gridApi || !colApi) return;
  const allColumnIds = [];
  colApi.getColumns().forEach((column) => {
    if (column.colDef.resizable) {
      allColumnIds.push(column.getId());
    }
  });
  colApi.autoSizeColumns(allColumnIds, skipHeader);

  let totalWidth = 0;
  colApi.getAllDisplayedColumns().forEach((column) => {
    if (column.colDef.resizable) {
      totalWidth += column.getActualWidth();
    }
  });

  const { left, right } = gridApi.getHorizontalPixelRange();
  const containerWidth = right - left;
  // TODO: fix bug where width is calculated wrong resulting in weirdly sized columns. happens on some grids consistently, but not others.
  if (containerWidth > totalWidth) {
    gridApi.sizeColumnsToFit();
  }
};

export default autoSizeAll;
