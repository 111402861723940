import { useUpdateAgreementMutation } from "app/apiSlice";

/**
 * Custom hook for updating an agreement with fixedCacheKey to allow universal load tracking.
 *
 * @returns {Object}
 * @property {Function} updateAgreement - The function to update an agreement.
 * @property {boolean} isLoading - The loading state.
 */
const useAgreementUpdate = () => {
  const [updateAgreement, { isLoading }] = useUpdateAgreementMutation({
    fixedCacheKey: "updateAgreement",
  });
  return {
    updateAgreement,
    isLoading,
  };
};

export default useAgreementUpdate;
