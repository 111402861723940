// Renders a button that triggers a modal to create a new campaign

import { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { PlusIcon } from "@heroicons/react/24/outline";
import AssignToCampaignModal from "./AssignToCampaignModal";
import { useParams } from "react-router-dom";

const AssignToCampaignModalButton = ({ ...props }) => {
  const { creator_id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = useCallback(
    () => setModalOpen(!modalOpen),
    [modalOpen, setModalOpen],
  );

  return (
    <div className="assign-to-campaign-modal-button" {...props}>
      <Button
        color="dark"
        className="d-flex align-items-center"
        onClick={() => setModalOpen(true)}
      >
        <PlusIcon height={20} width={20} className="me-2" />
        Add to Campaign
      </Button>
      <AssignToCampaignModal
        modalOpen={modalOpen}
        creator_id={creator_id}
        toggleModal={toggleModal}
      ></AssignToCampaignModal>
    </div>
  );
};

export default AssignToCampaignModalButton;
