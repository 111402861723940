import { useCallback } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import { useGetBrandUsersQuery } from "app/apiSlice";

import DataGrid from "components/DataGrid";
import { brandUsersColDefs } from "../constants";

const BrandUserAssignments = () => {
  const navigate = useNavigate();
  const { brand_id } = useParams();
  const { data, isLoading } = useGetBrandUsersQuery(brand_id);
  const brandUsers = data?.results || [];

  const onBrandUserClick = useCallback((brandUser) => {
    if (!brand_id || !brandUser?._id) return;
    navigate(`/brands/${brand_id}/brandUsers/${brandUser?._id}`);
  }, [brand_id, navigate]);

  return (
    <>
      <div className="brand-user-assignments px-2 pb-2">
        {brandUsers?.length > 0 ? (
          <>
            <DataGrid
              gridId="brand-users"
              columnDefs={brandUsersColDefs}
              idField="_id"
              rowData={brandUsers}
              loading={isLoading}
              borders={true}
              actionText="View"
              actionClick={onBrandUserClick}
              quickFilter={true}
              appliedFilters={true}
              height="100%"
            />
          </>
        ) : (
          <Container>
            <Row>
              <Col className="d-flex justify-content-center align-items-center flex-column">
                <p className="no-brand-user-assignments mt-5">
                  No brand users created yet
                </p>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
};

export default BrandUserAssignments;
