import { createContext, useContext } from "react";

/**
 * Context for storing the agreement ID and UI status.
 * @type {React.Context}
 * @example
 *
 */
const AgreementModalContext = createContext(null);

/**
 * Custom hook for accessing the agreement modal context.
 * @returns {Object} The agreement context.
 * @property {boolean} isAgreementModalOpen - The open state of the modal.
 * @property {Function} setIsAgreementModalOpen - The function to set the modal open state.
 * @property {Function} toggleAgreementModal - The function to toggle the modal open state.
 * @throws {Error} If used outside of an AgreementModalContext.Provider.
 */
const useAgreementModalContext = () => {
  const context = useContext(AgreementModalContext);
  if (!context) {
    throw new Error(
      "useAgreementModalContext must be used within an AgreementModalContext.Provider",
    );
  }
  return context;
};

export { AgreementModalContext, useAgreementModalContext };
