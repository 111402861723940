import "./index.scss";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useMemo, useState } from "react";
import { campaignCreatorAssignmentDataDefs } from "../../constants/dataDefs";
import { connect } from "react-redux";
import {
  changeDisplayedAssignment,
  updateCampaignAssignment,
} from "../../actions";
import Panel from "../../../../components/Panel";
import PanelHeader from "../../../../components/Panel/PanelHeader";
import PanelBody from "../../../../components/Panel/PanelBody";
import LabelledData from "../../../../components/Forms/LabelledData";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import { Formik } from "formik";
import PanelFooter from "components/Panel/PanelFooter";
import UnsavedChangesPrompt from "components/Forms/UnsavedChangesPrompt";
import { getChangedValues } from "helpers/getChangedValues";
import { showToast } from "components/Toasts/helpers/showToast";
import useResyncLegacyStore from "hooks/useResyncLegacyStore";
import CampaignAssignmentMilestones from "./CampaignAssignmentMilestones";

const CampaignCreatorAssignment = ({
  displayedAssignment,
  changeDisplayedAssignment,
  updateCampaignAssignment,

  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [resyncAfterMutation] = useResyncLegacyStore();

  useEffect(() => {
    setIsEditing(false);
  }, [displayedAssignment?._id]);

  const assignmentDefs = useMemo(
    () => campaignCreatorAssignmentDataDefs(displayedAssignment || {}),
    [displayedAssignment],
  );

  const initialValues = useMemo(
    () => getInitialValues(assignmentDefs?.body, displayedAssignment),
    [assignmentDefs?.body, displayedAssignment],
  );

  const validate = useCallback(
    (_values) => {
      // TODO: validate assignment
      return {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- temp for placeholder unused initialValues
    [initialValues],
  );

  const submit = useCallback(
    async(values, actions) => {
      try {
        const changedValues = getChangedValues(values, initialValues);
        if (Object.keys(changedValues).length) {
          await updateCampaignAssignment({
            _id: displayedAssignment?._id,
            ...changedValues,
          });
          resyncAfterMutation();
          showToast({
            type: "success",
            message: "Assignment saved successfully.",
          });
        }
        setIsEditing(false);
      } catch (error) {
        showToast({
          type: "error",
          message: "Error updating assignment.",
        });
        console.error("Error updating assignment", error);
      }
      actions.setSubmitting(false);
    },
    [
      displayedAssignment?._id,
      initialValues,
      resyncAfterMutation,
      updateCampaignAssignment,
    ],
  );

  if (!displayedAssignment) return null;

  const disableEditing = (dirty) => {
    if (dirty) {
      const confirmDiscard = window.confirm("Discard unsaved changes?");
      if (confirmDiscard) {
        setIsEditing(false);
      }
    } else {
      setIsEditing(false);
    }
    // TODO: reset form values
  };

  return (
    <div key={displayedAssignment?._id} className="campaign-creator-assignment">
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validate={validate}
        enableReinitialize={true}
      >
        {({ dirty, ...props }) => (
          <Form onSubmit={props.handleSubmit}>
            <Panel overlay={"expanded"}>
              <PanelHeader closeAction={() => changeDisplayedAssignment(null)}>
                <h2 className="fs-4 d-flex align-items-center">
                  <Label className="text-muted small">
                    {assignmentDefs?.header?.label}:
                  </Label>
                  {assignmentDefs?.header?.value}
                  {!isEditing && displayedAssignment?._id && (
                    <Button
                      color="secondary"
                      outline
                      size="sm"
                      className="d-flex align-items-center ms-3"
                      onClick={() => setIsEditing(true)}
                    >
                      <PencilIcon height={20} width={20} />
                    </Button>
                  )}
                </h2>
              </PanelHeader>
              <PanelBody padded={true} split="horizontal" scrollable={true}>
                <Row>
                  {assignmentDefs?.body?.map((def, i) => {
                    if (def?.role === "section") {
                      return (
                        <Col key={i}>
                          <div className="labeled-data-container">
                            {def?.fields?.map((subDef, j) => {
                              return (
                                <LabelledData
                                  key={j}
                                  data={displayedAssignment}
                                  definition={subDef}
                                  isEditing={isEditing}
                                />
                              );
                            })}
                          </div>
                        </Col>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Row>
                {isEditing ? (
                  <PanelFooter>
                    <UnsavedChangesPrompt />
                    <div className="save-form-footer d-flex align-items-center justify-content-end gap-3">
                      <Button
                        color="secondary"
                        outline
                        size="lg"
                        className="d-flex align-items-center"
                        onClick={() => disableEditing(dirty)}
                      >
                        <XMarkIcon height={20} width={20} className="me-2" />
                        Cancel
                      </Button>
                      <Button
                        color="dark"
                        className="d-flex align-items-center"
                        type="submit"
                        size="lg"
                      >
                        <CheckIcon height={20} width={20} className="me-2" />
                        Save
                      </Button>
                    </div>
                  </PanelFooter>
                ) : (
                  <CampaignAssignmentMilestones />
                )}
              </PanelBody>
            </Panel>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    displayedAssignment: state.campaigns.displayedAssignment,
  };
};
export default connect(mapStateToProps, {
  changeDisplayedAssignment,
  updateCampaignAssignment,
})(CampaignCreatorAssignment);
