import { useParams, useNavigate } from "react-router-dom";

import Panel from "components/Panel";
import PanelBody from "components/Panel/PanelBody";
import PanelHeader from "components/Panel/PanelHeader";
import TabbedNav from "components/TabbedNav";
import TabPanes from "components/TabbedNav/TabPanes";
import CreatorCampaignAssignment from "modules/Creators/components/Creator/CreatorCampaignAssignment";
import { creatorAssignmentsTabOptions } from "modules/Creators/constants/tabOptions";

const CreatorAssignments = ({ ...props }) => {
  const { creator_id, assignmentTab } = useParams();
  const navigate = useNavigate();

  // Callback function to change the URL
  const handleTabChange = (assignmentTab) => {
    navigate(`/creators/${creator_id}/${assignmentTab}`);
  };

  return (
    <>
      <div className="creator-assignments">
        <Panel>
          <PanelHeader unpadded className="mx-2">
            <TabbedNav
              activeTabId={assignmentTab || "campaigns"}
              setActiveTabId={handleTabChange}
              tabs={creatorAssignmentsTabOptions}
            />
          </PanelHeader>
          <PanelBody>
            <TabPanes
              activeTabId={assignmentTab}
              setActiveTabId={handleTabChange}
              tabs={creatorAssignmentsTabOptions}
            />
          </PanelBody>
        </Panel>
        <CreatorCampaignAssignment />
      </div>
      {/* Rest of the code */}
    </>
  );
};

export default CreatorAssignments;
