import { useCallback } from "react";
import { connect } from "react-redux";
import { Form, Formik } from "formik";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getInitialValues } from "components/Forms/helpers/getInitialValues";
import LabelledData from "components/Forms/LabelledData";
import { inviteCreator } from "modules/Creators/actions";
import { creatorDetailsDataDefs } from "modules/Creators/constants/dataDefs";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";

const fieldDefs = creatorDetailsDataDefs({})?.body;

// TODO: handle if creator with that email already exists
/**
 * A function to validate the form values.
 * @param {object} values - The form values to validate.
 * @returns {object} An object containing any validation errors.
 */
const validate = (values) => {
  const errors = {};
  fieldDefs?.forEach((section) => {
    if (section?.role === "section") {
      section?.fields?.forEach((field) => {
        if (
          field?.editing?.required
          && (values[field?.fieldKey] === undefined
            || values[field?.fieldKey] === null)
        ) {
          errors[field?.fieldKey] = `This field is required`;
        }

        if (
          field?.type === "number"
          && (!values[field?.fieldKey] || values[field?.fieldKey] <= 0)
        ) {
          errors[field?.fieldKey] = `Must be an amount greater than 0`;
        }
      });
    }
  });
  return errors;
};

/**
 * @typedef {Object} InviteCreatorModalProps
 * @property {boolean} modalOpen - Modal open state
 * @property {(event?: React.MouseEvent<HTMLButtonElement>) => void} toggleModal - Toggle modal state
 * @property {string} [initialCreatorName] - Initial creator name
 * @property {boolean} loading - Loading state managed by redux
 * @property {Function} inviteCreator - Create new creator action
 */
/**
 * Invite Creator Modal
 * @param {InviteCreatorModalProps} props
 * @returns {JSX.Element}
 */
function InviteCreatorModal({
  modalOpen,
  toggleModal,
  initialCreatorName = undefined,

  loading,

  inviteCreator,
  ...props
}) {
  const navigate = useNavigate();
  /**
   * Handle form submission
   * @typedef {Object} OnSubmitValues
   * @property {string} name - Creator name
   *
   * @param {OnSubmitValues} values
   * @param {object} actions
   * @param {Function} actions.setSubmitting
   */
  const onSubmit = useCallback(
    async(values, { setSubmitting }) => {
      let newCreator;
      try {
        newCreator = await inviteCreator(values);
        toggleModal();
        navigate(`/creators/${newCreator?._id}`);
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [inviteCreator, navigate, toggleModal],
  );

  const initialValues = getInitialValues(fieldDefs, {
    name: initialCreatorName,
  });

  return (
    <Modal
      isOpen={modalOpen}
      size="md"
      toggle={toggleModal}
      className="create-campaign-modal"
      unmountOnClose={true}
      returnFocusAfterClose={true}
    >
      {loading && <Loader />}
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form>
          <ModalHeader toggle={toggleModal}>
            Invite Creator to Portal
          </ModalHeader>
          <ModalBody>
            {fieldDefs?.map((fieldDef, i) => {
              if (fieldDef?.role === "section") {
                return (
                  <div key={i} className="labeled-data-container">
                    {fieldDef?.fields?.map((subDef, j) => {
                      return (
                        <LabelledData
                          key={`${fieldDef?.fieldKey}-${j}`}
                          data={initialValues}
                          definition={subDef}
                          isEditing={true}
                          editingOnly={true}
                        />
                      );
                    })}
                  </div>
                );
              }
              return null;
            })}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>
              Cancel
            </Button>
            <Button color="dark" type="submit">
              Send Invitation
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading.INVITE_CREATOR?.pending,
  };
};
export default connect(mapStateToProps, {
  inviteCreator,
})(InviteCreatorModal);
